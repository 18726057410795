import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getApiCall } from "../../Utilites/Api";
import { ApiUrl } from "../../Constants/Api";
import { ReactComponent as DashboardIcon } from "../../Assets/dashboard.svg";
import { ReactComponent as ShowsIcon } from "../../Assets/shows.svg";
import { ReactComponent as InventoryIcon } from "../../Assets/inventory.svg";
import { ReactComponent as AccountingIcon } from "../../Assets/accounting.svg";
import { ReactComponent as MerchIqIcon } from "../../Assets/merch_iq.svg";
import { ReactComponent as MerchIcon } from "../../Assets/merch.svg";
import { ReactComponent as WarehouseIcon } from "../../Assets/warehouse.svg";
import { ReactComponent as TrailerIcon } from "../../Assets/trailer.svg";
import { ReactComponent as ShipmentIcon } from "../../Assets/shipment.svg";
import {ReactComponent as OrdersIcon} from "../../Assets/orders.svg";
import RadialMenu from "../../Pages/Home/RadialMenu";
import ScrollButton from "../../Pages/Home/ScrollButton";
import SearchInput from "../SearchInput";

const SecondaryHeader = (props) => {
  const { secondaryMenu, currentPage, title } = props;
  const [showToursDropdown, setShowToursDropdown] = useState(false);
  const [toursList, setToursList] = useState(null);
  const [selectedTour, setSelectedTour] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const location = useLocation();
  const tourItemObj = typeof location?.state?.item === 'undefined' || location?.state.item === ''? JSON.parse(localStorage.getItem("tourItemObj")) : location?.state.item;
  console.log("Secondory header:"+JSON.stringify(tourItemObj));
  const dropdownRef = useRef(null);

  useEffect(() => {
    getTours();
  }, [currentPage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowToursDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getTours = async () => {
    const tourId = localStorage.getItem("initalTourId");
    //const tourItemObj = localStorage.getItem("tourItemObj");
    //console.log('Tour Data:'+ tourItemObj.tour_name);
    //alert(tourItemObj.tour_name);
    if (currentPage === "shows") {
      const resp = await getApiCall(ApiUrl.toursList("upcoming"));
      if (resp.status && resp.data.list.length>0
      ) {
        setToursList(resp.data.list);
        setSelectedTour(tourId);
        console.log('My Tour:'+JSON.stringify(tourItemObj));
        if (tourItemObj === "") {
          localStorage.setItem("initalTourId", resp.data.list[0]?.id);
          localStorage.setItem("tourItemObj", JSON.stringify(resp.data.list[0]));
          setSelectedTour(resp.data.list[0]);
        } else {
          //localStorage.setItem("initalTourId", tourItemObj.id);
          //localStorage.setItem("tourItemObj", JSON.stringify(tourItemObj));
          setSelectedTour(tourItemObj);
        }
      }
    }
  };


  const navigateToPath = (item) => {
    if (item.path === "/shows" || item.path === "/shows/") {
      item=tourItemObj;
      const tourId = localStorage.getItem("initalTourId");
      navigate("/shows", { state: { tourId: tourId, item } });
    } else {
      navigate(item.path);
    }
  };

  const renderMenuOptions = () => {
    // const renderSubMenuIcon = (isActive, title) => {
    //   return title === "Overview" ? (
    //     <DashboardIcon className="w-5 h-5" fill={isActive && "#fff"} />
    //   ) : title === "Shows" || title === "Shows Left" ? (
    //     <ShowsIcon className="w-5 h-5" fill={isActive && "#fff"} />
    //   ) : title === "Inventory" && currentPage !== "inventory" && currentPage !== "warehouse" && currentPage !== "trailer" ? (
    //     <InventoryIcon className="w-5 h-5" fill={isActive && "#fff"} />
    //   ) : title === "Accounting" ? (
    //     <AccountingIcon className="w-5 h-5" fill={isActive && "#fff"} />
    //   ) : title === "Merch IQ" ? (
    //     <MerchIqIcon className="w-5 h-5" fill={isActive && "#fff"} />
    //   ) : title === "Inventory" ? (
    //     <MerchIcon className="w-5 h-5" fill={isActive && "#fff"} />
    //   ) : title === "Warehouses" ? (
    //     <WarehouseIcon className="w-5 h-5" fill={isActive && "#fff"} />
    //   ) : title === "Trailers" ? (
    //     <TrailerIcon className="w-5 h-5" fill={isActive && "#fff"} />
    //   ) : title === "Shipment" ? (
    //     <ShipmentIcon className="w-5 h-5" fill={isActive && "#fff"} />
    //   ) : title ==="Orders" ?(
    //     <OrdersIcon className="w-5 h-5" fill={isActive && "#fff"} />
    //   ) : null;
    // };

    // return null;
      // <div className="flex flex-row gap-6">
      //   {secondaryMenu.map((item) => (
      //     <div
      //       className={`flex flex-row justify-center items-center ${item.path === pathname ? "bg-secondary " : ""
      //         } px-3 py-2 rounded-lg cursor-pointer gap-2 hoverable-button second-header-icons ${item.path !== pathname && "w-10 h-10"
      //         }`}
      //       key={item.id}
      //       onClick={() => navigateToPath(item)}
      //     >
      //       {renderSubMenuIcon(
      //         item.path === pathname ? true : false,
      //         item.title
      //       )}
      //       {item.path === pathname && (
      //         <p
      //           className={`font-pop text-sm ${item.path === pathname ? "text-white" : "text-secondary"
      //             }`}
      //         >
      //           {item.title}
      //         </p>
      //       )}
      //     </div>
      //   ))}
      // </div>
    // );
  };

  const navigateToShows = (item) => {
    setShowToursDropdown(false);
    setSelectedTour(item);
    const tourId = item.id;
    navigate("/shows", { state: { tourId: tourId, item } });
    setSearchInput("");
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredToursList = toursList
    ? toursList.filter((item) =>
      item.tour_name.toLowerCase().includes(searchInput.toLowerCase())
    )
    : [];

  return (
    <div className="bg-white px-6 py-3 second-header">
      <div className="flex flex-row justify-between items-center">
        {renderMenuOptions()}
        <ScrollButton />
        {/* <RadialMenu pageTitle={currentPage} /> */}
        {currentPage === "shows" && (
          <div className="relative" ref={dropdownRef}>
            <div
              className="flex flex-row justify-between items-center cursor-pointer"
              onClick={() => setShowToursDropdown(!showToursDropdown)}
            >
              <div className="flex flex-row justify-center items-center">
                <p className="font-pop font-medium text-primary text-md">
                  {selectedTour?.tour_name ??
                    (toursList && toursList.length > 0
                      ? toursList[0].tour_name
                      : "Manage Tour")}
                </p>
                <img
                  src="/chevron_down.png"
                  className="w-4 h-4 object-contain mt-2 mx-2"
                />
              </div>
            </div>
            {showToursDropdown && (
              <div className="absolute top-0 right-0 mt-10">
                <div className="flex flex-col">
                  <div className="sticky z-40" style={{ width: "188px" }}>
                    <SearchInput
                      searchInput={searchInput}
                      handleSearchInputChange={handleSearchInputChange}
                    />
                  </div>
                  <div className="absolute top-10 right-0 bg-white border border-primary rounded-md z-30 shadow-lg cursor-pointer">
                    <ul
                      className="max-h-44 overflow-y-scroll hide-scrollbar"
                      style={{ width: "188px" }}
                    >
                      {filteredToursList.map((item) => (
                        <li
                          key={item.id}
                          className="shadow-md"
                          onClick={() => {
                            navigateToShows(item);
                          }}
                        >
                          <p className="font-pop text-xs text-primary hover:bg-primary hover:text-white border-b p-2">
                            {item.tour_name}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SecondaryHeader;

import React, { useState } from "react";
import CustomModal from "../../../../Components/Modal";
import {
  IncountTable,
  InputTable,
  MoreDetailsTable,
  Sizes,
  SoldTable,
  Table,
  TotalTable,
  changeTaxOverride,
  countsPriceChange,
  getCountsList,
} from ".";
import { AppImages } from "../../../../Constants/Images";
import { toast } from "react-toastify";
import ImageSliderModal from '../../../../Pages/Home/Products/ImageSliderModal';


const CountsComp = (props) => {
  const {
    index,
    responseData,
    grossCounts,
    setgrossCounts,
    setGrossTotal,
    setTotalStands,
    setStandsTypeList,
    setStandsTotalGross,
    setResponseData,
    setStandType,
    setStandTypeData,
    setShowsData,
    setIsLoading,
    tour_id,
    show_id,
    stand_type,
  } = props;

  console.log(stand_type, "ss");
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [data3, setData3] = useState([]);
  const [dataAdds, setDataAdds] = useState();
  const [totalAdds, setTotalAdds] = useState([]);
  const [changePriceData, setChangePriceData] = useState();
  const [data5, setData5] = useState([]);
  const [moreDetailsValue, setMoreDetailsValue] = useState();
  const [expandAddProductModal, setAddExpandModal] = useState(false);
  const [taxOverride, setTaxOverride] = useState(
    responseData && responseData?.merch_tax
  );

  const showDropdown = () => {
    setShowMoreDetails(!showMoreDetails);
  };
  const [modalProps, setModalProps] = useState({
    visible: false,
    title: "",
    key: "",
  });


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImages, setModalImages] = useState([]);
  const handleImageClick = (images) => {
    const validImages = images.filter(img => img);
    // alert("4")
    if (validImages.length > 0) {
      setModalImages(validImages);
      setIsModalOpen(true);
    } else {
      // Display a placeholder image
      // setModalImages(["https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"]);
      // setIsModalOpen(true);
      toast.warning("No valid images found, displaying placeholder image.");
    }
  };

  const closeModalOne = () => {
    setModalProps({
      visible: false,
      title: "",
      key: "",
    });
    setShowMoreDetails(false);
  };
  const handleSaveDataAndCloseModalOne = (value, tax_per) => {
    closeModalOne();
    // setIsOpen(!isOpen)
    setShowMoreDetails(false);
    // var pricesArr
    const pricesArr = changePriceData
      ? changePriceData[0]?.data?.map((item, index) => {
          const qty_id = responseData?.child_list[index]?.qty_ids;
          const cost = Number(responseData?.child_list[index]?.cost);
          const sale_price = Number(item);

          return { qty_id: qty_id, cost: cost, sale_price: sale_price };
        })
      : responseData &&
        responseData?.trailer[1]?.data?.map((item, index) => {
          const qty_id = responseData?.child_list[index]?.qty_ids;
          const cost = Number(
            responseData && responseData?.child_list[index]?.cost
          );
          const sale_price = Number(item);
          // console.log({qty_id: qty_id, cost: cost, sale_price: sale_price}, 'priceItems')
          return { qty_id: qty_id, cost: cost, sale_price: sale_price };
        });
    if (value == "Tax Override") {
      changeTaxOverride({
        tour_id: tour_id,
        show_id: show_id,
        merch_id: responseData && responseData?.id,
        category: responseData && responseData?.category,
        tax_per: tax_per && tax_per,
      });
    }
    console.log(responseData?.trailer[1]?.data, "priceres");
    console.log(pricesArr, "priceArr");
    countsPriceChange({
      tour_id: tour_id,
      show_id: show_id,
      stand_type: stand_type,
      prices: pricesArr,
    });
    console.log(changePriceData && changePriceData, "changePrice");
    console.log(responseData, "responseprice");
    getCountsList(tour_id, show_id, stand_type);
  };
  const addMoreDetailsModal = (item, value) => {
    setMoreDetailsValue(value);
    console.log(moreDetailsValue, "moreDetailsValue");
    console.log(item, "moreDetails");
    setModalProps({
      visible: true,
      title: "General Information ",
      key: "addProduct",
    });
  };
  const handleTaxOverride = (event) => {
    setTaxOverride(event.target.value);
  };
  const totalTrlrStock =
    responseData &&
    responseData?.in_stock?.map((itm, ind) =>
      itm?.data?.reduce(
        (start, item, index) => (start = start + Number(item)),
        0
      )
    );
  const inCounts = responseData && responseData?.in_stock[0]?.data;
  const addsTotal = responseData?.in_stock[1]?.data;
  const combined = inCounts?.map(
    (item, index) => Number(item) + Number(addsTotal[index])
  );
  const price = responseData?.trailer[1];
  const gross = inCounts.map(
    (item, index) => Number(combined[index]) * Number(price?.data[index])
  );

  const compsAndOuts = inCounts?.map(
    (item, index) =>
      Number(combined[index]) -
      (Number(responseData?.out_stock[0].data[index]) +
        Number(responseData?.out_stock[1].data[index]))
  );

  const totalGross = inCounts?.map((item, index) =>
    Number(compsAndOuts[index] * Number(price?.data[index]).toFixed(2))
  );

  console.log(compsAndOuts, "totalccc");
  console.log(totalGross, "totalc");

  const totalOutStock = responseData && responseData?.out_stock?.map((item, ind) => item?.data?.reduce((start, itm, index) => start = start + Number(itm), 0))
  console.log(totalOutStock, 'totalOutStock')

  return (
    <>
      <div className="border border-primary rounded-lg p-5">
        <div className="flex flex-row align-center justify-around w-full">
          <div className="flex flex-row items-center gap-4">
            <div className="flex flex-col gap-2">
              <p className="text-primary font-medium font-pop text-sm">
                {responseData && responseData?.product_name}
              </p>
              <p className="text-secondary font-pop text-xs">
                {responseData && responseData?.category}
              </p>
              {/* <div className="my-2">
                                <img src={responseData && responseData?.image1} className="w-24 h-24" />
                                </div> */}
              <div className="relative ml-[10px] my-2 cursor-pointer">
                {responseData?.image2 ? (
                  <>
                    <img src={responseData?.image2} alt="Preview" className="w-24 h-24 z-10 rounded" />
                    <img src={responseData?.image1} alt="Preview" className="w-24 h-24 absolute top-[-10px] left-[-10px] bg-grey z-0 rounded" />
                  </>
                ) : (
                  <img src={responseData?.image1 || "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"} alt="Preview" className="w-24 h-24 z-10 rounded" />
                )}
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="relative">
                <div
                  className="cursor-pointer flex flex-row justify-center items-center bg-primary w-28	h-6	rounded-lg gap-2 mt-6"
                  onClick={showDropdown}
                >
                  <p className="font-pop text-white text-xs">More details</p>
                  <img
                    src="/chevron_down_white.png"
                    className="w-2 h-2 object-contain"
                  />
                </div>
                {showMoreDetails && (
                  <div className="absolute top-[-120%] right-0 mt-10">
                    <table className="border-collapse w-[120px] text-xs bg-white rounded-t-lg">
                      <thead>
                        <tr
                          className="cursor-pointer flex flex-row justify-center items-center bg-primary	h-6 gap-2 p-2 rounded-t-lg"
                          onClick={showDropdown}
                        >
                          <th className="text-white">More Details</th>
                          <img
                            src="/chevron_down_white.png"
                            className="w-2 h-2 object-contain rotate-180"
                          />
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="h-6 cursor-pointer">
                          <td
                            className="border p-2"
                            onClick={() =>
                              addMoreDetailsModal(
                                responseData && responseData,
                                "Change Price"
                              )
                            }
                          >
                            Change Price
                          </td>
                        </tr>
                        <tr className="h-6 cursor-pointer">
                          <td
                            className="border p-2"
                            onClick={() =>
                              addMoreDetailsModal(
                                responseData && responseData,
                                "Tax Override"
                              )
                            }
                          >
                            Tax Override
                          </td>
                        </tr>
                        <tr className="h-6 cursor-pointer">
                          <td className="border p-2">Crew Sales</td>
                        </tr>
                        <tr className="h-6 cursor-pointer">
                          <td className="border rounded-t-lg p-2">
                            Trailer Comps
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <ImageSliderModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} images={modalImages} />
                  </div>
                )}
              </div>
              <CustomModal
                handleModalClose={closeModalOne}
                modalProps={modalProps}
                className={`overflow-y-scroll w-[600px] rounded-2xl shadow-xl ${expandAddProductModal && "h-[600px]"
                  }`}
              >
                <div className="p-4">
                  <div className=" flex flex-row items-end gap-4">
                    <div className="flex flex-row gap-4">
                      <div className="flex flex-col gap-2">
                        <p className="text-primary font-medium font-pop text-sm">
                          {responseData && responseData?.product_name}
                        </p>
                        <p className="text-secondary font-pop text-xs ">
                          {responseData && responseData?.category}
                        </p>
                        <div className="my-2">
                          <img
                            src={responseData && responseData?.image1}
                            className="w-24 h-24"
                          />
                        </div>
                      </div>
                      <table className="border-collapse w-[120px] text-xs bg-white rounded-t-lg">
                        <thead>
                          <tr
                            className="cursor-pointer flex flex-row justify-center items-center bg-primary	h-6 gap-2 p-2 rounded-t-lg"
                            onClick={showDropdown}
                          >
                            <th className="text-white">More Details</th>
                            <img
                              src="/chevron_down_white.png"
                              className="w-2 h-2 object-contain rotate-180"
                            />
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            className={`h-6 ${
                              moreDetailsValue &&
                              moreDetailsValue == "Change Price" &&
                              "bg-[#555]"
                            } cursor-pointer`}
                          >
                            <td
                              className={`border ${
                                moreDetailsValue &&
                                moreDetailsValue == "Change Price" &&
                                "text-white"
                              } p-2`}
                              onClick={() =>
                                addMoreDetailsModal(
                                  responseData && responseData,
                                  "Change Price"
                                )
                              }
                            >
                              Change Price
                            </td>
                          </tr>
                          <tr
                            className={`h-6 ${
                              moreDetailsValue &&
                              moreDetailsValue == "Tax Override" &&
                              "bg-[#555]"
                            } cursor-pointer`}
                          >
                            <td
                              className={`border ${
                                moreDetailsValue &&
                                moreDetailsValue == "Tax Override" &&
                                "text-white"
                              } p-2`}
                              onClick={() =>
                                addMoreDetailsModal(
                                  responseData && responseData,
                                  "Tax Override"
                                )
                              }
                            >
                              Tax Override
                            </td>
                          </tr>
                          <tr className="h-6 cursor-pointer">
                            <td className="border p-2">Crew Sales</td>
                          </tr>
                          <tr className="h-6 cursor-pointer">
                            <td className="border rounded-t-lg p-2">
                              Trailer Comps
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <Sizes data={responseData?.child_list} />
                      {responseData &&
                        (moreDetailsValue == "Change Price" ? (
                          <MoreDetailsTable
                            responseData={responseData && responseData}
                            tour_id={tour_id}
                            show_id={show_id}
                            stand_type={stand_type}
                          />
                        ) : (
                          moreDetailsValue == "Tax Override" && (
                            <div className="bg-primary h-fit gap-2 rounded-lg">
                              <div className="text-white p-2">New Tax</div>
                              <input
                                type="number"
                                className={`w-[100px] border px-6 py-2 rounded-b-lg border-secondary focus:outline-none text-secondary font-pop text-[11px] text-center`}
                                value={taxOverride && taxOverride}
                                onChange={handleTaxOverride}
                              />
                            </div>
                          )
                        ))}
                    </div>
                    <div className="flex flex-row gap-4">
                      <button
                        type="button"
                        className="flex justify-center items-center  bg-grey h-8 w-8 rounded-lg shadow-lg"
                        onClick={closeModalOne}
                      >
                        <img src={AppImages.cancelBlack} className="w-3 h-3" />
                      </button>
                      <button
                        type="submit"
                        onClick={() =>
                          handleSaveDataAndCloseModalOne(
                            moreDetailsValue,
                            taxOverride
                          )
                        }
                        className="flex justify-center items-center  bg-secondary h-8 w-8 rounded-lg shadow-lg"
                      >
                        <img src={AppImages.checkIcon} className="w-3 h-3" />
                      </button>
                    </div>
                  </div>
                </div>
              </CustomModal>
              <div className="flex flex-row justify-center items-center bg-primary w-20	h-6	rounded-lg gap-2">
                <p className="font-pop text-white text-xs">More Inv</p>
                <img
                  src="/chevron_down_white.png"
                  className="w-2 h-2 object-contain"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-[1.2rem]">
            {responseData && stand_type == 1 && (
              <Table
                data={[responseData?.global]}
                tour_id={tour_id}
                show_id={show_id}
                stand_type={stand_type}
              />
            )}
            <Sizes data={responseData?.child_list} />
            {responseData && (
              <Table
                data={responseData?.trailer}
                tour_id={tour_id}
                show_id={show_id}
                stand_type={stand_type}
              />
            )}
            {responseData && responseData?.in_stock.length > 0 && (
              <div className="flex flex-col">
                <IncountTable
                  responseData={responseData}
                  data={responseData?.in_stock.slice(0, 2)}
                  grossCounts={grossCounts}
                  setResponseData={setResponseData}
                  setStandType={setStandType}
                  setStandTypeData={setStandTypeData}
                  setShowsData={setShowsData}
                  setgrossCounts={setgrossCounts}
                  setGrossTotal={setGrossTotal}
                  setTotalStands={setTotalStands}
                  setStandsTypeList={setStandsTypeList}
                  setStandsTotalGross={setStandsTotalGross}
                  setData={setData3}
                  dataAdds={responseData?.in_stock.slice(2, 5)}
                  setDataAdds={setDataAdds}
                  totalAdds={responseData?.in_stock[1]?.data}
                  setTotalAdds={setTotalAdds}
                  tour_id={tour_id}
                  show_id={show_id}
                  stand_type={stand_type}
                />
                <div className="flex flex-row">
                  <div className={`px-6 py-2 w-[100px]`}>
                    <p className="text-primary font-pop text-[11px] text-center">
                      {totalTrlrStock && totalTrlrStock[0]}
                    </p>
                  </div>
                  <div className={`px-6 py-2 w-[100px]`}>
                    <p className="text-primary font-pop text-[11px] text-center">
                      {totalTrlrStock && totalTrlrStock[1]}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {responseData && (
              <div className="flex flex-col">
                <TotalTable responseData={responseData} />
                <div className="flex flex-row">
                  <div className={`px-6 py-2`}>
                    <p className="text-primary font-pop text-[11px] text-center">
                      {combined?.reduce(
                        (start, item, index) => (start = start + Number(item))
                      )}
                    </p>
                  </div>
                  <div className={`px-6 py-2 min-w-[100px]`}>
                    <p className="text-primary font-pop text-[11px] text-center">
                      ${" "}
                      {gross?.reduce(
                        (start, item, index) => (start = start + Number(item))
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {responseData && (
              <div className="flex flex-col">
                <InputTable
                responseData={responseData}
                data={responseData?.out_stock}
                setgrossCounts={setgrossCounts}
                setGrossTotal={setGrossTotal}
                setTotalStands={setTotalStands}
                setStandsTypeList={setStandsTypeList}
                setStandsTotalGross={setStandsTotalGross}
                setData={setData5}
                tour_id={tour_id}
                show_id={show_id}
                stand_type={stand_type}
              />
              <div className="flex flex-row">
                  <div className={`px-6 py-2 w-[100px]`}>
                    <p className="text-primary font-pop text-[11px] text-center">
                      {totalOutStock && totalOutStock[0]}
                    </p>
                  </div>
                  <div className={`px-6 py-2 w-[100px]`}>
                    <p className="text-primary font-pop text-[11px] text-center">
                    {totalOutStock && totalOutStock[1]}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {responseData && (
              <div>
                <SoldTable
                responseData={responseData}
                setData={setData5}
                tour_id={tour_id}
                show_id={show_id}
                stand_type={stand_type}
              />
              <div className="flex flex-row">
                  <div className={`px-6 py-2`}>
                    <p className="text-primary font-pop text-[11px] text-center">
                      {compsAndOuts?.reduce(
                        (start, item, index) => (start = start + Number(item))
                      )}
                    </p>
                  </div>
                  <div className={`px-6 py-2`}>
                    <p className="text-primary font-pop text-[11px] text-center">
                      ${" "}
                      {totalGross?.reduce(
                        (start, item, index) => (start = start + Number(item))
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CountsComp;

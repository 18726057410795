import React from "react";
import CustomModal from "../../../Components/Modal";
import WarehouseForm from "../../../Pages/Home/Warehouse/WarehouseForm";
import ProductsStockForm from "../../Home/Products/ProductsStockForm";

const ModalCustomWarehouse = ({ showModal, closeModal, modalProps, merchDetails, updateMerchStocks, selectedWarehouse, addOrUpdateNewWarehouse, warehouseDetails }) => {
  const handleClickClose = () => {
    closeModal();
  };

  return (
    <CustomModal
      handleModalClose={closeModal}
      modalProps={modalProps}
      className={`overflow-y-scroll w-full rounded-2xl shadow-xl`}
    >
      {modalProps.key === "merchStock" ? (
        <ProductsStockForm
          handleModalClose={closeModal}
          modalProps={modalProps}
          merchDetails={merchDetails}
          stockTypes={[]}
          handleMerchStockSubmit={updateMerchStocks}
          currentStockType={'warehouse'}
          currentStockId={selectedWarehouse.id}
        />
      ) : (
        <WarehouseForm
          handleModalClose={closeModal}
          handleAddOrUpdateWarehouse={addOrUpdateNewWarehouse}
          singlewarehouseDetails={warehouseDetails}
        />
      )}
    </CustomModal>
  );
};

export default ModalCustomWarehouse;

import React, { useCallback, useEffect, useState } from "react";
import withHoc from "../../../../Components/Hoc";
import { AppData } from "../../../../Constants/AppData";
import { useLocation } from "react-router-dom";
import { apiCall, getApiCall } from "../../../../Utilites/Api";
import { ApiUrl } from "../../../../Constants/Api";
import CustomModal from "../../../../Components/Modal";
import { AppImages } from "../../../../Constants/Images";
import { Formik, Form } from "formik";
import {
  covertToReadableFormat,
  convertToDbFormat,
} from "../../../../Utilites/Date";
import ShowsSettlements from "../ShowsSettlements";
import CountsComp from "./CountsComp";
import LoadingOverlay from "react-loading-overlay";
import { BeatLoader } from "react-spinners";

// import { CancelForm } from "..";

export const getCountsList = async (tour_id, show_id, stand_type) => {
  // const [responseData, setResponseData] = useState([]);
  const resp = await getApiCall(
    ApiUrl.merchCounts(tour_id, show_id, stand_type)
  );
  if (resp.status) {
    //   setProductList(resp.data.merch_list);
    console.log(resp.data, "resp");
    const filteredArray = resp.data?.merch_list;
    const standTypeData = resp.data?.stand_type_list;
    const showsData = resp.data?.shows_data;

    // setResponseData(filteredArray);
    console.log(filteredArray, "respf");
    //return filteredArray
    return { filteredArray, standTypeData, showsData };
  }
};

export const getCountsMainStand = async (tour_id, show_id) => {
  const resp = await getApiCall(ApiUrl.countsMainStand(tour_id, show_id));
  if (resp?.status) {
    console.log(resp?.data, "standsData");
    const standsCountsGross = resp.data?.merch_counts;
    const standsTotal = resp.data?.total;
    const standsTotalGross = resp.data?.gross_total;
    const totalStands = resp.data?.stands;
    const standTypeList = resp.data?.stand_type_list;
    return {
      standsCountsGross,
      standsTotal,
      standsTotalGross,
      totalStands,
      standTypeList,
    };
  }
};

export const changeTaxOverride = async ({ ...pay }) => {
  const payload = {
    tour_id: pay?.tour_id,
    show_id: pay?.show_id,
    merch_id: pay?.merch_id,
    category: pay?.category,
    tax_per: pay?.tax_per,
  };
  const resp = await apiCall(ApiUrl.changeTaxPer(), payload);
  if (resp.status) {
    return resp.data;
  }
};

export const createCounts = async ({ ...pay }) => {
  const payload = {
    qty_id: pay?.qty_id,
    tour_id: pay?.tour_id,
    show_id: pay?.show_id,
    stand_type: pay?.stand_type,
    sale_price: pay?.sale_price ? pay?.sale_price : 0,
    cost: pay?.cost_price ? pay?.cost_price : 0,
    sale_price: pay?.sale_price ? pay?.sale_price : 0,
    in_stock: pay?.in_stock,
    adds1: pay?.adds1,
    adds2: pay?.adds2,
    adds3: pay?.adds3,
    comps: pay?.comps,
    out_stock: pay?.out_stock,
  };
  console.log(payload, "pay");
  // const [responseData, setResponseData] = useState([]);
  const resp = await apiCall(ApiUrl.countsCreate(), payload);
  if (resp.status) {
    //   setProductList(resp.data.merch_list);
    console.log(resp.data, "resp");
    return resp.data;
  }
};

export const countsPriceChange = async ({ ...pay }) => {
  const payload = {
    tour_id: pay?.tour_id,
    show_id: pay?.show_id,
    stand_type: pay?.stand_type,
    prices: pay?.prices,
  };
  // console.log(pay?.responseData && pay?.responseData, 'payloadRes')
  console.log(payload, "payload");
  const resp = await apiCall(ApiUrl.countsCostChange(), payload);
  if (resp.status) {
    return resp.data;
    console.log(resp.data, "changeCost");
  }
};

export const taxMethodChange = async ({ ...pay }) => {
  const payload = {
    tour_id: pay?.tour_id,
    show_id: pay?.show_id,
    tax_method: pay?.tax_method,
  };
  const resp = await apiCall(ApiUrl.changeTaxMethod(), payload);
  if (resp.status) {
    return resp.data;
  }
};

const postNewStand = async (tour_id, show_id, stand_type) => {
  const payload = {
    tour_id: tour_id,
    show_id: show_id,
    stand_type: stand_type,
  };
  const resp = await apiCall(ApiUrl.addNewStand(), payload);
  if (resp.status) {
    return resp.data;
  }
};

export const Sizes = (childList) => {
  console.log(childList?.data, "size");
  return (
    <div className="flex flex-col gap-2 justify-center align-center ">
      {childList &&
        childList?.data?.map((item, index) => (
          item.size ? (
            <div
              key={index}
              className="bg-secondary px-2 py-1 border-b border-secondary rounded-md"
            >
              <p className="text-white font-pop text-xs text-center">
                {item.size_name}
              </p>
            </div>
          ) : null
        ))
      }

    </div>
  );
};

const CancelForm = ({
  handleModalClose,
  handleStand,
  handleAddStandConfirmation,
}) => {
  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        handleStand();
      }}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <div className="p-4">
            <div className="flex flex-row justify-between items-center gap-20 w-200">
              <div className="flex flex-col">
                <p className="font-pop text-xl font-medium text-primary">
                  Are you sure you want to Add Stand ?
                </p>
              </div>
            </div>
          </div>
          <div className="border mx-4" />
          <div className="mx-4">
            <div className="flex flex-row gap-4 mt-6 justify-center items-center">
              <button
                type="submit"
                className="flex justify-center items-center bg-secondary text-white h-8 w-20 rounded-lg shadow-lg"
                onClick={handleAddStandConfirmation}
              >
                Yes
              </button>

              <button
                className="flex justify-center items-center bg-grey h-8 w-20 rounded-lg shadow-lg"
                onClick={handleModalClose}
              >
                No
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const ShowsCounts = () => {
  const location = useLocation();
  const showItemObj = location?.state?.item;
  const tour_id = showItemObj.tour_id;
  const show_id = showItemObj.id;
  const showTypeFromProps = location?.state?.item?.stand_type; // Assuming stand_type is coming from props
  const [stand_type, setStandType] = useState(showTypeFromProps || 1);
  console.log(showItemObj, "showresp");
  console.log(showItemObj?.total, "tt");
  const [responseData, setResponseData] = useState();
  const [standTypeData, setStandTypeData] = useState([
    { id: 0, stand_type: 1 },
  ]);
  const [showsData, setShowsData] = useState([]);
  const [addStand, setAddStand] = useState(false);
  const [showsField, setShowsField] = useState("Counts");
  const [isLoading, setIsLoading] = useState(false);
  const [grossCounts, setgrossCounts] = useState();
  const [grossTotal, setGrossTotal] = useState();
  const [standsTotalGross, setStandsTotalGross] = useState();
  const [totalStands, setTotalStands] = useState();
  const [standsTypeList, setStandsTypeList] = useState();
  // const [standConfirmationData, setStandConfirmationData] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getCountsList(tour_id, show_id, stand_type && stand_type)
      .then(({ filteredArray, standTypeData, showsData }) => {
        setResponseData(filteredArray);
        setStandType(stand_type);
        setStandTypeData(standTypeData && standTypeData);
        setShowsData(showsData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching merch list:", error);
      });
  }, []);
  useEffect(() => {
    getCountsMainStand(tour_id, show_id)
      .then(
        ({
          standsCountsGross,
          standsTotal,
          standsTotalGross,
          totalStands,
          standTypeList,
        }) => {
          setgrossCounts(standsCountsGross);
          setGrossTotal([standsTotal]);
          setStandsTotalGross(standsTotalGross);
          setTotalStands(totalStands);
          setStandsTypeList(standTypeList);
        }
      )
      .catch((error) => {
        console.error("Error fetching merch list:", error);
      });
  }, []);

  const handleSetStandTypeData = (item) => {
    setIsLoading(true);
    setStandType(item);
    getCountsList(tour_id, show_id, item)
      .then(({ filteredArray, standTypeData, showsData }) => {
        setResponseData(filteredArray);
        setStandType(item);
        setStandTypeData(standTypeData);
        setShowsData(showsData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching merch list:", error);
      });
    console.log(responseData, "standres");
    console.log(standTypeData, "standTy");
  };
  const handleStandType = (e) => {
    setAddStand(true);
  };

  const handleAddStandConfirmation = async () => {
    setIsLoading(true);
    // setStandConfirmationData(null);
    setAddStand(null);
    console.log(standTypeData, "standTyp");
    const newStandType =
      Number(standTypeData[standTypeData.length - 1]?.stand_type) + 1;
    // setStandType(newStandType);
    setStandTypeData([
      ...standTypeData,
      {
        id: Number(standTypeData[standTypeData.length - 1]?.id) + 1,
        stand_type: newStandType && newStandType,
      },
    ]);
    postNewStand(tour_id, show_id, newStandType);
    setIsLoading(false);
    // if (resp.status) {
    //   setIsLoading(false);
    //   getTours(selectedTourStatus);
    //   toast.success("Tour cancelled successfully");
    // } else {
    //   toast.error("Tour not cancelled. Please try later");
    // }
  };
  console.log(totalStands && totalStands, "totalStands");
  console.log(showsData, "showsData");
  console.log(standTypeData, "standTyp");
  console.log(responseData && responseData, "rrr");
  const priceArray = [];
  console.log(priceArray, "pp");

  console.log(responseData?.in_stock, "cdaAdd");
  console.log(showItemObj, "ttt");
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [salesPrice, setSalesPrice] = useState(
    responseData && responseData?.map((item, index) => item?.trailer[1]?.data)
  );

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  const arrayOne =
    responseData &&
    responseData?.child_list?.map((item, index) => {
      item.sale_price = Number(responseData?.trailer[1]?.data[index]);
      return item;
    });
  console.log(arrayOne, "arrayOne");
  console.log(responseData, "arrayOneee");
  const previousChildList = responseData && responseData?.child_list;
  // const [previousChildList, setPreviousChildList] = useState(childPreviousList)
  const [salesCount, setSalesCount] = useState(0);
  const [salesCountOne, setSalesCountOne] = useState(0);
  useEffect(() => {
    if (showsData && showsData?.tax_method == "exclusive") {
      setSalesCountOne(salesCountOne + 1);
      if (salesCountOne < 1) {
        const price = responseData?.map(
          (item, index) => item?.trailer[1]?.data
        );
        setSalesPrice(price);
        const taxValue =
          responseData && responseData?.map((item, index) => item?.merch_tax);
        const priceData = price?.map((item, index) =>
          item.map(
            (itm, indexOne) => itm + (taxValue[index] / 100) * itm.toFixed(2)
          )
        );
        responseData?.map((item, index) => {
          item.trailer[1].data = priceData[index];
        });
      }
    }
  }, [showsData]);
  const handleOptionClick = (tour_id, show_id, option, showsData) => {
    console.log(option, "option");
    console.log(showsData, "showsData");
    console.log(showItemObj, "optionshow");
    console.log(responseData && responseData, "option");
    const taxValue =
      responseData && responseData?.map((item, index) => item?.merch_tax);
    console.log(taxValue, "taxValue");
    setSelectedOption(option);
    const price = responseData?.map((item, index) => item?.trailer[1]?.data);
    console.log(price, "optionPrice");
    if (salesCount < 1 && salesCountOne < 1) {
      setSalesPrice(price);
    }
    console.log(salesPrice && salesPrice, "salespoption");
    console.log(taxValue, "optionpp");
    console.log(previousChildList, "optionPre");
    console.log(responseData, "optionRespo");
    console.log(showsData, "showsOption");
    if (showsData && showsData?.tax_method == "inclusive") {
      if (option == "exclusive") {
        setSalesCount(salesCount + 1);
        if (salesCount < 1) {
          const priceData = price?.map((item, index) =>
            item.map(
              (itm, indexOne) => itm + (taxValue[index] / 100) * itm.toFixed(2)
            )
          );
          responseData?.map((item, index) => {
            item.trailer[1].data = priceData[index];
          });
          setIsOpen(false);
          taxMethodChange({
            tour_id: tour_id,
            show_id: show_id,
            tax_method: option,
          });
          return priceData;
        }
      } else {
        setSalesCount(0);
        salesPrice
          ? responseData?.map((item, index) => {
            item.trailer[1].data = salesPrice[index];
          })
          : responseData?.map((item, index) => {
            item.trailer[1].data = price[index];
          });
        taxMethodChange({
          tour_id: tour_id,
          show_id: show_id,
          tax_method: option,
        });
      }
    } else if (showsData && showsData?.tax_method == "exclusive") {
      if (option == "inclusive") {
        setSalesCountOne(0);
        salesPrice &&
          responseData?.map((item, index) => {
            item.trailer[1].data = salesPrice[index];
          });
        taxMethodChange({
          tour_id: tour_id,
          show_id: show_id,
          tax_method: option,
        });
      } else {
        setSalesCountOne(salesCountOne + 1);
        if (salesCountOne < 1) {
          const price = responseData?.map(
            (item, index) => item?.trailer[1]?.data
          );
          setSalesPrice(price);
          const taxValue =
            responseData && responseData?.map((item, index) => item?.merch_tax);
          const priceData = price?.map((item, index) =>
            item.map(
              (itm, indexOne) => itm + (taxValue[index] / 100) * itm.toFixed(2)
            )
          );
          responseData?.map((item, index) => {
            item.trailer[1].data = priceData[index];
          });
        }
      }
    }
    taxMethodChange({ tour_id: tour_id, show_id: show_id, tax_method: option });
    console.log(responseData, "resoption");
    setIsOpen(false);
  };

  console.log(responseData, "optionRespoOne");
  console.log(salesCount, "salesCount");
  console.log(salesCountOne, "salesCountone");

  // const grossValueArray =
  //   responseData &&
  //   responseData?.map((itm, indexOne) =>
  //     itm?.trailer[1].data?.map((item, index) => {
  //       const sum =
  //         Number(
  //           Number(itm?.in_stock[0]?.data[index]) +
  //             Number(itm?.in_stock[1]?.data[index])
  //         ) -
  //         (Number(itm?.out_stock[0]?.data[index]) +
  //           Number(itm?.out_stock[1]?.data[index]));
  //       return Number(sum) * Number(itm?.trailer[1]?.data[index]).toFixed(2);
  //     })
  //   );

  // console.log(grossValueArray, "ggg");
  // const grossValue = grossValueArray?.map((itm, index) =>
  //   itm.reduce((start, item) => Number(start) + Number(item), 0)
  // );
  // console.log(grossValue, "totalggg");

  // const grossValueInArray =
  //   responseData &&
  //   responseData?.map((itm, indexOne) =>
  //     itm?.trailer[1]?.data?.map((item, index) => {
  //       return (
  //         Number(itm?.in_stock[0]?.data[index]) *
  //         Number(itm?.trailer[1]?.data[index]).toFixed(2)
  //       );
  //     })
  //   );
  // const grossValueIn = grossValueInArray?.map((itm, index) =>
  //   itm.reduce((start, item) => Number(start) + Number(item), 0)
  // );
  // console.log(grossValueInArray, "gggvvv");
  // console.log(grossValueIn, "gggvin");
  // const grossaddsArray =
  //   responseData &&
  //   responseData?.map((itm, indexOne) =>
  //     itm?.trailer[1]?.data?.map((item, index) => {
  //       return (
  //         Number(itm?.in_stock[1]?.data[index]) *
  //         Number(itm?.trailer[1]?.data[index]).toFixed(2)
  //       );
  //     })
  //   );
  // const grossValueAdd =
  //   grossaddsArray &&
  //   grossaddsArray?.map((itm, index) =>
  //     itm.reduce((start, item) => Number(start) + Number(item), 0)
  //   );
  // console.log(grossValueAdd, "gggvad");
  // const compsArray =
  //   responseData &&
  //   responseData?.map((itm, indexOne) =>
  //     itm?.trailer[1]?.data?.map((item, index) => {
  //       return (
  //         Number(itm?.out_stock[0]?.data[index]) *
  //         Number(itm?.trailer[1]?.data[index]).toFixed(2)
  //       );
  //     })
  //   );
  // const compsValue =
  //   compsArray &&
  //   compsArray?.map((itm, index) =>
  //     itm.reduce((start, item) => Number(start) + Number(item), 0)
  //   );
  // console.log(compsValue, "gggcomps");
  // const apperalArray = [
  //   grossValueIn?.reduce((start, item, index) => {
  //     if (responseData && responseData[index]?.category == "Apparel") {
  //       const sum = Number(start) + Number(item);
  //       return sum;
  //     }
  //   }, 0) || 0,
  //   grossValueAdd?.reduce((start, item, index) => {
  //     if (responseData && responseData[index]?.category == "Apparel") {
  //       const sum = Number(start) + Number(item);
  //       return sum;
  //     }
  //   }, 0) || 0,
  //   Number(
  //     grossValueIn?.reduce((start, item, index) => {
  //       if (responseData && responseData[index]?.category == "Apparel") {
  //         const sum = Number(start) + Number(item);
  //         return sum;
  //       }
  //     }, 0)
  //   ) +
  //     Number(
  //       grossValueAdd?.reduce((start, item, index) => {
  //         if (responseData && responseData[index]?.category == "Apparel") {
  //           const sum = Number(start) + Number(item);
  //           return sum;
  //         }
  //       }, 0)
  //     ) || 0,
  //   compsValue?.reduce((start, item, index) => {
  //     if (responseData && responseData[index]?.category == "Apparel") {
  //       const sum = Number(start) + Number(item);
  //       return sum;
  //     }
  //   }, 0) || 0,
  //   grossValue?.reduce((start, item, index) => {
  //     if (responseData && responseData[index]?.category == "Apparel") {
  //       const sum = Number(start) + Number(item);
  //       return sum;
  //     }
  //   }, 0) || 0,
  // ];

  // const othersArray = [
  //   grossValueIn?.reduce((start, item, index) => {
  //     if (responseData && responseData[index]?.category == "Others") {
  //       const sum = Number(start) + Number(item);
  //       return sum;
  //     }
  //   }, 0) || 0,
  //   grossValueAdd?.reduce((start, item, index) => {
  //     if (responseData && responseData[index]?.category == "Others") {
  //       const sum = Number(start) + Number(item);
  //       return sum;
  //     }
  //   }, 0) || 0,
  //   Number(
  //     grossValueIn?.reduce((start, item, index) => {
  //       if (responseData && responseData[index]?.category == "Others") {
  //         const sum = Number(start) + Number(item);
  //         return sum;
  //       }
  //     }, 0)
  //   ) +
  //     Number(
  //       grossValueAdd?.reduce((start, item, index) => {
  //         if (responseData && responseData[index]?.category == "Others") {
  //           const sum = Number(start) + Number(item);
  //           return sum;
  //         }
  //       }, 0)
  //     ) || 0,
  //   compsValue?.reduce((start, item, index) => {
  //     if (responseData && responseData[index]?.category == "Others") {
  //       const sum = Number(start) + Number(item);
  //       return sum;
  //     }
  //   }, 0) || 0,
  //   grossValue?.reduce((start, item, index) => {
  //     if (responseData && responseData[index]?.category == "Others") {
  //       const sum = Number(start) + Number(item);
  //       return sum;
  //     }
  //   }, 0) || 0,
  // ];

  // const musicArray = [
  //   grossValueIn?.reduce((start, item, index) => {
  //     if (responseData && responseData[index]?.category == "Music") {
  //       const sum = Number(start) + Number(item);
  //       return sum;
  //     }
  //   }, 0) || 0,
  //   grossValueAdd?.reduce((start, item, index) => {
  //     if (responseData && responseData[index]?.category == "Music") {
  //       const sum = Number(start) + Number(item);
  //       return sum;
  //     }
  //   }, 0) || 0,
  //   Number(
  //     grossValueIn?.reduce((start, item, index) => {
  //       if (responseData && responseData[index]?.category == "Music") {
  //         const sum = Number(start) + Number(item);
  //         return sum;
  //       }
  //     }, 0)
  //   ) +
  //     Number(
  //       grossValueAdd?.reduce((start, item, index) => {
  //         if (responseData && responseData[index]?.category == "Music") {
  //           const sum = Number(start) + Number(item);
  //           return sum;
  //         }
  //       }, 0)
  //     ) || 0,
  //   compsValue?.reduce((start, item, index) => {
  //     if (responseData && responseData[index]?.category == "Music") {
  //       const sum = Number(start) + Number(item);
  //       return sum;
  //     }
  //   }, 0) || 0,
  //   grossValue?.reduce((start, item, index) => {
  //     if (responseData && responseData[index]?.category == "Others") {
  //       const sum = Number(start) + Number(item);
  //       return sum;
  //     }
  //   }, 0) || 0,
  // ];

  // console.log(apperalArray, "gggappa");
  // console.log(othersArray, "gggoth");
  // console.log(musicArray, "gggm");
  //console.log(grossCounts && grossCounts, "dataggg");
  const dataArray = grossCounts && grossCounts;
  const totalArray = grossTotal && grossTotal;
  const handleShowsFields = (item) => {
    setShowsField(item);
  };

  const totalInArray =
    grossCounts &&
    grossCounts?.map((item, index) =>
      item?.data.reduce((start, itm, ind) => start + itm, 0)
    );
  //console.log(totalInArray, "standstotalIn");

  //console.log(dataArray && dataArray, "dataArray");
  return (
    <LoadingOverlay
      active={isLoading}
      classNamePrefix="customLoader"
      spinner={<BeatLoader color="white" size={20} />}
    >
      <>
        <CustomModal
          handleModalClose={() => setAddStand(null)}
          modalProps={{
            visible: !!addStand,
            title: "Add Stand Confirmation",
            key: "addStandConfirmation",
          }}
        >
          {addStand && (
            <CancelForm
              handleModalClose={() => setAddStand(null)}
              handleStand={handleAddStandConfirmation}
            />
          )}
        </CustomModal>
        <div className="bg-grey px-7 py-3">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row gap-8">
              <div
                className="flex flex-row justify-center items-center gap-2 cursor-pointer"
                onClick={() => handleShowsFields("Counts")}
              >
                <div className="flex justify-center items-center border border-secondary h-7 w-7 rounded-lg">
                  <img src="/counts.png" className="w-3 h-3 object-contain" />
                </div>
                <p
                  className={`font-pop text-xs ${showsField == "Counts"
                      ? "font-bold text-primary"
                      : "text-secondary font-medium"
                    }`}
                >
                  Counts
                </p>
              </div>
              <div
                className="flex flex-row justify-center items-center gap-2 cursor-pointer"
                onClick={() => handleShowsFields("Settlements")}
              >
                <div className="flex justify-center items-center border border-secondary h-7 w-7 rounded-lg">
                  <img src="/settle.png" className="w-3 h-3 object-contain" />
                </div>
                <p
                  className={`font-pop text-xs ${showsField == "Settlements"
                      ? "font-bold text-primary"
                      : "text-secondary font-medium"
                    }`}
                >
                  Settlements
                </p>
              </div>
            </div>
            <div className="flex flex-row gap-8">
              <div className="flex flex-row justify-center items-center gap-2">
                <div className="flex justify-center bg-primary items-center border border-secondary h-9 w-9 rounded-lg">
                  <img src="/printer.png" className="w-5 h-5 object-contain" />
                </div>
              </div>
              <div className="flex flex-row justify-center items-center gap-2">
                <div className="flex justify-center bg-primary items-center border border-secondary h-9 w-9 rounded-lg">
                  <img src="/xcel.png" className="w-5 h-5 object-contain" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showsField == "Counts" ? (
          <>
            <div className="flex flex-row justify-between items-center bg-white  px-7 py-3 gap-6">
              <div className="border border-primary rounded-lg p-3">
                <div className="flex flex-row gap-2">
                  {showItemObj && showItemObj?.start_date && (
                    <div className="flex flex-row justify-center items-center bg-primary w-48	h-8	rounded-lg gap-2">
                      <p className="font-pop text-xs text-white text-center font-medium">
                        {covertToReadableFormat(showItemObj.start_date) +
                          " - " +
                          covertToReadableFormat(showItemObj.end_date)}
                      </p>
                    </div>
                  )}
                  <div className="flex flex-row justify-center items-center bg-primary w-48	h-8	rounded-lg gap-2">
                    <p className="font-pop text-white text-xs">
                      {showItemObj.venue_rep_name}
                    </p>
                    <img
                      src="/chevron_down_white.png"
                      className="w-2 h-2 object-contain"
                    />
                  </div>
                  <div className="relative">
                    <div
                      className="flex flex-row justify-center items-center bg-primary px-3 h-8 rounded-lg gap-2 cursor-pointer"
                      onClick={toggleDropdown}
                    >
                      <p className="font-pop text-white text-xs">
                        {selectedOption
                          ? selectedOption == "inclusive"
                            ? "Sales Tax Included"
                            : "Sales Tax Excluded"
                          : showsData && showsData?.tax_method == "inclusive"
                            ? "Sales Tax Included"
                            : "Sales Tax Excluded"}
                      </p>
                      <img
                        src="/chevron_down_white.png"
                        className={`w-2 h-2 object-contain transform ${isOpen ? "rotate-180" : "rotate-0"
                          }`}
                      />
                    </div>
                    {isOpen && (
                      <div className="absolute top-full left-0 mt-1 w-[160px] bg-white shadow-lg rounded-lg">
                        <div
                          className="cursor-pointer p-2 hover:bg-gray-200"
                          onClick={() =>
                            handleOptionClick(
                              tour_id,
                              show_id,
                              "inclusive",
                              showsData
                            )
                          }
                        >
                          Sales Tax Included
                        </div>
                        <div
                          className="cursor-pointer p-2 hover:bg-gray-200"
                          onClick={() =>
                            handleOptionClick(
                              tour_id,
                              show_id,
                              "exclusive",
                              showsData
                            )
                          }
                        >
                          Sales Tax Excluded
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-row">
                    <div className="flex flex-row justify-center items-center bg-primary px-3	h-8	rounded-l-lg gap-2">
                      <p className="font-pop text-white text-xs">Tax Fee %</p>
                    </div>
                    <div className="flex flex-row justify-center items-center bg-white border border-primary w-16	h-8	rounded-none gap-2">
                      <p className="font-pop text-secondary text-xs">
                        {showsData && showsData?.tax_apparel}%
                      </p>
                    </div>
                    <div className="flex flex-row justify-center items-center bg-white border border-primary w-16	h-8	rounded-none gap-2">
                      <p className="font-pop text-secondary text-xs">
                        {showsData && showsData?.tax_music}%
                      </p>
                    </div>
                    <div className="flex flex-row justify-center items-center bg-white border border-primary w-16	h-8	rounded-r-lg gap-2">
                      <p className="font-pop text-secondary text-xs">
                        {showsData && showsData?.tax_others}%
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border border-primary rounded-lg p-3">
                <div className="flex flex-row gap-2">
                  {standTypeData &&
                    standTypeData.map((item) =>
                      item?.stand_type === stand_type ? (
                        <div className="flex flex-row justify-center items-center bg-primary border border-primary w-7 h-7 rounded-lg cursor-pointer">
                          <p className="font-pop text-white text-xs">
                            {item?.stand_type}
                          </p>
                        </div>
                      ) : (
                        <div
                          className="flex flex-row justify-center items-center bg-white border border-primary w-7	h-7	rounded-lg cursor-pointer"
                          key={item?.stand_type}
                          onClick={() =>
                            handleSetStandTypeData(item?.stand_type)
                          }
                        >
                          <p className="font-pop text-secondary text-xs">
                            {item?.stand_type}
                          </p>
                        </div>
                      )
                    )}
                  {/* {standTypeData && standTypeData.length === 0 && (
                      <div className="flex flex-row justify-center items-center bg-primary border border-primary w-7	h-7	rounded-lg">
                        <p className="font-pop text-white text-xs">
                          1
                        </p>
                      </div>
                    )} */}
                  <div className="flex flex-row cursor-pointer justify-center items-center bg-primary w-24	h-8	rounded-lg gap-2">
                    <p
                      className="font-pop text-white text-xs"
                      onClick={(e) => handleStandType(e)}
                    >
                      {" "}
                      + Add Stand
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 bg-white px-7 py-4">
              {/* {responseData && <CountsComp responseData={responseData} tour_id={tour_id} show_id={show_id} stand_type={stand_type} />} */}
              {responseData &&
                responseData?.map((item, index) => (
                  <CountsComp
                    index={index}
                    responseData={item}
                    grossCounts={grossCounts}
                    setResponseData={setResponseData}
                    setStandType={setStandType}
                    setStandTypeData={setStandTypeData}
                    setShowsData={setShowsData}
                    setIsLoading={setIsLoading}
                    setgrossCounts={setgrossCounts}
                    setGrossTotal={setGrossTotal}
                    setStandsTypeList={setStandsTypeList}
                    setTotalStands={setTotalStands}
                    setStandsTotalGross={setStandsTotalGross}
                    tour_id={tour_id}
                    show_id={show_id}
                    stand_type={stand_type}
                  />
                ))}
              <div className="border border-primary rounded-lg p-5">
                <div className="flex flex-row items-center">
                  <div className="flex flex-row gap-6 align-center items-end w-full">
                    <div className="flex flex-col  justify-center align-center">
                      <div className="bg-secondary px-2 py-3 border-b border-white rounded-t">
                        <p className="text-white font-pop text-xs text-center">
                          Gross Value In
                        </p>
                      </div>
                      <div className="bg-secondary px-2 py-3 border-b border-white">
                        <p className="text-white font-pop text-xs text-center">
                          Gross Value Add
                        </p>
                      </div>
                      <div className="bg-secondary px-2 py-3 border-b border-white">
                        <p className="text-white font-pop text-xs text-center">
                          Gross Value In/Add
                        </p>
                      </div>
                      <div className="bg-secondary px-2 py-3 border-b border-white">
                        <p className="text-white font-pop text-xs text-center">
                          Comp Value
                        </p>
                      </div>
                      <div className="bg-secondary px-2 py-3 border-b border-white rounded-b">
                        <p className="text-white font-pop text-xs text-center">
                          Gross Sales Man Stand
                        </p>
                      </div>
                    </div>

                    {responseData && (
                      <Table
                        data={dataArray}
                        tour_id={tour_id}
                        show_id={show_id}
                      />
                    )}
                    {responseData && (
                      <Table
                        data={totalArray}
                        tour_id={tour_id}
                        show_id={show_id}
                      />
                    )}
                  </div>
                  <div className="flex flex-row items-center justify-center gap-6 mx-12 flex-wrap">
                    {totalStands &&
                      totalStands?.map((item, index) => (
                        <div>
                          <p className="font-pop text-md my-2">
                            {(index == 0)? 'Main' : ''} Stand #{index + 1}
                          </p>
                          <div className="flex flex-col">
                            <div className="flex flex-row">
                              <div
                                className={`px-6 py-2 rounded-l-md border bg-primary border-primary w-36`}
                              >
                                <p className="text-white font-pop text-[11px] text-center">
                                  Units
                                </p>
                              </div>
                              <div
                                className={`px-6 py-2 rounded-r-md border border-secondary w-36`}
                              >
                                <p className="text-secondary font-pop text-[11px] text-center">
                                  $ {item?.total_units}
                                </p>
                              </div>
                            </div>
                            <div className="flex flex-row">
                              <div
                                className={`px-6 py-2 rounded-l-md border bg-primary border-primary w-36`}
                              >
                                <p className="text-white font-pop text-[11px] text-center">
                                  Top Gross Sales
                                </p>
                              </div>
                              <div
                                className={`px-6 py-2 rounded-r-md border border-secondary w-36`}
                              >
                                <p className="text-secondary font-pop text-[11px] text-center">
                                  $ {item?.gross_total}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="flex flex-row gap-6 align-center my-8">
                  <div className="bg-secondary px-8 py-3 border-b border-white rounded-md">
                    <p className="text-white font-pop text-xs text-center">
                      Gross Value In
                    </p>
                  </div>
                  <div className="flex flex-row">
                    <div
                      className={`py-3 px-6 rounded-l-md border border-secondary`}
                    >
                      <p className="text-secondary font-pop text-[11px] text-center">
                        $ {totalInArray && totalInArray[0]}
                      </p>
                    </div>
                    <div className={`py-3 px-6 border border-secondary`}>
                      <p className="text-secondary font-pop text-[11px] text-center">
                        $ {totalInArray && totalInArray[1]}
                      </p>
                    </div>
                    <div className={`py-3 px-6 border border-secondary`}>
                      <p className="text-secondary font-pop text-[11px] text-center">
                        $ {totalInArray && totalInArray[2]}
                      </p>
                    </div>
                  </div>
                  <div className="bg-secondary px-8 py-3 border-b border-white rounded-md">
                    <p className="text-white font-pop text-xs text-center">
                      $ {standsTotalGross && standsTotalGross}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <ShowsSettlements showItemObj={showItemObj} />
          </>
        )}
      </>
    </LoadingOverlay>
  );
};

export const TotalTable = (props) => {
  const { responseData, flag } = props;
  // getCountsList(tour_id, show_id, stand_type ? stand_type : 1);
  const Incounts = responseData?.in_stock[0]?.data;
  const totalAdds = responseData?.in_stock[1]?.data;
  console.log(Incounts, "comIn");
  console.log(totalAdds, "comAdd");
  const combined = Incounts?.map(
    (item, index) => Number(item) + Number(totalAdds[index])
  );
  const totalIn = { title: "Total IN", data: combined };
  const price = responseData?.trailer[1];
  console.log(price, "combineprice");
  const gross = Incounts.map(
    (item, index) => Number(totalIn?.data[index]) * Number(price?.data[index])
  );
  const totalGross = { title: "In/Adds Gross", data: gross };
  const totalData = [totalIn, totalGross];
  console.log(totalData, "combine");
  return (
    <div className="flex flex-row">
      {totalData?.map((item, index) => (
        <div
          className={`flex flex-col h-fit ${
            index % 3 === 0 ? "border" : "border-r border-b border-t"
          }   rounded-lg`}
          key={index}
        >
          <div className="bg-secondary py-2 rounded-t-md px-3">
            <p className="text-white font-pop text-[11px] text-center">
              {item?.title}
            </p>
          </div>
          {item?.data.map((item1, index1) => (
            <div
              className={`px-6 py-2 ${index1 === item.data.length - 1 ? "rounded-b" : "border-b"
                } border-secondary`}
            >
              {item?.title == "In/Adds Gross" ? (
                <p className="text-secondary font-pop text-[11px] text-center">
                  $ {Number.isInteger(item1) ? item1 : item1.toFixed(2)}
                </p>
              ) : (
                <p className="text-secondary font-pop text-[11px] text-center">
                  {Number.isInteger(item1) ? item1 : item1.toFixed(2)}
                </p>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export const SoldTable = (props) => {
  const { responseData, setData, tour_id, show_id, stand_type } = props;
  // getCountsList(tour_id, show_id, stand_type ? stand_type : 1);
  const Incounts = responseData?.in_stock[0]?.data;
  const totalAdds = responseData?.in_stock[1]?.data;

  const combined = Incounts?.map(
    (item, index) => Number(item) + Number(totalAdds[index])
  );
  const totalIn = { title: "Total IN", data: combined };
  const compsAndOuts = Incounts?.map(
    (item, index) =>
      Number(combined[index]) -
      (Number(responseData?.out_stock[0].data[index]) +
        Number(responseData?.out_stock[1].data[index]))
  );
  const sold = { title: "Sold", data: compsAndOuts };
  const price = responseData?.trailer[1];
  const gross = Incounts?.map((item, index) =>
    Number(compsAndOuts[index] * Number(price?.data[index]).toFixed(2))
  );
  const soldGross = [sold, { title: "Sold Gross", data: gross }];
  return (
    <div className="flex flex-row">
      {soldGross?.map((item, index) => (
        <div
          className={`flex flex-col h-fit ${
            index % 3 === 0 ? "border" : "border-r border-b border-t"
          }   rounded-lg`}
          key={index}
        >
          <div className="bg-secondary py-2 rounded-t-md px-3">
            <p className="text-white font-pop text-[11px] text-center">
              {item?.title}
            </p>
          </div>
          {item?.data.map((item1, index1) => (
            <div
              className={`px-6 py-2 ${index1 === item.data.length - 1 ? "rounded-b" : "border-b"
                } border-secondary`}
            >
              {item?.title == "Sold Gross" ? (
                <p className="text-secondary font-pop text-[11px] text-center">
                  $ {Number.isInteger(item1) ? item1 : Number(item1).toFixed(2)}
                </p>
              ) : (
                <p className="text-secondary font-pop text-[11px] text-center">
                  {Number.isInteger(item1) ? item1 : Number(item1).toFixed(2)}
                </p>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export const Table = (props) => {
  const { data, totalIn, price, tour_id, show_id } = props;
  // console.log(totalIn,'totalinO')
  // setData(data)
  return (
    <div className="flex flex-row">
      {data?.map((item, index) => (
        <div
          className={`flex flex-col h-fit ${
            index % 3 === 0 ? "border" : "border-r border-b border-t"
          }   rounded-lg`}
          key={index}
        >
          <div className="bg-secondary py-2 rounded-t-md px-3">
            <p className="text-white font-pop text-[11px] text-center">
              {item?.title}
            </p>
          </div>
          {item?.title == "Total IN" || item?.title == "Sold"
            ? item?.data?.map((item1, index1) => (
              <div
                className={`px-6 py-2 ${index1 === item.data.length - 1 ? "rounded-b" : "border-b"
                  } border-secondary`}
              >
                <p className="text-secondary font-pop text-[11px] text-center">
                  {totalIn[index1]}
                </p>
              </div>
            ))
            : item?.title == "In/Adds Gross" || item?.title == "Sold gross"
              ? item.data?.map((item1, index1) => (
                <div
                  className={`px-6 py-2 ${index1 === item?.data.length - 1 ? "rounded-b" : "border-b"
                    } border-secondary`}
                >
                  <p className="text-secondary font-pop text-[11px] text-center">
                    {totalIn[index1] * price[index1]}
                  </p>
                </div>
              ))
              : item?.data.map((item1, index1) => (
                <div
                  className={`px-6 py-2 ${index1 === item.data.length - 1 ? "rounded-b" : "border-b"
                    } ${(item?.title == "Apparel" ||
                      item?.title == "Others" ||
                      item?.title == "Total" ||
                      item?.title == "Music") &&
                    "h-[40.8px]"
                    } border-secondary`}
                >
                  {item?.title == "Apparel" ||
                    item?.title == "Price" ||
                    item?.title == "Others" ||
                    item?.title == "Total" ||
                    item?.title == "Music" ? (
                    <p className="text-secondary font-pop text-[11px] text-center">
                      ${" "}
                      {Number(item1) % 1 == 0
                        ? Number(item1)
                        : Number(item1).toFixed(2)}
                    </p>
                  ) : (
                    <p className="text-secondary font-pop text-[11px] text-center">
                      {Number(item1)}
                    </p>
                  )}
                </div>
              ))}
        </div>
      ))}
    </div>
  );
};

export const MoreDetailsTable = (props) => {
  const { responseData, tour_id, show_id, stand_type } = props;
  const initialState = {
    title: "New Price",
    data: responseData?.trailer[1]?.data,
  };
  const [newPrice, setNewPrice] = useState([initialState]);
  const handleMoreData = (e, index, dataIndex) => {
    newPrice[0]?.data?.splice(dataIndex, 1, Number(e.target.value).toFixed(2));
    console.log(responseData?.trailer[1]?.data[dataIndex], "moreDataTwo2");
    // setData([...data]);
    setNewPrice([...newPrice]);
    console.log(responseData, "moreDataOne");
    console.log(responseData?.trailer[1]?.data[dataIndex], "moreDataTwo");
  };

  return (
    <div className="flex flex-row">
      {newPrice?.map((item, index) => (
        <div
          className={`flex flex-col h-fit ${
            index % 3 === 0 ? "border" : "border-r border-b border-t"
          }   rounded-lg`}
          key={index}
        >
          <div className="bg-secondary py-2 rounded-t-md px-3">
            <p className="text-white font-pop text-[11px] text-center">
              {item?.title}
            </p>
          </div>
          {item?.data.map((item1, index1) => (
            <input
              type="number"
              className={`w-[100px] px-6 py-2 ${index1 === item.data.length - 1 ? "rounded-b-lg" : "border-b"
                } border-secondary focus:outline-none text-secondary font-pop text-[11px] text-center`}
              value={Number(newPrice && newPrice[0]?.data[index1]).toFixed(2)}
              onChange={(e) => handleMoreData(e, index, index1)}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export const IncountTable = (props) => {
  const {
    responseData,
    data,
    grossCounts,
    setResponseData,
    setStandType,
    setStandTypeData,
    setShowsData,
    setgrossCounts,
    setGrossTotal,
    setStandsTotalGross,
    setTotalStands,
    setStandsTypeList,
    setData,
    dataAdds,
    setDataAdds,
    totalAdds,
    setTotalAdds,
    tour_id,
    show_id,
    stand_type,
  } = props;
  console.log(data, "rrr4");
  const [expandAddProductModal, setAddExpandModal] = useState(false);
  console.log(totalAdds, "rrrind");
  const [modalProps, setModalProps] = useState({
    visible: false,
    title: "",
    key: "",
  });
  const openAddProductModal = () => {
    setModalProps({
      visible: true,
      title: "General Information",
      key: "addProduct",
    });
  };

  const closeModal = () => {
    setModalProps({
      visible: false,
      title: "",
      key: "",
    });
  };
  const handleInputData = (e, index, dataIndex) => {
    console.log(index, "cdaind");
    console.log(dataIndex, "cdataindex");
    console.log(e.target.value, "ddddd");
    dataAdds[index - 2]?.data?.splice(dataIndex, 1, Number(e.target.value));
    console.log(dataAdds, "dddd");
    setDataAdds([...dataAdds, dataAdds[index - 2]]);
    console.log(dataAdds, "dataAddsOne");
    console.log(dataAdds[1]?.data[dataIndex], "dataAddsONE");
    console.log(responseData, "dataAddsOneresp");
    console.log(stand_type, "stand_typeOne");
    createCounts({
      qty_id: responseData?.child_list[dataIndex]?.qty_ids,
      tour_id: tour_id,
      show_id: show_id,
      stand_type: stand_type,
      sale_price:
        responseData &&
        Number(responseData?.child_list[dataIndex]?.sale_price).toFixed(2),
      cost_price:
        responseData && Number(responseData?.child_list[dataIndex]?.cost),
      in_stock:
        responseData && Number(responseData?.in_stock[0].data[dataIndex]),
      adds1: Number(dataAdds[0]?.data[dataIndex]),
      adds2: Number(dataAdds[1]?.data[dataIndex]),
      adds3: Number(dataAdds[2]?.data[dataIndex]),
      comps: Number(
        responseData && responseData?.out_stock[0]?.data[dataIndex]
      ),
      out_stock:
        responseData && Number(responseData?.out_stock[1]?.data[dataIndex]),
    });
    // getCountsList(tour_id, show_id, stand_type).then(({ filteredArray, standTypeData, showsData }) => {
    //   setResponseData(filteredArray);
    //   setStandType(stand_type);
    //   setStandTypeData(standTypeData && standTypeData);
    //   setShowsData(showsData);
    //   // setIsLoading(false);
    // })
    // .catch((error) => {
    //   console.error("Error fetching merch list:", error);
    // });
    getCountsMainStand(tour_id, show_id)
      .then(
        ({
          standsCountsGross,
          standsTotal,
          standsTotalGross,
          totalStands,
          standTypeList,
        }) => {
          setgrossCounts(standsCountsGross);
          setGrossTotal([standsTotal]);
          setStandsTotalGross(standsTotalGross);
          setTotalStands(totalStands);
          setStandsTypeList(standTypeList);
        }
      )
      .catch((error) => {
        console.error("Error fetching merch list:", error);
      });
  };
  const handleInCountData = (e, index, dataIndex) => {
    console.log(index, "cdaind");
    console.log(dataIndex, "cdataindex");
    if (index == 0) {
      data[index].data.splice(
        dataIndex,
        1,
        e?.target?.value.replace(/[^0-9]/g, "")
      );
      setData([...data]);
    } else {
      data[index].data.splice(dataIndex, 1, e.target.value);
      setData([...data]);
      responseData &&
        responseData?.in_stock[2]?.data.splice(
          dataIndex,
          1,
          Number(e?.target?.value)
        );
    }
    console.log(data, "data");
    createCounts({
      qty_id: responseData?.child_list[dataIndex]?.qty_ids,
      tour_id: tour_id,
      show_id: show_id,
      stand_type: stand_type,
      sale_price:
        responseData &&
        Number(responseData?.child_list[dataIndex]?.sale_price).toFixed(2),
      cost_price:
        responseData && Number(responseData?.child_list[dataIndex]?.cost),
      in_stock: data[0]?.data[dataIndex] || 0,
      adds1: Number(responseData?.in_stock[2]?.data[dataIndex]),
      adds2: Number(responseData?.in_stock[3]?.data[dataIndex]),
      adds3: Number(responseData?.in_stock[4]?.data[dataIndex]),
      comps: Number(
        responseData && responseData?.out_stock[0]?.data[dataIndex]
      ),
      out_stock:
        responseData && Number(responseData?.out_stock[1]?.data[dataIndex]),
    });
    // getCountsList()
    console.log(stand_type, "stand_typeTwo");
    getCountsList(tour_id, show_id, stand_type).then(({ filteredArray, standTypeData, showsData }) => {
      setResponseData(filteredArray);
      setStandType(stand_type);
      setStandTypeData(standTypeData && standTypeData);
      setShowsData(showsData);
      // setIsLoading(false);
    })
      .catch((error) => {
        console.error("Error fetching merch list:", error);
      });
    getCountsMainStand(tour_id, show_id)
      .then(
        ({
          standsCountsGross,
          standsTotal,
          standsTotalGross,
          totalStands,
          standTypeList,
        }) => {
          setgrossCounts(standsCountsGross);
          setGrossTotal([standsTotal]);
          setStandsTotalGross(standsTotalGross);
          setTotalStands(totalStands);
          setStandsTypeList(standTypeList);
        }
      )
      .catch((error) => {
        console.error("Error fetching merch list:", error);
      });
    console.log(responseData && responseData, "respo");
    console.log(grossCounts && grossCounts, "standsGross");
  };
  const handleSaveDataAndCloseModal = (event) => {
    event.preventDefault();
    console.log(dataAdds, "dataAdds");

    const dataOne = dataAdds?.map((item) => item.data);
    console.log(dataOne, "d00");
    const transposed = dataOne[0].map((_, colIndex) =>
      dataOne.map((row) => row[colIndex])
    );
    const total = transposed.map((col) => {
      return col.reduce((acc, val) => Number(acc) + Number(val), 0);
    });
    console.log(total, "rrrdddttt");
    setTotalAdds(total);
    console.log(totalAdds, "rrrddd");
    console.log(total, "rrrdddtttO");
    responseData?.in_stock?.splice(1, 1, { title: "adds", data: total });
    closeModal();
    getCountsList(tour_id, show_id, stand_type).then(({ filteredArray, standTypeData, showsData }) => {
      setResponseData(filteredArray);
      setStandType(stand_type);
      setStandTypeData(standTypeData && standTypeData);
      setShowsData(showsData);
      // setIsLoading(false);
    })
      .catch((error) => {
        console.error("Error fetching merch list:", error);
      });
    getCountsMainStand(tour_id, show_id)
      .then(
        ({
          standsCountsGross,
          standsTotal,
          standsTotalGross,
          totalStands,
          standTypeList,
        }) => {
          setgrossCounts(standsCountsGross);
          setGrossTotal([standsTotal]);
          setStandsTotalGross(standsTotalGross);
          setTotalStands(totalStands);
          setStandsTypeList(standTypeList);
        }
      )
      .catch((error) => {
        console.error("Error fetching merch list:", error);
      });
  };

  return (
    <>
      <CustomModal
        handleModalClose={closeModal}
        modalProps={modalProps}
        className={`overflow-y-scroll w-full rounded-2xl shadow-xl ${expandAddProductModal && "h-[600px]"
          }`}
      >
        <div className="p-4">
          <div className=" flex flex-row items-end gap-4">
            <div className="flex flex-row">
              {responseData?.in_stock?.map(
                (item, index) =>
                  index > 1 && (
                    <div
                      className={`flex flex-col h-fit ${
                        index % 3 === 0
                          ? "border"
                          : "border-r border-b border-t"
                        }   rounded-lg`}
                      key={index}
                    >
                      <div className="bg-secondary py-2 rounded-t-md px-3">
                        <p className="text-white font-pop text-[11px] text-center">
                          {item?.title}
                        </p>
                      </div>
                      {item.data.map((item1, index1) => (
                        <input
                          type="text"
                          className={`w-[100px] px-6 py-2 ${index1 === item.data.length - 1
                              ? "rounded-b-lg"
                              : "border-b"
                            } border-secondary focus:outline-none text-secondary font-pop text-[11px] text-center`}
                          value={dataAdds[index - 2]?.data[index1]}
                          onChange={(e) => handleInputData(e, index, index1)}
                        />
                      ))}
                    </div>
                  )
              )}
            </div>
            <div className="flex flex-row gap-4">
              <button
                type="button"
                className="flex justify-center items-center  bg-grey h-8 w-8 rounded-lg shadow-lg"
                onClick={closeModal}
              >
                <img src={AppImages.cancelBlack} className="w-3 h-3" />
              </button>
              <button
                type="submit"
                onClick={handleSaveDataAndCloseModal}
                className="flex justify-center items-center  bg-secondary h-8 w-8 rounded-lg shadow-lg"
              >
                <img src={AppImages.checkIcon} className="w-3 h-3" />
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
      <div className="flex flex-row">
        {responseData &&
          responseData?.in_stock?.map(
            (item, index) =>
              index <= 1 && (
                <div
                  className={`flex flex-col h-fit ${
                    index % 3 === 0 ? "border" : "border-r border-b border-t"
                  }   rounded-lg`}
                  key={index}
                >
                  {item?.title == "adds" ? (
                    <div
                      className="flex flex-row cursor-pointer justify-between items-center bg-secondary py-2 rounded-t-md px-3"
                      onClick={openAddProductModal}
                    >
                      <p className="text-white font-pop text-[11px] text-center">
                        Adds
                      </p>
                      <img
                        src="/chevron_down_white.png"
                        className="w-2 h-2 object-contain"
                      />
                    </div>
                  ) : (
                    <div className="bg-secondary py-2 rounded-t-md px-3">
                      <p className="text-white font-pop text-[11px] text-center">
                        {item?.title}
                      </p>
                    </div>
                  )}
                  {item.data.map((item1, index1) =>
                    index == 1 && !item1 == 0 ? (
                      <p
                        className={`w-[100px] px-6 py-2 ${index1 === item.data.length - 1
                            ? "rounded-b-lg"
                            : "border-b"
                          } border-secondary focus:outline-none text-secondary font-pop text-[11px] text-center`}
                      >
                        {item1}
                      </p>
                    ) : (
                      <input
                        type="text"
                        className={`w-[100px] px-6 py-2 ${index1 === item.data.length - 1
                            ? "rounded-b-lg"
                            : "border-b"
                          } border-secondary focus:outline-none text-secondary font-pop text-[11px] text-center`}
                        value={Number(
                          responseData?.in_stock[index]?.data[index1]
                        )}
                        onChange={(e) => handleInCountData(e, index, index1)}
                      />
                    )
                  )}
                </div>
              )
          )}
      </div>
    </>
  );
};

export const InputTable = (props) => {
  const {
    responseData,
    data,
    setData,
    // setResponseData,
    setgrossCounts,
    setGrossTotal,
    // setStandType,
    // setStandTypeData,
    setStandsTotalGross,
    setTotalStands,
    // setShowsData,
    setStandsTypeList,
    tour_id,
    show_id,
    stand_type,
  } = props;
  const handleInputData = (e, index, dataIndex) => {
    console.log(data[index], "cdaind");
    console.log(dataIndex, "cdataindex");
    data[index].data.splice(
      dataIndex,
      1,
      e.target.value.replace(/[^0-9]/g, "")
    );
    console.log(data[index].data, "newcda");
    console.log(data, "cdaDa");
    setData([...data]);
    console.log(data, "for comps");
    createCounts({
      qty_id: responseData?.child_list[dataIndex]?.qty_ids,
      tour_id: tour_id,
      show_id: show_id,
      stand_type: stand_type,
      sale_price:
        responseData &&
        Number(responseData?.child_list[dataIndex]?.sale_price).toFixed(2),
      cost_price:
        responseData && Number(responseData?.child_list[dataIndex]?.cost),
      in_stock:
        responseData && Number(responseData?.in_stock[0].data[dataIndex]),
      adds1: responseData && Number(responseData?.in_stock[2]?.data[dataIndex]),
      adds2: responseData && Number(responseData?.in_stock[3]?.data[dataIndex]),
      adds3: responseData && Number(responseData?.in_stock[4]?.data[dataIndex]),
      comps: data && Number(data[0].data[dataIndex]),
      out_stock: data && Number(data[1].data[dataIndex]),
    });
    getCountsList(tour_id, show_id, stand_type);
    getCountsMainStand(tour_id, show_id)
      .then(
        ({
          standsCountsGross,
          standsTotal,
          standsTotalGross,
          totalStands,
          standTypeList,
        }) => {
          setgrossCounts(standsCountsGross);
          setGrossTotal([standsTotal]);
          setStandsTotalGross(standsTotalGross);
          setTotalStands(totalStands);
          setStandsTypeList(standTypeList);
        }
      )
      .catch((error) => {
        console.error("Error fetching merch list:", error);
      });
  };

  return (
    <div className="flex flex-row">
      {data.map((item, index) => (
        <div
          className={`flex flex-col h-fit ${
            index % 3 === 0 ? "border" : "border-r border-b border-t"
          }   rounded-lg`}
          key={index}
        >
          <div className="bg-secondary py-2 rounded-t-md px-3">
            <p className="text-white font-pop text-[11px] text-center">
              {item?.title}
            </p>
          </div>
          {item.data.map((item1, index1) => (
            <input
              type="text"
              className={`w-[100px] px-6 py-2 ${index1 === item.data.length - 1 ? "rounded-b-lg" : "border-b"
                } border-secondary focus:outline-none text-secondary font-pop text-[11px] text-center`}
              value={data && data[index]?.data[index1]}
              onChange={(e) => handleInputData(e, index, index1)}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
const secondaryMenu = AppData.toursSecondaryMenu;

export default withHoc(ShowsCounts, { secondaryMenu, page: "shows" });

import React, { useState, useEffect } from 'react';
import withHoc from "../../../Components/Hoc";
import { useNavigate } from 'react-router-dom';
import { AppData } from "../../../Constants/AppData";
import ViewPage from "./ViewPage";
import { getApiCall } from "../../../Utilites/Api";
import { ApiUrl } from "../../../Constants/Api";
import { Field, Formik } from "formik";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';


const ShipmentPage = () => {
  const [showStatusCheckboxes, setShowStatusCheckboxes] = useState(false);
  const [showShipmentOptions, setShowShipmentOptions] = useState(false);
  const [statusCheckboxes, setStatusCheckboxes] = useState({
    pending: true,
    intransit: true,
    received: true,
    completed: true,
    rejected: true,
    resolved: true,
  });
  const [stockTypes, setStockTypes] = useState([]);
  const [checkedItems, setCheckedItems] = useState(new Set());
  const [shipmentOptions, setShipmentOptions] = useState({
    shipmentOption: 'either',
    defaultWarehouse: true,
    firstTrailer: true,
  });
  const [selectedStockTypes, setSelectedStockTypes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getShipTypeLists();
  }, []);

  const toggleStatusCheckboxes = () => {
    setShowStatusCheckboxes(!showStatusCheckboxes);
  };

  const toggleShipmentOptions = () => {
    setShowShipmentOptions(!showShipmentOptions);
  };

  const handleViewClick = () => {
    navigate('/ViewPage');
  };
  
  
  const handleStatusCheckboxChange = (event) => {
    const { name, checked } = event.target;
    alert(name);
    console.log(`Status checkbox ${name} changed to ${checked}`); // Log status change
    setStatusCheckboxes(prevState => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleShipmentOptionChange = (event) => {
    const { name, value, type, checked } = event.target;
    console.log(`Shipment option ${name} changed to ${type === 'radio' ? value : checked}`); // Log shipment option change
    setShipmentOptions(prevState => ({
      ...prevState,
      [name]: type === 'radio' ? value : checked,
    }));
  };

  const handleStockTypeChange = (event) => {
    const itemId = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add item to the set of checked items
      setCheckedItems((prevCheckedItems) => new Set(prevCheckedItems).add(itemId));
    } else {
      // Remove item from the set of checked items
      setCheckedItems((prevCheckedItems) => {
        const newCheckedItems = new Set(prevCheckedItems);
        newCheckedItems.delete(itemId);
        return newCheckedItems;
      });
    }
  };

  const handleClearClick = () => {
    setStatusCheckboxes({
      pending: false,
      intransit: false,
      received: false,
      completed: false,
      rejected: false,
      resolved: false,
    });
    setShipmentOptions({
      shipmentOption: '',
      defaultWarehouse: false,
      firstTrailer: false,
    });
    setSelectedStockTypes([]);
  };

  const getShipTypeLists = async () => {
    try {
      const warehouseResp = await getApiCall(ApiUrl.WarehousesList());
      const trailerResp = await getApiCall(ApiUrl.TrailersList());
      if (warehouseResp.status && trailerResp.status) {
        const combinedList = [
          ...warehouseResp.data.map(item => ({ id: item.id, name: item.warehouse_name, type: "warehouse" })),
          ...trailerResp.data.map(item => ({ id: item.id, name: item.trailer_name, type: "trailer" }))
        ];
        setStockTypes(combinedList);
        console.log(combinedList); // Log combined list to console
      } else {
        console.error("Failed to fetch warehouse or trailer list");
      }
    } catch (error) {
      console.error("Error fetching warehouse or trailer list:", error);
    }
  };

  return (
    <Formik
      initialValues={{
        statusCheckboxes,
        shipmentOptions,
        checkedItems
      }}
      enableReinitialize
    >
      {({ values, setFieldValue }) => (
        <div className="flex mt-5">
          <div className="shippment-card w-full mx-3 rounded-lg p-4 bg-white shadow">
            <table className="w-full mt-3" cellPadding={20}>
              <thead className="shadow-md bg-black table-th table-header">
                <tr className="text-white font-medium rounded-xl shippment-table">
                  <th className="text-center">
                    <p className="text-secondary font-sm">
                      Shipped Date
                    </p>
                  </th>
                  <th className="text-center">
                    <p className="text-secondary font-sm">
                      Delivery Date
                    </p>
                  </th>
                  <th className="text-center">
                    <p className="text-secondary font-sm">
                      Status
                    </p>
                  </th>
                  <th className="text-center">
                    <p className="text-secondary font-sm">
                      From
                    </p>
                  </th>
                  <th className="text-center">
                    <p className="text-secondary font-sm">
                      To
                    </p>
                  </th>
                  <th className="text-center">
                    <p className="text-secondary font-sm">
                      Location
                    </p>
                  </th>
                  <th className="text-center">
                  </th>
                </tr>
              </thead>
              <tbody className="shadow-md">
                <tr className="font-pop text-xs text-secondary text-center font-medium">
                  <td>2024-06-07</td>
                  <td>2024-06-10</td>
                  <td>In transit</td>
                  <td>Hyderabad</td>
                  <td>Delhi</td>
                  <td>On route</td>
                  <td>
                    <button onClick={handleViewClick} className="font-pop text-xs text-secondary text-center font-medium view-button bg-grey rounded-xl">View</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="shippment-card w-80 mx-3 rounded-lg p-4 bg-white shadow">
            <h1 className="text-black font-pop rounded-lg shippment-filter">Filter</h1>
            <div className="mt-3">
            <h6 onClick={toggleStatusCheckboxes} className="cursor-pointer flex justify-between items-center mb-3">
              Status {showStatusCheckboxes ? <FaChevronUp className="mr-5" /> : <FaChevronDown className="mr-5" />}
            </h6>
              {showStatusCheckboxes && (
                <div className="ml-2 mb-15">
                  {Object.keys(statusCheckboxes).map(status => (
                    <div key={status}>
                      <Field
                        type="checkbox"
                        id={status}
                        name={`statusCheckboxes.${status}`}
                        checked={values.statusCheckboxes[status]}
                        onChange={(e) => {
                          setFieldValue(`statusCheckboxes.${status}`, e.target.checked);
                          handleStatusCheckboxChange(e);
                        }}
                        className="mr-2 mb-3"
                      />
                      <label htmlFor={status}>{status.charAt(0).toUpperCase() + status.slice(1)}</label><br />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <hr className="my-3 hrclr" />
            <div className="mt-3">
            <h6 onClick={toggleShipmentOptions} className="cursor-pointer flex justify-between items-center">
                Shipments {showShipmentOptions ? <FaChevronUp className="mr-5" /> : <FaChevronDown className="mr-5" />}
              </h6>
              {showShipmentOptions && (
                <div>
                  <div className="shipment-options-card p-3 bg-gray-100 rounded-lg flex items-center space-x-4">
                    <div className="flex items-center">
                      <Field
                        type="radio"
                        id="from"
                        name="shipmentOptions.shipmentOption"
                        value="from"
                        checked={values.shipmentOptions.shipmentOption === 'from'}
                        onChange={(e) => {
                          setFieldValue("shipmentOptions.shipmentOption", e.target.value);
                          handleShipmentOptionChange(e);
                        }}
                        className="mr-1"
                      />
                      <label htmlFor="from">From</label>
                    </div>
                    <div className="flex items-center">
                      <Field
                        type="radio"
                        id="to"
                        name="shipmentOptions.shipmentOption"
                        value="to"
                        checked={values.shipmentOptions.shipmentOption === 'to'}
                        onChange={(e) => {
                          setFieldValue("shipmentOptions.shipmentOption", e.target.value);
                          handleShipmentOptionChange(e);
                        }}
                        className="mr-1"
                      />
                      <label htmlFor="to">To</label>
                    </div>
                    <div className="flex items-center">
                      <Field
                        type="radio"
                        id="either"
                        name="shipmentOptions.shipmentOption"
                        value="either"
                        checked={values.shipmentOptions.shipmentOption === 'either'}
                        onChange={(e) => {
                          setFieldValue("shipmentOptions.shipmentOption", e.target.value);
                          handleShipmentOptionChange(e);
                        }}
                        className="mr-1"
                      />
                      <label htmlFor="either">Either</label>
                    </div>
                  </div>
                  <hr className="my-3 hrclr" />

                  <div className="mt-3">
                    <h6 className="cursor-pointer mb-3">Warehouses and Trailers</h6>
                    <div className="ml-2 mb-15 scrollable-list">
                      {stockTypes.map((stockType) => (
                        <div key={stockType.id}>
                          <Field
                            type="checkbox"
                            id={stockType.id}
                            name={`checkedItems.${stockType.id}`}
                            value={stockType.id}
                            checked={checkedItems.has(stockType.id.toString())}
                            onChange={(e) => {
                              setFieldValue(`checkedItems.${stockType.id}`, e.target.checked);
                              handleStockTypeChange(e);
                            }}
                            className="mr-2 mb-3"
                          />
                          <label htmlFor={stockType.id}>
                            {stockType.name} ({stockType.type})
                          </label><br />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="mt-3">
                    <button onClick={handleClearClick} className="px-4 py-2 bg-primary text-white rounded-lg ml-20">Clear</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

const secondaryMenu = AppData.productsSecondaryMenu;
export default withHoc(ShipmentPage, { secondaryMenu, page: "ShipmentPage" });

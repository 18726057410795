import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { AppImages } from "../../../../Constants/Images";
import { Trailerschema } from "../../../../Utilites/Validation";
import TextInput from "../../../../Components/TextInput";
import { ApiUrl } from "../../../../Constants/Api";
import { apiCall, getApiCall } from "../../../../Utilites/Api";
import CustomSelectPicker from "../../../../Components/CustomSelect";
import CustomDatepicker from "../../../../Components/CustomDatePicker";
import moment from "moment";

const TrailerForm = (props) => {
  const { handleModalClose, handleAddOrUpdateTrailer, singleTrailerDetails , modalTitle} = props;
  const [selectedTrailer, setSelectedTrailer] = useState("");
  const [TrailersData, setTrailersData] = useState(null);
  const [productsList, setProductList] = useState([]);
  const [toursData, setToursData] = useState(null);
  const [TrailerDetails, setTrailerDetails] = useState(null);
  const [selectedTourId, setSelectedTourId] = useState(singleTrailerDetails ? singleTrailerDetails.tour_id : "");
  const [isLoading, setIsLoading] = useState(false);
  const [showsData, setShowsData] = useState(null);
  const [selectedTourStatus, setSelectedTourStatus] = useState("upcoming");
  const [addToursData, setAddToursData] = useState(null);

  const getFormttedTourTypeData = (tourTypes) => {
    return tourTypes && tourTypes.length > 0
      ? tourTypes.map((item) => ({
        label: item,
        value: item,
      }))
      : [];
  };

  useEffect(() => {
    getTrailers();
  }, []);

  useEffect(() => {
  if (singleTrailerDetails) {
    setSelectedTourId(singleTrailerDetails.tour_id); // Update selectedTourId when singleTrailerDetails changes
  }
}, [singleTrailerDetails]);

  const getTrailers = async () => {
    const resp = await getApiCall(ApiUrl.TrailersList());
    if (resp.status) {
      setIsLoading(false);
      setTrailersData(resp.data);
      setSelectedTrailer(resp.data[0]);
      getMerchList(resp.data[0]);
    } else {
      setIsLoading(false);
    }
  };

  const getMerchList = async (trailerItem) => {
    const resp = await getApiCall(ApiUrl.merchList("trailer", trailerItem?.id));
    if (resp.status) {
      setProductList(resp.data.merch_list);
    }
  };

  const getFormttedCurrencyData = (currencyTypes) => {
    return currencyTypes && currencyTypes.length > 0
      ? currencyTypes.map((item) => ({
        ...item,
        label: item.name + " " + item.symbol,
        value: item.code,
      }))
      : [];
  };

  // const getFormattedToursData = (toursData) => {
  //   return toursData && toursData.list
  //     ? toursData.list.map((item) => ({
  //       ...item,
  //       label: `${item.tour_name} `,
  //       value: `${item.id}`,
  //     }))
  //     : [];
  // };

  useEffect(() => {
    getTours(selectedTourStatus);
  }, [selectedTourStatus]);

  const getTours = async (selectedTourStatus) => {
    const resp = await getApiCall(ApiUrl.toursList(selectedTourStatus));
    if (resp.status && resp.data.list.length>0
    ) {
      setIsLoading(false);
      setToursData(resp.data);
      localStorage.setItem("initalTourId", resp.data.list[0]?.id);
    } else {
      setIsLoading(false);
    }
  };

  // const handleToursDataChange = async (selectedTourId, toursData, values) => {
  //   if (toursData && toursData.list) {
  //     const selectedTour = toursData.list.find((item) => `${item.id}` === selectedTourId);
  //     if (selectedTour) {
  //       setSelectedTourId(selectedTourId);
  //       await getShowsList(selectedTourId, selectedTour.status);
  //       // addOrUpdateNewTrailer({ ...values, tour_id: selectedTourId });
  //     }
  //   }
  // };

  const getShowsList = async (tourId, selectedShowStatus) => {
    const resp = await getApiCall(ApiUrl.showsList(tourId, selectedShowStatus));
    if (resp.status) {
      setIsLoading(false);
      setShowsData(resp.data);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAddToursData();
  }, []);

  useEffect(() => {
    if (singleTrailerDetails) {
      console.log("singleTrailerDetails.tour_id:", singleTrailerDetails.tour_id);
    }
  }, [singleTrailerDetails]);

  const getAddToursData = async () => {
    const resp = await getApiCall(ApiUrl.addToursData);
    if (resp.status) {
      setAddToursData(resp.data);
    }
  };

  // const addOrUpdateNewTrailer = async ({ ...payload }) => {
  //   payload.tour_id = selectedTourId;
  //   setIsLoading(true);
  //   const resp = await apiCall(
  //     TrailerDetails?.id
  //       ? ApiUrl.TrailerEdit(TrailerDetails?.id)
  //       : ApiUrl.addTrailer(),
  //     payload
  //   );
  //   if (resp.status) {
  //     getTrailers();
  //   }
  // };

  return (
    <Formik
      initialValues={{
        trailer_name: singleTrailerDetails?.trailer_name || '',
        contact_person: singleTrailerDetails?.contact_person || '',
        phone_number: singleTrailerDetails?.phone_number || '',
        tour_id: singleTrailerDetails?.tour_id || '',
      }}
      validationSchema={Trailerschema}
      onSubmit={(values, { setSubmitting }) => {
        values.tour_id = selectedTourId;
        setSubmitting(false);
        handleAddOrUpdateTrailer(values);
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        errors,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit} className="m-3">
          <>
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-col">
              {/* {modalTitle === "Edit Trailer" ? (
                 null
                ) : (
                  <p className="font-pop text-xl font-medium text-primary"> Trailer</p>
                )} */}
                <p className="font-pop text-xl font-medium text-primary">{modalTitle}</p>
              </div>
              <div className="flex flex-row gap-4">
                <button
                  type="button"
                  className="flex justify-center items-center bg-grey h-8 w-8 rounded-lg shadow-lg"
                  onClick={handleModalClose}
                >
                  <img src={AppImages.cancelBlack} className="w-3 h-3" />
                </button>
                <button
                  type="submit"
                  className="flex justify-center items-center bg-secondary h-8 w-8 rounded-lg shadow-lg"
                >
                  <img src={AppImages.checkIcon} className="w-3 h-3" />
                </button>
              </div>
            </div>
            {/* {modalTitle === "Edit Trailer" ? (
              null
            ) : (
              <p className="font-pop text-xs text-placeholderColor"></p>
            )} */}
          </>
          <div className="border my-6" />
          <>
            <div className="flex flex-row gap-4 items-center">
              <TextInput
                type="text"
                name="trailer_name"
                placeholder="Trailer Name"
                rightIcon={null}
                className="p-2.5 w-80 border-placeholderColor"
              />
              <TextInput
                type="text"
                name="contact_person"
                placeholder="Contact Person"
                rightIcon={null}
                className="p-2.5 w-96 border-placeholderColor"
              />
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row gap-4 items-center">
                <TextInput
                  type="text"
                  name="phone_number"
                  placeholder="Phone Number"
                  rightIcon={null}
                  className="p-2.5 w-80 border-placeholderColor"
                />
              </div>
              {/* <div className="style">
                <CustomSelectPicker
                  isMulti={false}
                  label={""}
                  placeHolder={"Select Tour"}
                  value={values.tour_id}
                  data={getFormattedToursData(toursData)}
                  name={"tour_id"}
                  className="p-1.5 border-placeholderColor"
                  handleCallback={(selectedTourId) => handleToursDataChange(selectedTourId, toursData, values)}
                />
              </div> */}
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
};

export default TrailerForm;

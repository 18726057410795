import React, { useEffect, useState } from "react";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

const ImageSliderModal = ({ isOpen, onClose, images }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    if (images && images.length > 0) {
      setSelectedImageIndex(0); 
    }
  }, [images]);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index); 
  };

  if (images.length === 0) {
    return null; 
  }

  return (
    <Modal open={isOpen} onClose={onClose} center classNames={{ modal: "custom-modal" }}>
      <div className="w-full h-full flex justify-center items-center">
        <div className="flex">
          {images.length === 1 ? (
            <img
              src={images[0]}
              alt="Slide 1"
              className="object-contain w-full h-[200px] cursor-pointer p-3"
            />
          ) : (
            <>
              {images[selectedImageIndex] && (
                <img
                  src={images[selectedImageIndex]}
                  alt={`Slide ${selectedImageIndex}`}
                  className="object-contain max-h-[600px] max-w-[50%] w-auto mr-2 p-3"
                  // onClick={() => handleImageClick((selectedImageIndex - 1 + images.length) % images.length)} 
                />
              )}
              {images[(selectedImageIndex + 1) % images.length] && (
                <img
                  src={images[(selectedImageIndex + 1) % images.length]}
                  alt={`Slide ${(selectedImageIndex + 1) % images.length}`}
                  className="object-contain max-h-[600px] max-w-[50%] w-auto ml-2 p-3"
                  // onClick={() => handleImageClick((selectedImageIndex + 1) % images.length)} 
                />
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ImageSliderModal;

import React, { useState, useContext, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import loginBg from '../../../Assets/login_bg.svg';
import TextInput from '../../../Components/TextInput';
import CustomButton from '../../../Components/CustomButton';
import { LoginSchema } from '../../../Utilites/Validation';
import { AuthContext } from '../../../Context/AuthContext';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const loginContext = useContext(AuthContext);
  const navigate = useNavigate();

  const handleShowPassword = () => {
    setShowPassword(false);
  };

  const handleHidePassword = () => {
    setShowPassword(true);
  };

  const doUserLogin = async (payload) => {
    await loginContext.doLogin(payload);
  };

  useEffect(() => {
    if (loginContext.accessToken) {
      navigate('/shows');
    }
  }, [loginContext.accessToken, navigate]);

  return (
    <div className="flex flex-row bg-white">
      <div className="w-1/2 flex items-start hidden-on-mobile">
        <img
          src={loginBg}
          alt="Login background image"
          className="w-full object-contain h-screen"
        />
      </div>
      <div className="flex flex-col justify-center items-center bg-primary w-full md:w-1/2 form-mobile-fullscreen">
        <div className="bg-primary rounded-lg p-10 w-full max-w-md">
        <p className="font-pop text-4xl font-bold text-white text-center my-1">
          Welcome Back!
        </p>
        <p className="font-pop text-sm text-white text-center my-1">
          Please enter your details
        </p>
        <Formik
          initialValues={{ identity: '', password: '' }}
          validationSchema={LoginSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await doUserLogin(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="bg-white rounded-xl my-8 p-16 flex flex-col gap-4 justify-center items-center">
                <div className="relative">
                  <TextInput
                    type="text"
                    name="identity"
                    placeholder="Email or identity"
                    rightIcon={null}
                  />
                </div>
                <div className="relative">
                  <TextInput
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    placeholder="Password"
                    rightIcon={
                      showPassword ? (
                        <FaEyeSlash
                          className="text-grey1"
                          onClick={handleShowPassword}
                        />
                      ) : (
                        <FaEye
                          className="text-grey1"
                          onClick={handleHidePassword}
                        />
                      )
                    }
                  />
                </div>
                <CustomButton
                  className="bg-primary w-5/12"
                  title="Sign in"
                  disabled={isSubmitting}
                  type="submit"
                />
              </div>
            </Form>
          )}
        </Formik>
        <p
            className="font-pop  text-white cursor-pointer text-center"
          onClick={() => navigate('/register')}
        >
          Don’t have an account? Sign Up
        </p>
        <p
            className="font-pop text-white cursor-pointer mt-4 text-center"
          onClick={() => navigate('/forgot-password')}
        >
          Forgot Password?
        </p>
      </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useState } from 'react';
import withHoc from "../../../../Components/Hoc";
import { useNavigate } from 'react-router-dom';
import { AppData } from "../../../../Constants/AppData";
import CustomDatepicker from "../../../../Components/CustomDatePicker";
import { Formik, Form, Field } from 'formik';
import TextInput from '../../../../Components/TextInput';
import CustomSelectPicker from "../../../../Components/CustomSelect";
import { toast } from "react-toastify";
import { AppImages } from "../../../../Constants/Images";

const EditPage = () => {
    const navigate = useNavigate();

    const handleViewClick = () => {
      navigate('/ViewPage');
      toast.success("Shipment Successfully Updated")
    };

    const initialValues = {
        shippedDate: '',
        deliveryDate: '',
        email: '',
        contact: '',
        phone: '',
        shippingNotes: ''
    };
    
    const   TourTypes = [
        { id: 1, value: "one", label: "Tour 1" }, 
        { id: 2, value: "two", label: "Tour 2" }, 
        { id: 3, value: "three", label: "Tour 3" },
      ];
    

    return (
        <div className="flex justify-center mt-5">
            <div className="view-card mx-3 rounded-lg p-4 bg-white shadow">
                <div className="flex flex-row mt-10 ml-20">
                    <h1 className="text-xl font-pop mr-20 text-primary">Shipments</h1>
                    <h2 className="mr-20">Status: <span className="font-pop">Completed</span></h2>
                    <p className="mr-10">From: Default Warehouse</p>
                    <p className="mr-10">To: First Trailer</p>
                    <div className="flex justify-center ml-20 w-7 h-7  border border-secondary rounded-md cursor-pointer hoverable-button mr-5">
                      <img src={AppImages.pdf} className="w-3 h-3" />
                    </div>
                </div>
                <hr className="mt-4 hrclr"/>
                <Formik
                    initialValues={initialValues}
                    
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form>
                        <div className="flex flex-row">
                            <div className="p-5 ml-10" style={{ width: "50%" }}>
                                <div className="mb-0 flex flex-row" >
                                    <label htmlFor="shippedDate" className="text-secondary ml-10 mr-7 mb-0">Shipped <br/> Date</label>
                                    <div>
                                    <CustomDatepicker
                                        selected={initialValues.shippedDate}
                                        onChange={(date) => setFieldValue('shippedDate', date)}
                                        name="shippedDate"
                                    />
                                    </div>
                                </div>
                                <div className=" flex flex-row">
                                    <label htmlFor="From" className="text-secondary ml-10 mr-10 mt-2">From</label>
                                    <div className="flex flex-col">
                                    <TextInput
                                        type="text"
                                        name="From"
                                        placeholder="Location"
                                        className="px-2.5 w-60 border-placeholderColor ml-2"
                                    />
                                    <TextInput
                                        type="text"
                                        name="Address"
                                        placeholder="Address"
                                        className="px-2.5 w-60 border-placeholderColor ml-2"
                                    />
                                    
                                    <div className="flex flex-row">
                                    <TextInput
                                        type="text"
                                        name="City"
                                        placeholder="City"
                                        className="px-2.5 w-20 border-placeholderColor ml-5"
                                    />
                                    <TextInput
                                        type="text"
                                        name="State"
                                        placeholder="State"
                                        className="px-2.5 w-20 border-placeholderColor ml-2"
                                    />
                                    
                                    <TextInput
                                        type="text"
                                        name="ZIP"
                                        placeholder="ZIP"
                                        className="px-2.5 w-20 border-placeholderColor ml-2"
                                    />
                                    </div>
                                    </div>
                                </div>
                                <div className="mb-0 flex flex-row">
                                    <label htmlFor="contact" className="text-secondary ml-10 mr-6">Contact</label>
                                    <TextInput
                                        type="text"
                                        name="contact"
                                        placeholder="Contact"
                                        className="px-2.5 w-60 border-placeholderColor ml-2"
                                    />
                                </div>
                                <div className=" flex flex-row">
                                    <label htmlFor="phone" className="text-secondary ml-10 mr-8">Phone</label>
                                    <TextInput
                                        type="text"
                                        name="phone"
                                        placeholder="Contact Number"
                                        className="px-2.5 w-60 border-placeholderColor ml-2"

                                    />
                                </div>
                                <div className="mb-0 flex flex-row">
                                    <label htmlFor="email" className="text-secondary ml-10 mr-10">Email</label>
                                    <TextInput
                                        type="text"
                                        name="email"
                                        placeholder="Contact Email"
                                        className="px-2.5 w-60 border-placeholderColor ml-2"
                                    />
                                </div>
                                <div className="flex flex-row mr-5">
                                    <div className="mb-0 flex flex-row">
                                        <label htmlFor="shipper" className="text-secondary ml-10 mr-6">Shipper</label>
                                        <TextInput
                                            type="text"
                                            name="shipper"
                                            placeholder="Shipper"
                                            className="px-2.5 w-30 border-placeholderColor ml-2"
                                        />
                                    </div>
                                    <div className="mb-0 flex flex-row">
                                        <label htmlFor="actNo" className="text-secondary ml-2">Act No</label>
                                        <TextInput
                                            type="text"
                                            name="actNo"
                                            placeholder="Act No"
                                            className="px-2.5 w-30 border-placeholderColor"
                                        />
                                    </div>
                                </div>
                                <div className="mb-0 flex flex-row">
                                    <label htmlFor="trackingNumber" className="text-secondary ml-10 mr-6">Tracking<br/> Number</label>
                                    <TextInput
                                        type="text"
                                        name="trackingNumber"
                                        placeholder="Tracking Number"
                                        className="px-2.5 w-60 border-placeholderColor ml-2"
                                    />
                                </div>
                            </div>
                            <div className="p-5" style={{ width: "50%" }}>
                                <div className="mb-0 flex flex-row">
                                    <label htmlFor="deliveryDate" className="text-secondary mr-5 mb-2 ml-2">Delivery <br/> Date</label>
                                    <CustomDatepicker
                                        selected={initialValues.deliveryDate}
                                        onChange={(date) => setFieldValue('deliveryDate', date)}
                                        name="deliveryDate"
                                        className="form-input"
                                    />
                                </div>
                                <div className=" flex flex-row">
                                    <label htmlFor="From" className="text-secondary ml-10 mr-8">To</label>
                                    <div className="flex flex-col">
                                    <TextInput
                                        type="text"
                                        name="From"
                                        placeholder="Location"
                                        className="px-2.5 w-60 border-placeholderColor ml-2"
                                    />
                                    <TextInput
                                        type="text"
                                        name="Address"
                                        placeholder="Address"
                                        className="px-2.5 w-60 border-placeholderColor ml-2"
                                    />
                                    
                                    <div className="flex flex-row">
                                    <TextInput
                                        type="text"
                                        name="City"
                                        placeholder="City"
                                        className="px-2.5 w-20 border-placeholderColor ml-2"
                                    />
                                    <TextInput
                                        type="text"
                                        name="State"
                                        placeholder="State"
                                        className="px-2.5 w-20 border-placeholderColor ml-2"
                                    />
                                    
                                    <TextInput
                                        type="text"
                                        name="ZIP"
                                        placeholder="ZIP"
                                        className="px-2.5 w-20 border-placeholderColor ml-2"
                                    />
                                    </div>
                                    </div>
                                </div>
                                <div className="mb-0 flex flex-row">
                                    <label htmlFor="contactDelivery" className="text-secondary mr-8 ">Contact</label>
                                    <TextInput
                                        type="text"
                                        name="contactDelivery"
                                        placeholder="Contact"
                                        className="px-2.5 w-60 border-placeholderColor ml-2"
                                    />
                                </div>
                                <div className="mb-0 flex flex-row">
                                    <label htmlFor="phoneDelivery" className="text-secondary mr-10">Phone</label>
                                    <TextInput
                                        type="text"
                                        name="phoneDelivery"
                                        placeholder="Contact Number"
                                        className="px-2.5 w-60 border-placeholderColor ml-2"
                                    />
                                </div>
                                <div className="mb-0 flex flex-row">
                                    <label htmlFor="emailDelivery" className="text-secondary mr-12">Email</label>
                                    <TextInput
                                        type="text"
                                        name="emailDelivery"
                                        placeholder="Contact Email"
                                        className="px-2.5 w-60 border-placeholderColor ml-10"
                                    />
                                </div>
                                <div className="mb-0 flex flex-row">
                                    <label htmlFor="shippingCost" className="text-secondary mr-8">Shipping <br/> Cost</label>
                                    <TextInput
                                        type="text"
                                        name="shippingCost"
                                        placeholder="Shipping Cost"
                                        className="px-2.5 w-60 border-placeholderColor "
                                    />
                                </div>
                                <div className="mb-0 flex flex-row">
                                    <label htmlFor="applyCostTo" className="text-secondary mr-10">Apply <br/>Cost To</label>
                                    <div className="h-16">
                                        <CustomSelectPicker
                                            isMulti={false}
                                            placeHolder={"Tour Type"}
                                            data={TourTypes}
                                            name="tourType"
                                            className="px-2.5 w-60 border-placeholderColor"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-5 ml-10 ">
                                <div className="mb-0 flex flex-row">
                                    <label htmlFor="shippingNotes" className="text-secondary ml-10 mr-5">Shipping <br/> Notes:</label>
                                    <textarea
                                        name="shippingNotes"
                                        placeholder="Enter any shipping notes here"
                                        className="width-100 p-2 cursor-pointer hoverable-button no-border-textarea"
                                        rows="4"
                                    />
                                </div>
                            </div>
                        <hr className="mt-4 hrclr" />
                    </Form>
                    
                    )}
                </Formik>
                <div className="flex flex-row">
                    <div className="p-5 ml-10" style={{ width: "40%" }}>
                        <h1 className="font-pop">Blue T-shirt</h1>
                        <div className="flex flex-row">
                            <div className='flex flex-col justify-center'>
                             <img src="https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg" alt="Preview" className="w-24 h-24 z-10 mt-10 mb-5 rounded mr-5"/>
                            </div>
                            <ul>
                                <li className="pt-5 pb-2">Type</li>
                                <li className="pb-2">Colour</li>
                                <li className="pb-2">Sex</li>
                                <li className="pb-2 pr-2">Reorder</li>
                                <li className="pb-2">Lead</li>
                                <li className="pb-2">For</li>
                            </ul>
                            <ul>
                                <li className="pt-5 pb-2 text-secondary">1/4 Zip Pullover</li>
                                <li className="pb-2 text-secondary">Baby Blue</li>
                                <li className="pb-2 text-secondary">Male</li>
                                <li className="pb-2 text-secondary">Priority</li>
                                <li className="pb-5 mb-2 text-secondary"></li>
                                <li className="text-secondary">H</li>
                            </ul>
                        </div>
                    </div>
                    <div className="p-5 ml-10" style={{ width: "60%" }}>
                        <div className="flex flex-col">
                            <ul className="text-secondary font-pop flex flex-row">
                                <li className="pr-10 mb-5">SKU</li>
                                <li className="pr-10 mb-5">Size</li>
                                <li className="pr-10 mb-5">Shipped</li>
                                <li className="pr-10 mb-5">Received</li>
                                <li className="pr-9 mb-5">Short/Over</li>
                                <li className="pr-10 mb-5">Short/Over $</li>
                            </ul>
                            <ul className="text-secondary font-pop mr-10 flex flex-row">
                                <li className="pr-11 mb-5 mr-3">123</li>
                                <li className="pr-20 mb-5">XS</li>
                                <li className="pr-20 mb-5">10</li>
                                <li className="pr-20 mb-5 mr-5">10</li>
                                <li className="pr-20 mb-5 mr-10">0</li>
                                <li className="pr-20 mb-5">0.0</li>
                            </ul>
                            <ul className="text-secondary font-pop flex flex-row">
                                <li className="pr-11 mr-3"></li>
                                <li className="pr-20">Total</li>
                                <li className="pr-20">10</li>
                                <li className="pr-20 mr-7">0</li>
                                <li className="pr-20 mr-10">0</li>
                                <li className="pr-10">0</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="hrclr"/>
                <ul className="text-secondary font-pop flex flex-row" style={{ justifyContent: "flex-end" }}>
                    <li className="pr-11 mr-3"></li>
                    <li className="pr-20">Total</li>
                    <li className="pr-20">10</li>
                    <li className="pr-20 mr-7">0</li>
                    <li className="pr-20 mr-8">0</li>
                    <li className="pr-10 mr-20">0</li>
                </ul>
                <div className="flex m-5 justify-center mr-20" >
                    <button onClick={handleViewClick} className="font-pop text-xs text-white text-center ml-20 font-medium edit-button bg-primary rounded-xl">
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

const secondaryMenu = AppData.productsSecondaryMenu;
export default withHoc(EditPage, { secondaryMenu, page: "EditPage" });

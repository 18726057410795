import React from 'react';
import withHoc from "../../../../Components/Hoc";
import { AppData } from "../../../../Constants/AppData";
import CustomSelectPicker from "../../../../Components/CustomSelect";
import { Formik, Form, Field } from "formik";
import TextInput from '../../../../Components/TextInput';
import { AppImages } from "../../../../Constants/Images";
import ProductShipmentForm from "../../Products/ProductShipmentForm"

const EditOrder = () => {
    const StatusTypes = [
        { id: 1, value: "one", label: "Pending" }, 
        { id: 2, value: "two", label: "To Be Approved" }, 
        { id: 3, value: "three", label: "Approved" },
        { id: 4, value: "four", label: "Placed" },
        { id: 5, value: "five", label: "Completed" },
    ];

    return (
        <Formik
            initialValues={{
                status_type: '',
                status_changed_by: ''
            }}
        >
            {({ setFieldValue }) => (
                <Form>
                    <div className="flex justify-center mt-5">
                        <div className="view-card mx-3 rounded-lg p-4 bg-white shadow">
                            <div className="flex flex-row">
                                <div style={{ width: "50%"}}>
                                    <h1 className="text-xl font-pop ml-10 mt-5 text-primary mb-6">Orders</h1>
                                    <p className="ml-10">Created On: 19/06/2024 by Lucky </p>
                                    <div className="flex flex-col ml-10">
                                        <div className="flex flex-row items-center mt-4">
                                            <p>Order Status:</p>
                                            <CustomSelectPicker
                                                isMulti={false}
                                                placeHolder={"Order Type"}
                                                data={StatusTypes}
                                                name={"status_type"}
                                                className="px-2.5 w-60 border-placeholderColor ml-2"
                                                onChange={(value) => setFieldValue('status_type', value)}
                                            />
                                        </div>
                                        <div className="flex flex-row mt-4">
                                            <p className="mt-1">Status Changed By:</p>
                                            <TextInput 
                                                name="status_changed_by"
                                                placeholder="Enter name"
                                                className="px-2.5 w-60 border-placeholderColor ml-2 mt-5"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "50%"}}>
                                    <div style={{display:"flex", justifyContent:"flex-end"}} className="mt-5">
                                        <button type="button" className="font-pop text-xs text-secondary text-center font-medium save-button bg-grey rounded-xl mr-2 mt-5">
                                            Ship All
                                        </button>
                                        <button type="submit" className="font-pop text-xs text-secondary text-center font-medium save-button bg-grey rounded-xl mr-10 mt-5">
                                            Save Order
                                        </button>
                                       
                                    </div>
                                    <div className=" flex flex-row mt-8">
                                    <label htmlFor="Notes" className="text-secondary mr-10  ">Additional Notes:</label>
                                    <textarea
                                        name="shippingNotes"
                                        placeholder="Enter any shipping notes here"
                                        className="w-80 p-2 cursor-pointer hoverable-button no-border-textarea"
                                        rows="4"
                                    />
                                </div>
                                </div>
                            </div>
                            <hr className="mt-4 hrclr" />
                            <div className="flex flex-row">
                                <div className="p-5 ml-10" style={{ width: "40%" }}>
                                    <h1 className="font-pop">Blue T-shirt</h1>
                                    <div className="flex flex-row">
                                    <div className="flex flex-col items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg" alt="Preview" className="w-24 h-24 z-10 mt-10 mb-5 rounded mr-5"/>
                                    <div className="flex flex-row">
                                        <div className="flex justify-center  w-7 h-7  border border-secondary rounded-md cursor-pointer hoverable-button mr-5">
                                            <img src={AppImages.locker} className="w-3 h-3" />
                                        </div>
                                        <div className="flex justify-center items-center border border-secondary w-7 h-7 mr-5 rounded-md hoverable-button">
                                            <img
                                                src={AppImages.deleteIcon}
                                                className="w-5 h-5 object-contain mt-1"
                                            />
                                        </div>
                                    </div>
                                    </div>
                                        <ul>
                                            <li className="pt-5 pb-2">Type</li>
                                            <li className="pb-2">Colour</li>
                                            <li className="pb-2">Sex</li>
                                            <li className="pb-2 pr-2">Reorder</li>
                                            <li className="pb-2">Lead</li>
                                            <li className="pb-2">For</li>
                                        </ul>
                                        <ul>
                                            <li className="pt-5 pb-2 text-secondary">1/4 Zip Pullover</li>
                                            <li className="pb-2 text-secondary">Baby Blue</li>
                                            <li className="pb-2 text-secondary">Male</li>
                                            <li className="pb-2 text-secondary">Priority</li>
                                            <li className="pb-5 mb-2 text-secondary"></li>
                                            <li className="text-secondary">H</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="p-5 ml-10" style={{ width: "60%" }}>
                                    <ul className="font-pop text-secondary text-sm flex flex-row text-center">
                                        <li className="ml-10 mr-10">Units <br/>Ordered</li>
                                        <li className=" mr-10">Cost</li>
                                        <li className=" mr-10">Avail To <br/>Ship</li>
                                    </ul>
                                    <div className="flex flex-row">
                                        <input className="square-input cursor-pointer hoverable-button mt-4" />
                                        <input className="square-input2 cursor-pointer hoverable-button mt-4" />
                                        <p className="mt-8 ml-9">10</p>
                                    </div>
                                    <ul  className="font-pop text-secondary text-sm flex flex-row text-center">
                                        <li className="mt-5 mr-5">Total</li>
                                        <li className='mt-5'>10</li>
                                        <li className="ml-20 mt-5">0.00</li>
                                    </ul>
                                </div>
                            </div> 
                            <hr className="mt-4 hrclr" />
                            <div className="flex  flex-col text">
                            <ul  className="font-pop text-secondary text-sm flex flex-row text-center font-pop mb-10">
                                        <li className="mt-5 mr-5">Total</li>
                                        <li className='mt-5'>10</li>
                                        <li className="ml-20 mt-5">0.00</li>
                           </ul>
                           <div className="flex flex-row  ">
                               <button className="font-pop  text-secondary text-center font-medium save-button bg-grey rounded-xs mr-4">Cancel Order</button>
                               <button className="font-pop  text-secondary text-center font-medium save-button bg-grey rounded-xs">Save Order</button>
                           </div>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

const secondaryMenu = AppData.productsSecondaryMenu;
export default withHoc(EditOrder, { secondaryMenu, page: "EditOrder" });

import React, { useState, useEffect } from "react";

const SearchBar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleIconClick = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex relative">
      {isMobile ? (
        <>
          <div
            className="flex justify-center items-center bg-white w-10 h-10  rounded-full cursor-pointer hoverable-button "
            style={{ border: '2px solid #0a8543', right: '0px' }}
            onClick={handleIconClick}
          >
            <img src="/search.png" className="w-4 h-4 object-contain" alt="search icon" />
          </div>
          <input
            type="text"
            className={`search-input transition-all duration-300 header-icons  ${
              isExpanded ? "w-40" : "w-0"
            } overflow-hidden absolute top-12 right-0`}
            style={{
              opacity: isExpanded ? 1 : 0,
              paddingRight: isExpanded ? "12px" : "0px",
            }}
            placeholder="Search..."
          />
        </>
      ) : (
        <>
          <input
            type="text"
            className={`search-input bg-secondary rounded-full h-9 placeholder-white text-white px-6 text-sm font-pop transition-all duration-300 header-icons ${
              isExpanded ? "w-40" : "w-0"
            } overflow-hidden`}
            style={{
              opacity: isExpanded ? 1 : 0,
              paddingRight: isExpanded ? "12px" : "0px",
              border: '2px solid white',
              height: '40px',
              
            }}
            placeholder="Search..."
          />
          <div
            className="flex justify-center items-center bg-white w-10 h-10 rounded-full cursor-pointer hoverable-button header-icons "
            style={{}}
            onClick={handleIconClick}
          >
            <img src="/search.png" className="w-4 h-4 object-contain" alt="search icon" />
          </div>
        </>
      )}
    </div>
  );
};

export default SearchBar;

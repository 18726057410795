import React from 'react';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import loginBg from '../../../Assets/login_bg.svg';
import TextInput from '../../../Components/TextInput';
import CustomButton from '../../../Components/CustomButton';
import { apiCall } from '../../../Utilites/Api';
import { ApiUrl } from '../../../Constants/Api';
import { ForgotPasswordSchema } from "../../../Utilites/Validation";

const ForgotPassword = () => {
    const navigate = useNavigate();

    const doUserForgotPassword = async (payload) => {
        const resp = await apiCall(ApiUrl.ForgotPassword(), payload);
        if (resp.status) {
            toast.success(resp.message);
            navigate("/login");
        } else {
            toast.error(resp.message);
        }
    };

    return (
        <div className="flex flex-row-col bg-white">
            <div className="w-1/2 flex items-start hidden-on-mobile">
                <img
                    src={loginBg}
                    alt="Login background image"
                    className="w-full object-contain h-screen"
                />
            </div>
            <div className="flex flex-col justify-center items-center bg-primary w-full md:w-1/2 form-mobile-fullscreen">
                <div className="bg-primary rounded-lg p-10 w-full max-w-md">
                    <p className="font-pop text-4xl font-bold text-white text-center my-1">Forgot Password!</p>
                    <p className="font-pop text-sm text-white text-center my-3">Enter your email address to reset your password.</p>
                    <Formik
                        initialValues={{ identity: '' }}
                        validationSchema={ForgotPasswordSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true);
                            doUserForgotPassword(values);
                            setSubmitting(false);
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form className="bg-white rounded-xl my-8 p-16 flex flex-col gap-4 justify-center items-center">
                                <div className="relative">
                                    <TextInput
                                        type="text"
                                        name="identity"
                                        placeholder="Email address"
                                    />
                                </div>
                                <CustomButton
                                    type="submit"
                                    title="Submit"
                                    disabled={isSubmitting}
                                    className="bg-primary w-5/12"
                                />
                            </Form>
                        )}
                    </Formik>
                  
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;

import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { AppImages } from "../../../../Constants/Images";
import CustomSelectPicker from "../../../../Components/CustomSelect";

const ShipmentForm = (props) => {
    const {
        handleModalClose,
        merchDetails,
        handleMerchShipmentSubmit,
        handleGetShipmentTypeLists,
        stockTypes,
        currentFromStockID,
        currentToStockID,
        currentFromStockType,
        currentToStockType,
        selectedWarehouseName,
    } = props;

    const [merchShipSizes, setmerchShipSizes] = useState(merchDetails.child_list);
    const [selectedShipType, setSelectedShipType] = useState("");
    const [showInputFields, setShowInputFields] = useState(false);

    const getFormattedShipmentTypeData = () => {
        return stockTypes.map((item) => ({
            ...item,
            label: item.trailer_name ? `Trailer - ${item.trailer_name}` : `Warehouse - ${item.warehouse_name}`,
            value: item.id,
            type: item.trailer_name ? `trailer` : `warehouse`,

        }));
    };

    const handleStockType = (selectedStock, setFieldValue, fieldName) => {
        const selectedData = stockTypes.find((item) => item.id === selectedStock);
        setFieldValue(fieldName, selectedStock);
        setFieldValue(`${fieldName}_type`, selectedData.trailer_name ? 'trailer' : 'warehouse');
    };

    const handleShipTypeButtonClick = (value) => {
        setSelectedShipType(value);
        setShowInputFields(value !== "");
        if (value !== "") {
            handleGetShipmentTypeLists(value);
        }
    };

    const renderShipmentForm = (values, setFieldValue) => {
        const handleMerchSizeFormChange = (index, event) => {
            const tempMerchSizes = [...merchShipSizes];
            tempMerchSizes[index][event.target.name] = event.target.value;
            setmerchShipSizes(tempMerchSizes);
        };

        return (
            <>
                {merchShipSizes.map((item, index) => (
                    <div key={index} className="flex flex-row items-center justify-between">
                        <div className="my-2">
                            <input
                                type="text"
                                className="border border-placeholderColor p-2.5 w-80 rounded-lg placeholder-placeholderColor font-pop text-xs"
                                disabled={true}
                                value={item.size_name}
                            />
                        </div>
                        <div className="my-2">
                            <input
                                type="number"
                                name="quantity"
                                className="border border-placeholderColor p-2.5 w-80 rounded-lg mr-20 placeholder-placeholderColor font-pop text-xs"
                                placeholder="Quantity"
                                value={item.quantity}
                                onChange={(event) => handleMerchSizeFormChange(index, event)}
                            />
                        </div>
                    </div>
                ))}
            </>
        );
    };

    return (
        <Formik
            initialValues={{
                from: currentFromStockID ?? "",
                to: currentToStockID ?? "",
                inventory_type: selectedShipType ?? "",
                from_type: currentFromStockType ?? "", // Set initial value
                to_type: currentToStockType ?? "", // Set initial value
            }}
            onSubmit={(values, { setSubmitting }) => {
                const payloadParams = {
                    from_stock_id: values.from,
                    to_stock_id: values.to,
                    inventory_type: selectedShipType,
                    inventory_data: merchShipSizes.map((item) => ({
                        merch_id: item.merch_id,
                        merch_child_id: item.id,
                        from_stock_type: values.from_type,
                        from_stock_id: values.from,
                        to_stock_type: values.to_type,
                        to_stock_id: values.to,
                        quantity: item.quantity,
                    })),
                };
                handleMerchShipmentSubmit(payloadParams);
                setSubmitting(false);
            }}
        >
            {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                <Form onSubmit={handleSubmit} className="m-3">
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-col">
                            <p className="font-pop text-xl font-medium text-primary">
                                Move Inventory
                            </p>
                        </div>
                        <div className="flex flex-row gap-4">
                            <button
                                type="button"
                                className="flex justify-center items-center bg-grey h-8 w-8 rounded-lg shadow-lg"
                                onClick={handleModalClose}
                            >
                                <img src={AppImages.cancelBlack} className="w-3 h-3" />
                            </button>
                            <button
                                type="submit"
                                className="flex justify-center items-center bg-secondary h-8 w-8 rounded-lg shadow-lg"
                            >
                                <img src={AppImages.checkIcon} className="w-3 h-3" />
                            </button>
                        </div>
                    </div>
                    <p className="font-pop text-xs text-placeholderColor">Stock</p>
                    <div className="border my-6 items-center " />
                    <div className="flex flex-row items-center gap-2 p-3">
                        <button
                            type="button"
                            className={`px-4 py-2 rounded-lg ${selectedShipType === "ship" ? "bg-secondary" : "bg-grey"}`}
                            onClick={() => handleShipTypeButtonClick("ship")}
                            name="inventory_type"
                            value="ship"
                        >
                            Ship
                        </button>
                        <button
                            type="button"
                            className={`px-4 py-2 rounded-lg ${selectedShipType === "transfer" ? "bg-secondary" : "bg-grey"}`}
                            onClick={() => handleShipTypeButtonClick("transfer")}
                            name="inventory_type"
                            value="transfer"
                        >
                            Transfer
                        </button>
                        <span className="text-danger text-sm font-pop text-xs">
                            {errors.inventory_type && touched.inventory_type && errors.inventory_type}
                        </span>
                    </div>
                    {showInputFields && (
                        <>
                            <div className="flex flex-row items-center gap-2 p-2">
                                <div className="h-16">
                                    <CustomSelectPicker
                                        isMulti={false}
                                        label={""}
                                        placeHolder={"From"}
                                        data={getFormattedShipmentTypeData()}
                                        handleCallback={(selectedStock) => handleStockType(selectedStock, setFieldValue, 'from')}
                                        name={"from"}
                                        className="px-2.5 w-80 border-placeholderColor"
                                    />
                                    <span className="text-danger text-sm font-pop text-xs">
                                        {errors.from && touched.from && errors.from}
                                    </span>
                                </div>
                                <div className="h-16">
                                    <CustomSelectPicker
                                        isMulti={false}
                                        label={""}
                                        placeHolder={"To"}
                                        data={getFormattedShipmentTypeData()}
                                        handleCallback={(selectedStock) => handleStockType(selectedStock, setFieldValue, 'to')}
                                        name={"to"}
                                        className="px-2.5 w-80 border-placeholderColor"
                                    />
                                    <span className="text-danger text-sm font-pop text-xs">
                                        {errors.to && touched.to && errors.to}
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                    {values.to > 0 && renderShipmentForm(values, setFieldValue)}
                </Form>
            )}
        </Formik>
    );
};

export default ShipmentForm;

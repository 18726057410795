import React, { useEffect, useState, useRef } from "react";
import withHoc from "../../../Components/Hoc";
import LoadingOverlay from "react-loading-overlay";
import { BeatLoader } from "react-spinners";
import { AppData } from "../../../Constants/AppData";
import { apiCall, getApiCall } from "../../../Utilites/Api";
import { ApiUrl } from "../../../Constants/Api";
import { AppImages } from "../../../Constants/Images";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ProductItem } from "../Products";
import ModalCustomTrailers from "../../Home/ModalCustomTrailers";
import SearchInput from "../../../Components/SearchInput";
import ScrollButton from "../../../Pages/Home/ScrollButton";

const Trailer = () => {
  const [TrailersData, setTrailersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [TrailerDetails, setTrailerDetails] = useState(null);
  const [TrailerDropdown, setTrailerDropdown] = useState(false);
  const [selectedTrailer, setSelectedTrailer] = useState(null);
  const [productsList, setProductList] = useState([]);
  const [merchDetails, setmerchDetails] = useState(null);
  const [stockTypes, setStockTypes] = useState([]);

  const navigate = useNavigate();
  const [modalProps, setModalProps] = useState({
    visible: false,
    title: "",
    key: "",
  });

  const dropdownRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    getTrailers();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setTrailerDropdown(false);
      }
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, modalRef]);

  const getMerchList = async (trailerItem) => {
    const resp = await getApiCall(ApiUrl.merchList("trailer", trailerItem?.id));
    if (resp.status) {
      setProductList(resp.data.merch_list);
    }
  };

  const getTrailers = async () => {
    const resp = await getApiCall(ApiUrl.TrailersList());
    if (resp.status) {
      setIsLoading(false);
      setTrailersData(resp.data);
      // If selected trailer exists in localStorage, set it, else select the first trailer
      const selectedTrailerFromStorage = JSON.parse(localStorage.getItem("selectedTrailer"));
      if (selectedTrailerFromStorage && resp.data.some(trailer => trailer.id === selectedTrailerFromStorage.id)) {
        setSelectedTrailer(selectedTrailerFromStorage);
      } else {
        setSelectedTrailer(resp.data[0] || null);
      }
      getMerchList(selectedTrailerFromStorage || resp.data[0]);
    } else {
      setIsLoading(false);
    }
  };




  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredTrailers = TrailersData?.filter((trailer) =>
    trailer.trailer_name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const openEditTrailerModal = (selectedTrailer) => {
    setTrailerDetails(selectedTrailer);
    setModalProps({
      visible: true,
      title: "Edit Trailer",
      key: "editTrailer",
    });
    setShowModal(true);
  };
  const openAddTrailerModal = () => {
    setTrailerDetails(null);
    setModalProps({
      visible: true,
      title: "Add Trailer",
      key: "addTrailer",
    });
  };

  const closeModal = () => {
    setShowModal(false);
    setTrailerDetails(null);
    setModalProps({
      visible: false,
      title: "",
      key: "",
    });
  };

  const addOrUpdateNewTrailer = async (payload) => {
    if (isLoading) return; // Prevent multiple API calls

    setIsLoading(true);
    try {
      const resp = await apiCall(
        TrailerDetails?.id
          ? ApiUrl.TrailerEdit(TrailerDetails?.id)
          : ApiUrl.addTrailer(),
        payload
      );

      if (resp.status) {
        closeModal();
        getTrailers();
        if (TrailerDetails?.id !== selectedTrailer?.id) {
          localStorage.setItem("selectedTrailer", JSON.stringify(resp.data));
          setSelectedTrailer(resp.data);
        } else { // Update the warehouse in localStorage if it's edited
          localStorage.setItem("selectedTrailer", JSON.stringify(resp.data));
          setSelectedTrailer(resp.data);
        }
        toast.success(
          `Trailer ${TrailerDetails?.id ? "updated" : "added"} successfully`
        );
      } else {
        toast.error(
          `Trailer not ${TrailerDetails?.id ? "updated" : "added"} . Please try later`
        );
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleTrailerClick = (TrailerItem) => {
    setSelectedTrailer(TrailerItem);
    setTrailerDropdown(!TrailerDropdown);
    getMerchList(TrailerItem);
    setSearchInput("");
    localStorage.setItem("selectedTrailer", JSON.stringify(TrailerItem));
  };

  const handleAssignMerchStock = (item) => {
    setmerchDetails(item);
    setModalProps({
      visible: true,
      title: "Merch Stock",
      key: "merchStock",
    });
    setShowModal(true);
  };

  const handleAssignMerchShipment = (item) => {
    setmerchDetails(item);
    setModalProps({
      visible: true,
      title: "Merch Shipment",
      key: "productsShipment",
    });
  };

  const updateMerchStocks = async (payload, merchSizes) => {
    const updatedPayload = merchSizes.map((item) => {
      return {
        ...item,
        stock_type: payload.stock_type,
        stock_id: payload.stock_id,
        merch_child_id: item.id,
      };
    });
    const resp = await apiCall(ApiUrl.addMerchStock(), updatedPayload);
    if (resp.status) {
      closeModal();
      getMerchList(selectedTrailer);
      toast.success(`Merch details updated successfully`);
    } else {
      toast.error(`Merch details not updated. Please try later`);
    }
  };

  const getShipmentTypeLists = async () => {
    try {
      const warehouseResp = await getApiCall(ApiUrl.WarehousesList());
      const trailerResp = await getApiCall(ApiUrl.TrailersList());
      if (warehouseResp.status && trailerResp.status) {
        const combinedList = [
          ...warehouseResp.data.map(item => ({ ...item, type: "warehouse" })),
          ...trailerResp.data.map(item => ({ ...item, type: "trailer" })),
        ];
        return combinedList;
      } else {
        console.error("Failed to fetch warehouse or trailer list");
        return [];
      }
    } catch (error) {
      console.error("Error fetching warehouse or trailer list:", error);
      return [];
    }
  };
  

  const updateMerchShipment = async (payload) => {
    const { inventory_type, inventory_data } = payload;
    const updatedPayload = inventory_data.map((item) => ({
      merch_id: item.merch_id,
      merch_child_id: item.merch_child_id,
      from_stock_type: item.from_stock_type,
      from_stock_id: item.from_stock_id,
      to_stock_type: item.to_stock_type,
      to_stock_id: item.to_stock_id,
      quantity: item.quantity,
    }));

    const finalPayload = {
      inventory_type,
      inventory_data: updatedPayload,
    };

    try {
      if ((finalPayload.inventory_data[0].from_stock_type != finalPayload.inventory_data[0].to_stock_type) || (finalPayload.inventory_data[0].from_stock_id != finalPayload.inventory_data[0].to_stock_id)) {
        const resp = await apiCall(ApiUrl.addMerchShipment(), finalPayload);
        if (resp.status) {
          closeModal();
          getMerchList(selectedTrailer);
          toast.success(`Inventory moved successfully`);
        } else {
          if (resp.message) {
            toast.error(resp.message);
          } else {
            toast.error(`Inventory not moved. Please try later`);
          }
        }
      } else {
        toast.error(`From - To Warehouse/Trailer should not be same`);
      }
    } catch (error) {
      console.error("Error updating merch shipment:", error);
      toast.error(`An error occurred while updating merch shipment`);
    }
  };

  return (
    <LoadingOverlay
      active={isLoading}
      classNamePrefix="customLoader"
      spinner={<BeatLoader color="white" size={20} />}
    >
      <ModalCustomTrailers
        showModal={showModal}
        closeModal={closeModal}
        modalProps={modalProps}
        merchDetails={merchDetails}
        updateMerchStocks={updateMerchStocks}
        updateMerchShipment={updateMerchShipment}
        getShipmentTypeLists={getShipmentTypeLists}
        selectedTrailer={selectedTrailer}
        addOrUpdateNewTrailer={addOrUpdateNewTrailer} // Ensure this prop is passed to the modal
        TrailerDetails={TrailerDetails}
        // modalTitle={modalProps.title}
      />

      <div className="primary-header px-7 py-3">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row">
            <div className="flex flex-row justify-center items-center gap-10 cursor-pointer" ref={dropdownRef}>
              <div
                className="flex flex-row justify-between items-center"
                onClick={() => setTrailerDropdown(!TrailerDropdown)}
              >
                <div className="flex flex-row justify-center items-center">
                  <p className="font-pop font-medium text-primary text-sm">
                    {selectedTrailer?.trailer_name ?? "Manage Trailers"}</p>
                  <img
                    src="/chevron_down.png"
                    className="w-4 h-4 object-contain mt-2 mx-2"
                  />
                </div>
              </div>
              <div
                className="flex justify-center items-center w-5 h-5 rounded-md"
                style={{ marginLeft: "-2rem" }}
                onClick={() => openEditTrailerModal(selectedTrailer)}
              >
                <img
                  src={AppImages.edit}
                  className="w-5 h-5 object-contain mt-1"
                />
              </div>
              {TrailerDropdown && (
                <div className="absolute top-12 left-6 w-60 rounded-md z-30 shadow-lg cursor-pointer">
                  <SearchInput
                    searchInput={searchInput}
                    handleSearchInputChange={handleSearchInputChange}
                  />
                  <ul className="bg-white border border-primary absolute top-19 left-1 w-60 rounded-md shadow-lg max-h-44 z-30 overflow-y-scroll hide-scrollbar">
                    {filteredTrailers.map((item) => (
                      <li
                        key={item.id}
                        className="shadow-md"
                        onClick={() => handleTrailerClick(item)}
                      >
                        <p className="font-pop text-xs text-primary  hover:bg-primary hover:text-white border-b p-2">
                          {item.trailer_name}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            </div>
            <div className="flex flex-row items-center justify-center gap-12">
            <div
              className="flex flex-row justify-center items-center bg-primary w-40 h-9 rounded-lg gap-2 cursor-pointer"
              onClick={openAddTrailerModal}
            >
              <img src={"/plus.png"} className="w-3 h-3 object-contain" />
              <p className="font-pop text-xs text-white">Add Trailer</p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="main-body px-3 py-4">
      <ScrollButton/>
        {productsList &&
          productsList.map((item) => (
            <ProductItem
              key={item.id}
              productItem={item}
              stockType="trailer"
              handleAssignMerchStock={handleAssignMerchStock}
              handleAssignMerchShipment={handleAssignMerchShipment}
            />
          ))}
      </div>
    </LoadingOverlay>
  );
};

const secondaryMenu = AppData.productsSecondaryMenu;
export default withHoc(Trailer, { secondaryMenu, page: "trailer" });

export const AppData = {
  headerMenu: [
    {
      id: 30,
      title: "Tours",
      page: ["tours", "shows"],
    },
    {
      id: 31,
      title: "Merch",
      page: ["inventory", "warehouse", "trailer", "shipments", "orders"],
    },
    {
      id: 32,
      title: "Reports",
      page: ["Accounting", "Reports"],
    },
    {
      id: 33,
      title: "All Menu",
      page: [
        "tours",
        "shows",
        "inventory",
        "warehouse",
        "trailer",
        "shipments",
        "orders",
        "Accounting",
        "Reports",
      ],
    },
  ],

  toursSecondaryMenu: [
    {
      id: 1,
      title: "Overview",
      path: "/",
    },
    {
      id: 2,
      title: "Shows",
      path: "/shows", //+ localStorage.getItem('initalTourId')
    },
    {
      id: 3,
      title: "Inventory",
      path: "/inventory",
    },
    {
      id: 4,
      title: "Accounting",
      path: "/accounting",
    },
    {
      id: 5,
      title: "Merch IQ",
      path: "/merch-iq",
    },
    // {
    //     id: 6,
    //     title: 'counts',
    //     path: '/count'
    // },
  ],
  productsSecondaryMenu: [
    {
      id: 130,
      title: "Inventory",
      path: "/products",
    },
    {
      id: 132,
      title: "Warehouses",
      path: "/warehouses",
    },
    {
      id: 133,
      title: "Trailers",
      path: "/trailers",
    },
    {
      id: 134,
      title: "Shipment",
      path: "/shipment",
    },
    {
      id: 135,
      title: "Orders",
      path: "/orders",
    },
  ],
};

export const TableHeaderData = {
  tours: [
    {
      id: 6,
      title: "S.No",
    },
    {
      id: 7,
      title: "Tour Name",
    },
    {
      id: 8,
      title: "Show Date Range",
    },
    {
      id: 10,
      title: "Vend Fee",
    },
    {
      id: 11,
      title: "Tour Type",
    },
    {
      id: 12,
      title: "Report Currency",
    },
    {
      id: 13,
      title: "Action",
    },
  ],
};

export const allMenu = [
  {
    id: 313,
    title: "Dashboard",
    subMenu: [
      {
        id: 16,
        title: "Overview",
        path: "shows/",
      },
      {
        id: 15,
        title: "Upcoming Tours",
        path: "",
      },
    ],
  },
  {
    id: 314,
    title: "Shows",
    subMenu: [
      {
        id: 16,
        title: "Overview",
        path: "/shows",
      },
      {
        id: 17,
        title: "Shows left",
        path: "shows",
      },
      {
        id: 18,
        title: "Cancelled",
        path: "shows?status=cancelled",
      },
      {
        id: 19,
        title: "Completed",
        path: "shows?status=completed",
      },
    ],
  },
  {
    id: 20,
    title: "Accounting",
    subMenu: [
      {
        id: 21,
        title: "Settlements ",
        path: "",
      },
      {
        id: 22,
        title: "Cash onhand",
        path: "",
      },
      {
        id: 23,
        title: "Expenses",
        path: "",
      },
      {
        id: 24,
        title: "⁠Artist Settlements",
        path: "",
      },
      {
        id: 25,
        title: "Reports",
        path: "",
      },
    ],
  },
  {
    id: 24,
    title: "Tour Projections",
    subMenu: [
      {
        id: 25,
        title: "Planning",
        path: "",
      },
    ],
  },
  {
    id: 54,
    title: "Inventory",
    subMenu: [
      {
        id: 50,
        title: "Products",
        path: "products",
      },
      {
        id: 51,
        title: "Import Products",
        path: "",
      },
      {
        id: 52,
        title: "Save merch to trailer/warehouse",
        path: "",
      },
      {
        id: 53,
        title: "Archived Products",
        path: "",
      },
    ],
  },
  {
    id: 55,
    title: "Warehouses",
    subMenu: [
      {
        id: 56,
        title: "Warehouses",
        path: "warehouses",
      },
      {
        id: 57,
        title: "View Shipments",
        path: "",
      },
      {
        id: 58,
        title: "Reconcile",
        path: "",
      },
      {
        id: 59,
        title: "Transfer inventory",
        path: "",
      },
    ],
  },
  {
    id: 60,
    title: "Trailers",
    subMenu: [
      {
        id: 61,
        title: "Trailers",
        path: "trailers",
      },
      {
        id: 62,
        title: "View Shipments",
        path: "",
      },
      {
        id: 63,
        title: "Reconcile",
        path: "",
      },
      {
        id: 64,
        title: "Transfer inventory",
        path: "",
      },
      {
        id: 65,
        title: "Ship inventory",
        path: "",
      },
      {
        id: 66,
        title: "Item sheet",
        path: "",
      },
    ],
  },
  {
    id: 67,
    title: "Shipments",
    subMenu: [
      {
        id: 68,
        title: "All",
        path: "",
      },
      {
        id: 69,
        title: "Pending",
        path: "",
      },
      {
        id: 70,
        title: "In Transit",
        path: "",
      },
      {
        id: 71,
        title: "Received",
        path: "",
      },
      {
        id: 72,
        title: "Completed",
        path: "",
      },
      {
        id: 73,
        title: "Rejected",
        path: "",
      },
      {
        id: 74,
        title: "Resolved",
        path: "",
      },
    ],
  },
  {
    id: 75,
    title: "Orders",
    subMenu: [
      {
        id: 76,
        title: "All",
        path: "",
      },
      {
        id: 77,
        title: "Pending",
        path: "",
      },
      {
        id: 78,
        title: "In Transit",
        path: "",
      },
      {
        id: 79,
        title: "Received",
        path: "",
      },
      {
        id: 80,
        title: "Completed",
        path: "",
      },
      {
        id: 81,
        title: "Rejected",
        path: "",
      },
      {
        id: 82,
        title: "Resolved",
        path: "",
      },
    ],
  },
  {
    id: 56,
    title: "Reports",
    subMenu: [
      {
        id: 57,
        title: "Orders",
        path: "",
      },
      {
        id: 58,
        title: "Sales",
        path: "",
      },
      {
        id: 59,
        title: "Inventory Addjustments",
        path: "",
      },
      {
        id: 60,
        title: "⁠Comps",
        path: "",
      },
      {
        id: 61,
        title: "ETC",
        path: "",
      },
    ],
  },
];

export const showsMenu = [
  {
    id: 313,
    title: "Dashboard",
    subMenu: [
      // {
      //     id: 14,
      //     title: 'Add Tour',
      //     path: ''
      // },
      {
        id: 16,
        title: "Overview",
        path: "shows/", //+localStorage.getItem('initalTourId')+'/'+'total'
      },
      {
        id: 15,
        title: "Upcoming Tours",
        path: "",
      },
    ],
  },
  {
    id: 314,
    title: "Shows",
    // mainMenu: [
    //     {
    //         id: 16,
    //         title: 'Count',
    //         path: '/count'
    //     },
    //     {
    //         id: 17,
    //         title: 'Settlement',
    //         path: '/count'
    //     },
    // ],
    subMenu: [
      {
        id: 16,
        title: "Overview",
        path: "/shows", //+localStorage.getItem('initalTourId')+'/'+'total'
      },
      // {
      //     id: 98,
      //     title: 'Count',
      //     path: 'shows/'+ localStorage.getItem('initialTourId')+ '/'+ 'count'
      // },
      // {
      //     id: 99,
      //     title: 'Add Shows ',
      //     path:'shows/'//+ localStorage.getItem('initialTourId')+ '/'+ 'settlement'
      // },
      {
        id: 17,
        title: "Shows left",
        path: "shows", //+localStorage.getItem('initalTourId')+'/'+'left'
      },
      {
        id: 18,
        title: "Cancelled",
        path: "shows?status=cancelled", //+localStorage.getItem('initalTourId')+'/'+'cancelled'
      },
      {
        id: 19,
        title: "Completed",
        path: "shows?status=completed", //+localStorage.getItem('initalTourId')+'/'+'completed'
      },
    ],
  },
  {
    id: 20,
    title: "Accounting",
    subMenu: [
      {
        id: 21,
        title: "Settlements ",
        path: "",
      },
      {
        id: 22,
        title: "Cash onhand",
        path: "",
      },
      {
        id: 23,
        title: "Expenses",
        path: "",
      },
      {
        id: 24,
        title: "⁠Artist Settlements",
        path: "",
      },
      {
        id: 25,
        title: "Reports",
        path: "",
      },
    ],
  },

  {
    id: 24,
    title: "Tour Projections",
    subMenu: [
      {
        id: 25,
        title: "Planning",
        path: "",
      },
      // {
      //     id: 26,
      //     title: 'Planning',
      //     path: ''
      // }
    ],
  },

  // {
  //     id: 27,
  //     title: 'Merch IQ',
  //     subMenu: [
  //         {
  //             id: 28,
  //             title: 'Tour Forecast',
  //             path: ''
  //         },
  //         {
  //             id: 29,
  //             title: 'Tour planning',
  //             path: ''
  //         }
  //     ]
  // },
];

export const inventoryMenu = [
  {
    id: 54,
    title: "Inventory",
    subMenu: [
      {
        id: 50,
        title: "Products",
        path: "products",
      },
      {
        id: 51,
        title: "Import Products",
        path: "",
      },
      {
        id: 52,
        title: "Save merch to trailer/warehouse",
        path: "",
      },
      {
        id: 53,
        title: "Archived Products",
        path: "",
      },
    ],
  },
  {
    id: 55,
    title: "Warehouses",
    subMenu: [
      {
        id: 56,
        title: "Warehouses",
        path: "warehouses",
      },
      {
        id: 57,
        title: "View Shipments",
        path: "",
      },
      {
        id: 58,
        title: "Reconcile",
        path: "",
      },
      {
        id: 59,
        title: "Transfer inventory",
        path: "",
      },
    ],
  },
  {
    id: 60,
    title: "Trailers",
    subMenu: [
      {
        id: 61,
        title: "Trailers",
        path: "trailers",
      },
      {
        id: 62,
        title: "View Shipments",
        path: "",
      },
      {
        id: 63,
        title: "Reconcile",
        path: "",
      },
      {
        id: 64,
        title: "Transfer inventory",
        path: "",
      },
      {
        id: 65,
        title: "Ship inventory",
        path: "",
      },
      {
        id: 66,
        title: "Item sheet",
        path: "",
      },
    ],
  },
  {
    id: 67,
    title: "Shipments",
    subMenu: [
      {
        id: 68,
        title: "All",
        path: "",
      },
      {
        id: 69,
        title: "Pending",
        path: "",
      },
      {
        id: 70,
        title: "In Transit",
        path: "",
      },
      {
        id: 71,
        title: "Received",
        path: "",
      },
      {
        id: 72,
        title: "Completed",
        path: "",
      },
      {
        id: 73,
        title: "Rejected",
        path: "",
      },
      {
        id: 74,
        title: "Resolved",
        path: "",
      },
    ],
  },
  {
    id: 75,
    title: "Orders",
    subMenu: [
      {
        id: 76,
        title: "All",
        path: "",
      },
      {
        id: 77,
        title: "Pending",
        path: "",
      },
      {
        id: 78,
        title: "In Transit",
        path: "",
      },
      {
        id: 79,
        title: "Received",
        path: "",
      },
      {
        id: 80,
        title: "Completed",
        path: "",
      },
      {
        id: 81,
        title: "Rejected",
        path: "",
      },
      {
        id: 82,
        title: "Resolved",
        path: "",
      },
    ],
  },
];

export const reportMenu = [
  {
    id: 55,
    title: "Accounting",
    subMenu: [
      {
        id: 50,
        title: "Cash On Hand",
        path: "",
      },
      {
        id: 51,
        title: "Settlements",
        path: "",
      },
      {
        id: 52,
        title: "Expenses",
        path: "",
      },
    ],
  },
  {
    id: 56,
    title: "Reports",
    subMenu: [
      {
        id: 57,
        title: "Orders",
        path: "",
      },
      {
        id: 58,
        title: "Sales",
        path: "",
      },
      {
        id: 59,
        title: "Inventory Addjustments",
        path: "",
      },
      {
        id: 60,
        title: "⁠Comps",
        path: "",
      },
      {
        id: 61,
        title: "ETC",
        path: "",
      },
    ],
  },
];

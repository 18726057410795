import React, { useState } from "react";
import {
  covertToReadableFormat,
  convertToDbFormat,
} from "../../../../Utilites/Date";

const ShowsSettlements = (props) => {
  const { showItemObj } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Sales Tax Excluded");

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  const handleOptionClick = (item) => {
    setSelectedOption(item);
    setIsOpen(false);
  };
  console.log(showItemObj, "show");

  const grossTable = [
    {
      show_gross: "0000.00",
      correction: "1000.00",
    },
    {
      show_gross: "1000.00",
      correction: "2000.00",
    },
    {
      show_gross: "2000.00",
      correction: "3000.00",
    },
    {
      show_gross: "4000.00",
      correction: "5000.00",
    },
  ];

  const venueAdjustedGross = [
    {
      percentage: "25",
      cost: "0,000.00",
    },
    {
      percentage: "25",
      cost: "1,000.00",
    },
    {
      percentage: "10",
      cost: "2,000.00",
    },
  ];

  const venueDue = ["0000.00", "0000.00", "0000.00"];

  const titleTable = ["Soft", "Other", "Music", "Total"];
  const venueTable = ["Apparel", "Other", "CD"];
  const salesTax = ["0000.00", "0000.00", "0000.00", "0000.00"];

  const adjustedGross = ["0000.00", "0000.00", "0000.00", "0000.00"];
  return (
    <>
      <div className="flex flex-row justify-between items-center bg-white  px-7 py-3 gap-6">
        <div className="border border-primary rounded-lg p-3">
          <div className="flex flex-row gap-2">
            {showItemObj && showItemObj?.start_date && (
              <div className="flex flex-row justify-center items-center bg-primary px-5	h-8	rounded-lg gap-2">
                <p className="font-pop text-xs text-white text-center font-medium">
                  {covertToReadableFormat(showItemObj.start_date) +
                    " - " +
                    covertToReadableFormat(showItemObj.end_date)}
                </p>
              </div>
            )}
            <div className="flex flex-row justify-center items-center bg-primary px-5	h-8	rounded-lg gap-2">
              <p className="font-pop text-white text-xs">
                {showItemObj.venue_rep_name}
              </p>
              <img
                src="/chevron_down_white.png"
                className="w-2 h-2 object-contain"
              />
            </div>
            <div className="flex flex-row justify-center items-center bg-primary px-3 	h-8	rounded-lg gap-2">
              <p className="font-pop text-white text-xs">Capacity</p>
              <p className="font-pop text-white text-xs">|</p>
              <p className="font-pop text-white text-xs">
                {showItemObj.show_capacity}
              </p>
            </div>
            <div className="relative">
              <div
                className="flex flex-row justify-center items-center bg-primary px-3 h-8 rounded-lg gap-2 cursor-pointer"
                onClick={toggleDropdown}
              >
                <p className="font-pop text-white text-xs">{selectedOption}</p>
                <img
                  src="/chevron_down_white.png"
                  className={`w-2 h-2 object-contain transform ${
                    isOpen ? "rotate-180" : "rotate-0"
                  }`}
                />
              </div>
              {isOpen && (
                <div className="absolute top-full left-0 mt-1 w-full bg-white shadow-lg rounded-lg">
                  <div
                    className="cursor-pointer p-2 hover:bg-gray-200"
                    onClick={() => handleOptionClick("Sales Tax Included")}
                  >
                    Sales Tax Included
                  </div>
                  <div
                    className="cursor-pointer p-2 hover:bg-gray-200"
                    onClick={() => handleOptionClick("Sales Tax Excluded")}
                  >
                    Sales Tax Excluded
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-row">
              <div className="flex flex-row justify-center items-center bg-primary px-3	h-8	rounded-l-lg gap-2">
                <p className="font-pop text-white text-xs">Tax Fee %</p>
              </div>
              <div className="flex flex-row justify-center items-center bg-white border border-primary w-16	h-8	rounded-none gap-2">
                <p className="font-pop text-secondary text-xs">
                  {showItemObj.tax_apparel}%
                </p>
              </div>
              <div className="flex flex-row justify-center items-center bg-white border border-primary w-16	h-8	rounded-none gap-2">
                <p className="font-pop text-secondary text-xs">
                  {showItemObj.tax_music}%
                </p>
              </div>
              <div className="flex flex-row justify-center items-center bg-white border border-primary w-16	h-8	rounded-r-lg gap-2">
                <p className="font-pop text-secondary text-xs">
                  {showItemObj.tax_others}%
                </p>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-row justify-center items-center bg-primary px-3	h-8	rounded-l-lg gap-2">
                <p className="font-pop text-white text-xs">Venue Fee %</p>
              </div>
              <div className="flex flex-row justify-center items-center bg-white border border-primary w-16	h-8	rounded-none gap-2">
                <p className="font-pop text-secondary text-xs">
                  {showItemObj?.fee_apparel}%
                </p>
              </div>
              <div className="flex flex-row justify-center items-center bg-white border border-primary w-16	h-8	rounded-none gap-2">
                <p className="font-pop text-secondary text-xs">
                  {showItemObj?.fee_music}%
                </p>
              </div>
              <div className="flex flex-row justify-center items-center bg-white border border-primary w-16	h-8	rounded-r-lg gap-2">
                <p className="font-pop text-secondary text-xs">
                  {showItemObj?.fee_others}%
                </p>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-row justify-center items-center bg-primary px-3	h-8	rounded-l-lg gap-2">
                <p className="font-pop text-white text-xs">Tickets sale</p>
              </div>
              <div className="flex flex-row justify-center items-center bg-white border border-primary w-16	h-8	rounded-r-lg gap-2">
                <p className="font-pop text-secondary text-xs">|</p>
              </div>
            </div>
            <div className="flex flex-row gap-2">
              <div className="flex flex-row justify-center items-center bg-primary border border-primary w-7	h-7	rounded-lg">
                <p className="font-pop text-white text-xs">1</p>
              </div>
              <div className="flex flex-row justify-center items-center bg-white border border-primary w-7	h-7	rounded-lg">
                <p className="font-pop text-secondary text-xs">2</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center bg-white  px-7 py-3 gap-6">
        <div className="flex justify-between border border-primary rounded-lg p-3 gap-4">
          <div className="flex flex-col justify-between items-start gap-4">
            <div className="flex flex-col justify-between items-center">
              <p className="text-primary font-semibold font-pop text-md">
                Venue Information
              </p>
            </div>
            <div className="flex justify-between items-center gap-4">
              <p className="border text-primary rounded w-fit py-2 px-[50px]">
                {showItemObj?.venue_rep_name}
              </p>
              <p className="border text-primary rounded w-fit py-2 px-[50px]">
                {showItemObj?.venue_rep_email}
              </p>
              <p className="border text-primary rounded w-fit py-2 px-[50px]">
                {showItemObj?.venue_rep_phone}
              </p>
            </div>
            <div className="flex justify-between items-center gap-6">
              <div className="flex justify-between items-center gap-[14px]">
                <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                  <p className="font-pop text-white text-xs">Paid Attendance</p>
                </div>
                <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-5">
                  {showItemObj?.total_tickets}
                </p>
              </div>
              <div className="flex justify-between items-center gap-[14px]">
                <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                  <p className="font-pop text-white text-xs">Venue Capacity</p>
                </div>
                <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-5">
                  {showItemObj?.show_capacity || 0}
                </p>
              </div>
              <div className="flex justify-between items-center gap-[14px]">
                <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                  <p className="font-pop text-white text-xs">$ per head</p>
                </div>
                <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-5">
                  $ {showItemObj?.total_tickets}
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between items-center">
              <p className="text-primary font-semibold font-pop text-sm">
                Venue Fee%
              </p>
            </div>
            <div className="flex justify-between items-center gap-6">
              <div className="flex justify-between items-center gap-[14px]">
                <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                  <p className="font-pop text-white text-xs">Apparel</p>
                </div>
                <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-4">
                  {showItemObj?.fee_apparel} %
                </p>
              </div>
              <div className="flex justify-between items-center gap-[14px]">
                <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                  <p className="font-pop text-white text-xs">Other</p>
                </div>
                <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-4">
                  {showItemObj?.fee_others} %
                </p>
              </div>
              <div className="flex justify-between items-center gap-[14px]">
                <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                  <p className="font-pop text-white text-xs">Music</p>
                </div>
                <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-4">
                  {showItemObj?.fee_music} %
                </p>
              </div>
            </div>
          </div>
          <div className="min-h-full bg-[#C6C6C6] w-[2px] rounded"></div>
          <div className="flex flex-col justify-between items-start gap-4">
            <div className="flex flex-col justify-between items-center">
              <p className="text-primary font-semibold font-pop text-md">
                Tax Information
              </p>
            </div>
            <div className="flex justify-between items-center gap-4">
              <div className="flex justify-between items-center gap-[14px]">
                <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                  <p className="font-pop text-white text-xs">
                    {showItemObj?.tax_method == "inclusive"
                      ? "Tax Included"
                      : "Tax Excluded"}
                  </p>
                </div>
                <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-8">
                  Taxes on Top
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between items-center">
              <p className="text-primary font-semibold font-pop text-sm">
                Tax Retained By
              </p>
            </div>
            <div className="flex justify-between items-center gap-6">
              <div className="flex justify-between items-center gap-[14px]">
                <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                  <p className="font-pop text-white text-xs">
                    {showItemObj?.venue_rep_name}
                  </p>
                </div>
                <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                  <p className="font-pop text-white text-xs">Venue</p>
                </div>
                <div className="flex flex-row justify-center items-center border px-5 py-3	rounded-lg gap-2">
                  <p className="font-pop  text-primary text-xs">
                    {showItemObj?.tax_id ? showItemObj?.tax_id : 1}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between items-center">
              <p className="text-primary font-semibold font-pop text-sm">
                Tax% Rate
              </p>
            </div>
            <div className="flex justify-between items-center gap-6">
              <div className="flex justify-between items-center gap-[14px]">
                <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                  <p className="font-pop text-white text-xs">Apparel</p>
                </div>
                <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-4">
                  {showItemObj?.fee_apparel} %
                </p>
              </div>
              <div className="flex justify-between items-center gap-[14px]">
                <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                  <p className="font-pop text-white text-xs">Other</p>
                </div>
                <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-4">
                  {showItemObj?.fee_others} %
                </p>
              </div>
              <div className="flex justify-between items-center gap-[14px]">
                <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                  <p className="font-pop text-white text-xs">Music</p>
                </div>
                <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-4">
                  {showItemObj?.fee_music} %
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center bg-white  px-7 py-3 gap-6">
        <div className="flex justify-between border border-primary rounded-lg p-3 gap-4 w-full">
          <div className="flex flex-col justify-between items-start gap-4 w-[45%]">
            <div className="flex flex-col justify-between items-center">
              <p className="text-primary font-semibold font-pop text-md">
                Income Cash/CC
              </p>
            </div>
            <div className="flex flex-col mt-2 justify-between items-start gap-4">
              <div className="flex flex-col justify-between items-center">
                <p className="text-primary font-semibold font-pop text-sm">
                  Show Cash
                </p>
              </div>
              <div className="flex justify-between items-center gap-4">
                <div className="flex justify-between items-center gap-[14px]">
                  <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                    <p className="font-pop text-white text-xs">Artist</p>
                  </div>
                  <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-8">
                    Venue
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center gap-6">
                <div className="flex justify-between items-center gap-[14px]">
                  <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                    <p className="font-pop text-white text-xs">Amount</p>
                  </div>
                  <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-5">
                    $ 00000.00
                  </p>
                </div>
                <div className="flex justify-between items-center gap-[14px]">
                  <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                    <p className="font-pop text-white text-xs">Float</p>
                  </div>
                  <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-5">
                    $ 00000.00
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-2 justify-between items-start gap-4">
              <div className="flex flex-col justify-between items-center">
                <p className="text-primary font-semibold font-pop text-sm">
                  Credit Card
                </p>
              </div>
              <div className="flex justify-between items-center gap-4">
                <div className="flex justify-between items-center gap-[14px]">
                  <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                    <p className="font-pop text-white text-xs">Artist</p>
                  </div>
                  <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-8">
                    Venue
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center gap-6">
                <div className="flex justify-between items-center gap-[14px]">
                  <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                    <p className="font-pop text-white min-w-fit text-xs">
                      Amount
                    </p>
                  </div>
                  <p className="border font-pop h-[40px] text-primary rounded min-w-fit py-2 px-5">
                    $ 00000.00
                  </p>
                </div>
                <div className="flex justify-between items-center gap-[14px]">
                  <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                    <p className="font-pop text-white min-w-fit text-xs">CC%</p>
                  </div>
                  <p className="border font-pop h-[40px] text-primary rounded min-w-fit py-2 px-5">
                    %
                  </p>
                </div>
                <div className="flex justify-between items-center gap-[14px]">
                  <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                    <p className="font-pop text-white min-w-fit text-xs">
                      CC Fee
                    </p>
                  </div>
                  <p className="border font-pop h-[40px] text-primary rounded min-w-fit py-2 px-5">
                    $ 000.00
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="min-h-full bg-[#C6C6C6] w-[2px] rounded"></div>
          <div className="flex flex-col gap-2 items-start w-[53%]">
            <div className="flex flex-col gap-2 items-start w-[80%]">
              <div className="flex justify-between w-full">
                <div className="w-[25%]">
                  <div></div>
                </div>
                <div className="flex items-center">
                  {titleTable?.map((item) => (
                    <div className="flex flex-row justify-center items-center px-5 py-3 gap-2 min-w-[100px]">
                      <p className="font-pop text-primary text-xs">{item}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div className="w-[25%]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-t-lg gap-2">
                      <p className="font-pop text-white text-xs">Show Gross</p>
                    </div>
                    <div className="flex flex-row justify-center items-center bg-white border border-primary px-5 py-3 rounded-b-lg gap-2">
                      <p className="font-pop text-primary text-xs">
                        Correction
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  {grossTable?.map((item, index) => (
                    <div className="flex flex-col">
                      <div
                        className={`flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 ${
                          index == 0 && "rounded-tl-lg"
                        } ${
                          index == grossTable?.length - 1 && "rounded-tr-lg"
                        } gap-2`}
                      >
                        <p className="font-pop text-white text-xs">
                          $ {item.show_gross}
                        </p>
                      </div>
                      <div
                        className={`flex flex-row justify-center items-center bg-white border border-primary px-5 py-3 ${
                          index == 0 && "rounded-bl-lg"
                        } ${
                          index == grossTable?.length - 1 && "rounded-br-lg"
                        } gap-2`}
                      >
                        <p className="font-pop text-primary text-xs">
                          $ {item.correction}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div className="w-[25%]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                      <p className="font-pop text-white text-xs">Sales Tax</p>
                    </div>
                    {/* <div className="flex flex-row justify-center items-center bg-white border border-primary px-5 py-3 rounded-b-lg gap-2">
                    <p className="font-pop text-primary text-xs">Correction</p>
                  </div> */}
                  </div>
                </div>
                <div className="flex items-center">
                  {salesTax?.map((item, index) => (
                    <div className="flex flex-col">
                      <div
                        className={`flex flex-row justify-center items-center border border-primary px-5 py-3 gap-2 ${
                          index == 0 && "rounded-s-lg"
                        } ${index == salesTax?.length - 1 && "rounded-e-lg"}`}
                      >
                        <p className="font-pop text-primary text-xs">
                          $ {item}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div className="w-[25%]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                      <p className="font-pop text-white text-xs">
                        Adjusted Gross
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  {adjustedGross?.map((item, index) => (
                    <div className="flex flex-col">
                      <div
                        className={`flex flex-row justify-center items-center border border-primary px-5 py-3 gap-2 ${
                          index == 0 && "rounded-s-lg"
                        } ${
                          index == adjustedGross?.length - 1 && "rounded-e-lg"
                        }`}
                      >
                        <p className="font-pop text-primary text-xs">
                          $ {item}
                        </p>
                      </div>
                      {/* <div className="flex flex-row justify-center items-center bg-white border border-primary px-5 py-3 rounded-b-lg gap-2">
                    <p className="font-pop text-primary text-xs">$ {item.correction}</p>
                  </div> */}
                    </div>
                  ))}
                </div>
              </div>
              <div className="min-w-full bg-[#C6C6C6] h-[1.5px] rounded mt-4"></div>
              <div className="flex flex-wrap items-center justify-between gap-2 w-full">
                <div className="flex justify-between items-center gap-4 w-[48%]">
                  <div className="flex justify-between items-center gap-[14px]">
                    <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                      <p className="font-pop text-white text-xs">Collected $</p>
                    </div>
                    <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-8">
                      $ 000.0
                    </p>
                  </div>
                </div>
                <div className="flex justify-between items-center gap-4 w-[48%]">
                  <div className="flex justify-between items-center gap-[14px]">
                    <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                      <p className="font-pop text-white text-xs">
                        Counted gross
                      </p>
                    </div>
                    <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-8">
                      $ 000.0
                    </p>
                  </div>
                </div>
                <div className="flex justify-between items-center gap-4 w-[48%]">
                  <div className="flex justify-between items-center gap-[14px]">
                    <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                      <p className="font-pop text-white text-xs">
                        Count Difference
                      </p>
                    </div>
                    <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-8">
                      $ 000.0
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center bg-white  px-7 py-3 gap-6">
        <div className="flex justify-between border border-primary rounded-lg p-3 gap-4 w-full">
          <div className="flex flex-col justify-between items-start gap-4 w-[45%]">
            <div className="flex flex-col justify-between items-center">
              <p className="text-primary font-semibold font-pop text-md">
                Venue Settlement
              </p>
            </div>
            <div className="flex flex-col gap-2 items-start w-full">
              <div className="flex justify-between w-full">
                <div className="w-[25%]">
                  <div></div>
                </div>
                <div className="flex gap-4 items-center">
                  <div className="flex items-center">
                    {venueTable?.map((item) => (
                      <div className="flex flex-row justify-center items-center px-5 py-3 gap-2 min-w-[100px]">
                        <p className="font-pop text-primary text-xs">{item}</p>
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-row justify-center items-center px-5 py-3 gap-2 min-w-[100px]">
                    <p className="font-pop text-primary text-xs">Total</p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-end w-full">
                <div className="w-[25%]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                      <p className="font-pop text-white text-xs">
                        Adjusted Gross
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 items-center">
                  <div className="flex items-center">
                    {venueAdjustedGross?.map((item, index) => (
                      <div className="flex flex-col">
                        <div
                          className={`flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 ${
                            index == 0 && "rounded-tl-lg"
                          } ${
                            index == venueAdjustedGross?.length - 1 &&
                            "rounded-tr-lg"
                          } gap-2`}
                        >
                          <p className="font-pop text-white text-xs">
                            {item.percentage}%
                          </p>
                        </div>
                        <div
                          className={`flex flex-row justify-center items-center bg-white border border-primary px-5 py-3 ${
                            index == 0 && "rounded-bl-lg"
                          } ${
                            index == venueAdjustedGross?.length - 1 &&
                            "rounded-br-lg"
                          } gap-2`}
                        >
                          <p className="font-pop text-primary text-xs">
                            $ {item.cost}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex h-[79.6px] flex-row justify-center rounded items-center bg-white border border-primary px-5 py-3">
                    <p className="font-pop text-primary text-xs">$ 0,0000</p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-end w-full">
                <div className="w-[25%]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                      <p className="font-pop text-white text-xs">CC Fee</p>
                    </div>
                  </div>
                </div>
                <div className="flex w-[63.3%] items-center">
                  <div className="flex flex-row justify-center rounded items-center bg-white border border-primary px-5 py-3">
                    <p className="font-pop text-primary text-xs">$ 0,0000</p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-end w-full">
                <div className="w-[25%]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                      <p className="font-pop text-white text-xs">Venue Exsp</p>
                    </div>
                  </div>
                </div>
                <div className="flex w-[63.3%] items-center">
                  <div className="flex flex-row justify-center rounded items-center bg-white border border-primary px-5 py-3">
                    <p className="font-pop text-primary text-xs">$ 0,0000</p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-end w-full">
                <div className="w-[25%]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                      <p className="font-pop text-white text-xs">Artist Exsp</p>
                    </div>
                  </div>
                </div>
                <div className="flex w-[63.3%] items-center"></div>
              </div>
              <div className="flex justify-between items-end w-full">
                <div className="w-[25%]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                      <p className="font-pop text-white text-xs">Venue Due</p>
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 items-center">
                  <div className="flex items-center">
                    {venueDue?.map((item, index) => (
                      <div className="flex flex-col">
                        <div
                          className={`flex flex-row justify-center items-center border border-primary px-5 py-3 gap-2 ${
                            index == 0 && "rounded-s-lg"
                          } ${index == venueDue?.length - 1 && "rounded-e-lg"}`}
                        >
                          <p className="font-pop text-primary text-xs">
                            $ {item}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-row justify-center rounded items-center bg-white border border-primary px-5 py-3">
                    <p className="font-pop text-primary text-xs">$ 0,0000</p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-end w-full">
                <div className="w-[25%]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                      <p className="font-pop text-white text-xs line-clamp-2">
                        Total Due Artist
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex w-[63.3%] items-center">
                  <div className="flex flex-row justify-center rounded items-center bg-white border border-primary px-5 py-3">
                    <p className="font-pop text-primary text-xs">$ 0,0000</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="min-h-full bg-[#C6C6C6] w-[2px] rounded"></div>
          <div className="flex flex-col justify-end items-start gap-4 w-[45%]">
            {/* <div className="flex flex-col justify-between items-center">
              <p className="text-primary font-semibold font-pop text-md">
                Venue Settlement
              </p>
            </div> */}
            <div className="flex flex-col gap-2 items-start w-full">
              <div className="flex justify-between w-full">
                <div className="w-[25%]">
                  <div></div>
                </div>
                <div className="flex gap-4 items-center">
                  <div className="flex items-center">
                    {venueTable?.map((item) => (
                      <div className="flex flex-row justify-center items-center px-5 py-3 gap-2 min-w-[100px]">
                        <p className="font-pop text-primary text-xs">{item}</p>
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-row justify-center items-center px-5 py-3 gap-2 min-w-[100px]">
                    <p className="font-pop text-primary text-xs">Total</p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-end w-full">
                <div className="w-[25%]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                      <p className="font-pop text-white text-xs">
                        Adjusted Gross
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 items-center">
                  <div className="flex items-center">
                    {venueAdjustedGross?.map((item, index) => (
                      <div className="flex flex-col">
                        <div
                          className={`flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 ${
                            index == 0 && "rounded-tl-lg"
                          } ${
                            index == venueAdjustedGross?.length - 1 &&
                            "rounded-tr-lg"
                          } gap-2`}
                        >
                          <p className="font-pop text-white text-xs">
                            {item.percentage}%
                          </p>
                        </div>
                        <div
                          className={`flex flex-row justify-center items-center bg-white border border-primary px-5 py-3 ${
                            index == 0 && "rounded-bl-lg"
                          } ${
                            index == venueAdjustedGross?.length - 1 &&
                            "rounded-br-lg"
                          } gap-2`}
                        >
                          <p className="font-pop text-primary text-xs">
                            $ {item.cost}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex h-[79.6px] flex-row justify-center rounded items-center bg-white border border-primary px-5 py-3">
                    <p className="font-pop text-primary text-xs">$ 0,0000</p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-end w-full">
                <div className="w-[25%]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                      <p className="font-pop text-white text-xs">CC Fee</p>
                    </div>
                  </div>
                </div>
                <div className="flex w-[63.3%] items-center">
                  <div className="flex flex-row justify-center rounded items-center bg-white border border-primary px-5 py-3">
                    <p className="font-pop text-primary text-xs">$ 0,0000</p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-end w-full">
                <div className="w-[25%]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                      <p className="font-pop text-white text-xs">Venue Exsp</p>
                    </div>
                  </div>
                </div>
                <div className="flex w-[63.3%] items-center">
                  <div className="flex flex-row justify-center rounded items-center bg-white border border-primary px-5 py-3">
                    <p className="font-pop text-primary text-xs">$ 0,0000</p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-end w-full">
                <div className="w-[25%]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                      <p className="font-pop text-white text-xs">Artist Exsp</p>
                    </div>
                  </div>
                </div>
                <div className="flex w-[63.3%] items-center"></div>
              </div>
              <div className="flex justify-between items-end w-full">
                <div className="w-[25%]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                      <p className="font-pop text-white text-xs">Venue Due</p>
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 items-center">
                  <div className="flex items-center">
                    {venueDue?.map((item, index) => (
                      <div className="flex flex-col">
                        <div
                          className={`flex flex-row justify-center items-center border border-primary px-5 py-3 gap-2 ${
                            index == 0 && "rounded-s-lg"
                          } ${index == venueDue?.length - 1 && "rounded-e-lg"}`}
                        >
                          <p className="font-pop text-primary text-xs">
                            $ {item}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-row justify-center rounded items-center bg-white border border-primary px-5 py-3">
                    <p className="font-pop text-primary text-xs">$ 0,0000</p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-end w-full">
                <div className="w-[25%]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                      <p className="font-pop text-white text-xs line-clamp-2">
                        Total Due Artist
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex w-[63.3%] items-center">
                  <div className="flex flex-row justify-center rounded items-center bg-white border border-primary px-5 py-3">
                    <p className="font-pop text-primary text-xs">$ 0,0000</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center bg-white px-7 py-3 gap-6">
        <div className="flex border border-primary rounded-lg p-3 gap-8 w-full">
          <div className="flex flex-col justify-between items-start gap-6 w-[29%]">
            <div className="flex flex-col justify-between items-center">
              <p className="text-primary font-semibold font-pop text-md">
                Final Payment
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex justify-between items-center gap-4">
                <div className="flex justify-between items-center gap-[14px]">
                  <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                    <p className="font-pop text-white text-xs">
                      Venue owes to Artist
                    </p>
                  </div>
                  <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-8">
                    $ 000.00
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center gap-4">
                <div className="flex justify-between items-start gap-[14px]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-t-lg gap-2">
                      <p className="font-pop text-white text-xs">Cash</p>
                    </div>
                    <div className="flex flex-row justify-center items-center bg-white border border-primary px-5 py-3 rounded-b-lg gap-2">
                      <p className="font-pop text-primary text-xs">
                        $ 0,000.00
                      </p>
                    </div>
                  </div>
                  <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-8">
                    Cheque
                  </p>
                  <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-8">
                    To Follow
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center gap-4">
                <div className="flex justify-between items-center gap-[14px]">
                  <div className="flex flex-row justify-center items-center bg-primary px-5 py-3	rounded-lg gap-2">
                    <p className="font-pop text-white text-xs">Balance</p>
                  </div>
                  <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-8">
                    $ 000.00
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="min-h-full bg-[#C6C6C6] w-[2px] rounded"></div>
          <div className="flex flex-col items-start gap-6 w-[61%]">
            <div className="flex flex-col justify-between items-center">
              <p className="text-primary font-semibold font-pop text-md">
                Summary
              </p>
            </div>
            <div className="flex flex-col gap-2 items-start w-full">
              <div className="flex justify-between items-end w-full">
                <div className="flex gap-8 w-full">
                  <div className="w-[26%]">
                    <div className="flex flex-col">
                      <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                        <p className="font-pop text-white text-xs">Gross</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-[63.3%] items-center">
                    <div className="flex flex-row justify-center rounded items-center bg-white border border-primary px-5 py-3">
                      <p className="font-pop text-primary text-xs">$ 0,0000</p>
                    </div>
                  </div>
                </div>
                <div className="flex gap-8 w-full">
                  <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-5">
                    P/H
                  </p>
                  <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-5">
                    $ 00000.00
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-end w-full">
                <div className="flex gap-8 w-full">
                  <div className="w-[26%]">
                    <div className="flex flex-col">
                      <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                        <p className="font-pop text-white text-xs">Tax</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-[63.3%] items-center">
                    <div className="flex flex-row justify-center rounded items-center bg-white border border-primary px-5 py-3">
                      <p className="font-pop text-primary text-xs">$ 0,0000</p>
                    </div>
                  </div>
                </div>
                <div className="flex gap-8 w-full"></div>
              </div>
              <div className="flex justify-between items-end w-full">
                <div className="flex gap-8 w-full">
                  <div className="w-[26%]">
                    <div className="flex flex-col">
                      <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                        <p className="font-pop text-white text-xs">
                          Venue Fee%
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-[63.3%] items-center">
                    <div className="flex flex-row justify-center rounded items-center bg-white border border-primary px-5 py-3">
                      <p className="font-pop text-primary text-xs">$ 0,0000</p>
                    </div>
                  </div>
                </div>
                <div className="flex gap-8 w-full">
                  <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-5">
                    %
                  </p>
                  <p className="border font-pop h-[40px] text-primary rounded w-fit py-2 px-5">
                    0%
                  </p>
                </div>
              </div>
              {/* <div className="flex justify-between items-end w-full">
                <div className="w-[25%]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                      <p className="font-pop text-white text-xs">Venue Exsp</p>
                    </div>
                  </div>
                </div>
                <div className="flex w-[63.3%] items-center">
                  <div className="flex flex-row justify-center rounded items-center bg-white border border-primary px-5 py-3">
                    <p className="font-pop text-primary text-xs">$ 0,0000</p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-end w-full">
                <div className="w-[25%]">
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-center items-center bg-primary border-primary px-5 py-3 rounded-lg gap-2">
                      <p className="font-pop text-white text-xs line-clamp-2">
                        Total Due Artist
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex w-[63.3%] items-center">
                  <div className="flex flex-row justify-center rounded items-center bg-white border border-primary px-5 py-3">
                    <p className="font-pop text-primary text-xs">$ 0,0000</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center bg-white px-7 py-3 gap-6">
        <div className="flex justify-between border border-primary rounded-lg p-3 gap-4 w-full">
          <div className="flex flex-col justify-between items-start gap-6 w-[40%]">
            <div className="flex flex-col justify-between items-center">
              <p className="text-primary font-semibold font-pop text-md">
                Signature & Additional Info
              </p>
            </div>
            <div className="flex flex-col w-full gap-4">
              <p className="text-primary font-pop text-sm">Rep Sign</p>
              <textarea className="border rounded w-[98%]" rows={6}/>
              <div className="flex flex-wrap gap-4">
                <button className="btn rounded px-6 py-1 font-pop text-white bg-primary border border-primary">
                  Clear
                </button>
                <button className="btn rounded px-6 py-1 font-pop btn-primary border border-primary">
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className="min-h-full bg-[#C6C6C6] w-[2px] rounded"></div>
          <div className="flex flex-col justify-between items-start gap-6 w-[56%]">
            <div className="flex flex-col w-full gap-4">
              <p className="text-primary font-pop font-semibold text-sm">Additional Info</p>
              <div className="flex justify-between w-full">
              <textarea className="border rounded text-center w-[48%]" rows={8}>Settlement Notes</textarea>
              <textarea className="border rounded text-center w-[48%]" rows={8}>Shows Notes</textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center bg-white px-7 py-3 gap-6">
        <div className="flex justify-between border border-primary rounded-lg p-3 gap-4 w-full">
          <div className="flex flex-col justify-between items-start gap-6 w-[48%]">
            <div className="flex flex-col justify-between items-center">
              <p className="text-primary font-semibold font-pop text-md">
                Settled By & Attachments
              </p>
            </div>
            <div className="flex flex-col w-full gap-4">
              <p className="text-primary font-pop text-sm">Settled By</p>
              <div className="flex flex-wrap gap-4">
                <input type="text"  className="px-4 py-1 border border-primary text-center rounded text-primary" placeholder="Name"/>
                <input type="text"  className="px-4 py-1 border border-primary text-center rounded text-primary" placeholder="Mobile"/>
                <input type="text"  className="px-4 py-1 border border-primary text-center rounded text-primary" placeholder="Contact Email"/>
              </div>
            </div>
          </div>
          <div className="min-h-full bg-[#C6C6C6] w-[2px] rounded"></div>
          <div className="flex flex-col justify-end gap-6 w-[48%]">
            <div className="flex flex-col w-full gap-4">
              <p className="text-primary font-pop text-sm">Attachments</p>
              <div className="flex flex-wrap gap-4">
                <p className="font-pop text-white bg-primary border border-primary px-2 py-1 rounded">Select & Upload</p>
                <input type="text"  className="px-4 py-1 border border-primary text-center rounded text-primary" placeholder="File Name"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowsSettlements;

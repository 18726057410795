import React from "react";
import CustomModal from "../../../Components/Modal";
import ToursForm from "../../../Pages/Home/Tours/ToursForm";

const ModalCustom = ({ showModal, handleAddOrUpdateTour, addToursData, setAddExpandModal, singleTourDetails, closeModal, expandAddTourModal }) => {
  const handleClickClose = () => {
    closeModal();
  };

  return (
    <CustomModal
      handleModalClose={handleClickClose}
      modalProps={{ visible: showModal, title: "Add/Edit Tour", key: "addEditTour" }}
      className={`overflow-y-scroll w-full ${expandAddTourModal && "h-[600px]"
        } rounded-2xl shadow-xl`}
    >
      <ToursForm
        handleModalClose={handleClickClose}
        handleAddOrUpdateTour={handleAddOrUpdateTour}
        addToursData={addToursData}
        handleSetAddExpandModal={setAddExpandModal}
        singleTourDetails={singleTourDetails}
      />
    </CustomModal>

  );
};

export default ModalCustom;

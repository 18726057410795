import React, { useEffect, useState, useRef } from "react";
import withHoc from "../../../Components/Hoc";
import CustomModal from "../../../Components/Modal";
import ProductsForm from "./ProductsForm";
import ProductsStockForm from "./ProductsStockForm";
import ProductShipmentForm from "./ProductShipmentForm";
import ImageSliderModal from "./ImageSliderModal";
import { apiCall, getApiCall } from "../../../Utilites/Api";
import { ApiUrl } from "../../../Constants/Api";
import { toast } from "react-toastify";
import { convertToDbFormat } from "../../../Utilites/Date";
import { AppImages } from "../../../Constants/Images";
import { AppData } from "../../../Constants/AppData";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import SearchInput from "../../../Components/SearchInput";
import ScrollButton from "../../../Pages/Home/ScrollButton";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import NoData from "../../../Components/NoData";
import EditProduct from "./EditProduct";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const Table = (props) => {
  const { data, headerTitle } = props;
  return (
    <div className={`flex flex-col ${headerTitle ? "gap-3" : "gap-7"}`}>
      <p className="font-pop text-primary text-xs text-center">{headerTitle}</p>
      <div className="flex flex-row">
        {data &&
          data?.length > 0 &&
          data?.map((item, index) => (
            <div
              className={`flex flex-col ${
                index % 3 === 0 ? "border" : "border-r border-b border-t"
              }   rounded-lg`}
              key={item?.id}
            >
              <div className="bg-secondary py-2 rounded-t-md px-3">
                <p className="text-white font-pop text-[11px] text-center">
                  {item?.title}
                </p>
              </div>
              {item?.data &&
                item.data.length > 0 &&
                item.data.map((item1, index1) => (
                  <div
                    className={`px-6 py-2 ${
                      index1 === item.data.length - 1 ? "rounded-b" : "border-b"
                    } border-secondary`}
                  >
                    <p className="text-secondary font-pop text-[11px] text-center">
                      {item1}
                    </p>
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export const Sizes = ({ data }) => {
  return (
    <div className="flex flex-col gap-2 justify-start align-center mt-16">
      {data &&
        data.map((item) =>
          item.size ? (
            <div className="bg-secondary px-2 py-1 border-b border-secondary rounded-md">
              <p className="text-white font-pop text-xs  text-center">
                {item.size_name}
              </p>
            </div>
          ) : (
            ""
          )
        )}
    </div>
  );
};

export const ProductItem = ({
  productItem,
  handleAssignMerchStock,
  handleAssignMerchShipment,
  stockType,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImages, setModalImages] = useState([]);
  const handleImageClick = (images) => {
    const validImages = images.filter((img) => img);
    if (validImages.length > 0) {
      setModalImages(validImages);
      setIsModalOpen(true);
    } else {
      // Display a placeholder image
      // setModalImages(["https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"]);
      // setIsModalOpen(true);
      toast.warning("No valid images found, displaying placeholder image.");
    }
  };

  const dropdownRef = useRef(null);

  const renderSizeGraphs = (graphData) => {
    const labels = graphData?.Y_axis;

    const data = {
      labels,
      datasets: [
        {
          label: "Quantity",
          data: graphData.graph_data,
          borderColor: "#0a8543",
          backgroundColor: "#0a8543",
          barThickness: 30,
        },
      ],
    };
    const maxValue = Math.max(...graphData.graph_data);

    const options = {
      indexAxis: "y",
      elements: {
        bar: {
          borderWidth: 5,
          borderRadius: 5,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: "right",
          display: false,
        },
        title: {
          display: false,
          text: "Sizes chart",
        },
      },
      scales: {
        x: {
          min: 0,
          max: maxValue > 200 ? maxValue : 200,
          ticks: {
            stepSize: 20, // Define the step size for the x-axis
          },
        },
      },
    };

    return (
      <div className="chart-container">
        <Bar
          options={options}
          data={data}
          className="chart"
          //height={300}
          //width={500}
          //backgroundColor="red"
        />
      </div>
    );
  };

  //console.log(productItem, 'proo')

  const [showModal, setShowModal] = useState(false);

  const handleEditClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div
      className="merch-card rounded-lg p-5 my-4"
      key={productItem.id}
      id={"product_" + productItem.id}
    >
      <div className="flex flex-row align-center justify-between my-2">
        <div className="flex flex-row items-center justify-center gap-6">
          <div className="flex flex-col gap-1">
            <p className="text-primary font-medium font-pop text-sm">
              {productItem.product_name}
            </p>
            <div className="flex flex-row gap-2">
              <p className="text-secondary font-pop text-xs ">
                {productItem.category}
              </p>
              <p className="text-placeholderColor font-pop text-xs">
                Type - {productItem.product_type}
              </p>
            </div>
            <div className="flex flex-row gap-4 mt-2">
              <div
                className="relative ml-[10px] cursor-pointer"
                onClick={() =>
                  handleImageClick([productItem.image1, productItem.image2])
                }
              >
                {productItem?.image2 ? (
                  <>
                    <img
                      src={productItem?.image2}
                      alt="Preview"
                      className="w-24 h-24 z-10 rounded"
                    />
                    <img
                      src={productItem?.image1}
                      alt="Preview"
                      className="w-24 h-24 absolute top-[-10px] left-[-10px] bg-grey z-0 rounded"
                    />
                  </>
                ) : (
                  <img
                    src={
                      productItem?.image1 ||
                      "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"
                    }
                    alt="Preview"
                    className="w-24 h-24 z-10 rounded"
                  />
                )}
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex justify-center items-center border border-secondary w-7 h-7 rounded-md hoverable-button">
                  <img
                    src={AppImages.edit}
                    className="w-5 h-5 object-contain mt-1"
                    onClick={handleEditClick}
                  />
                </div>
                <div className="flex justify-center items-center border border-secondary w-7 h-7 rounded-md hoverable-button">
                  <img
                    src={AppImages.download}
                    className="w-5 h-5 object-contain mt-1"
                  />
                </div>
                <div className="flex justify-center items-center border border-secondary w-7 h-7 rounded-md hoverable-button">
                  <img
                    src={AppImages.deleteIcon}
                    className="w-5 h-5 object-contain mt-1"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-2">
              <div
                className="flex justify-center items-center w-7 h-7 border border-secondary rounded-md cursor-pointer hoverable-button"
                onClick={() => handleAssignMerchStock(productItem)}
              >
                <img src={AppImages.circle_plus_blue} className="w-3 h-3" />
              </div>
              <div
                className="flex justify-center items-center w-7 h-7 border border-secondary rounded-md cursor-pointer hoverable-button"
                onClick={() => handleAssignMerchShipment(productItem)}
              >
                <img src={AppImages.locker} className="w-3 h-3" />
              </div>
              <div className="flex justify-center items-center w-7 h-7 border border-secondary rounded-md hoverable-button">
                <img src={AppImages.cart} className="w-3 h-3" />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <div>
              <p className="font-pop text-xs text-placeholderColor">Color</p>
              <p className="font-pop text-sm text-primary">
                {productItem?.colour_name}
              </p>
            </div>

            <div>
              <p className="font-pop text-xs text-placeholderColor">Gender</p>
              <p className="font-pop text-sm text-primary">
                {productItem.gender}{" "}
              </p>
            </div>

            <div>
              <p className="font-pop text-xs text-placeholderColor">Reorder</p>
              <p className="font-pop text-sm text-primary">Priority</p>
            </div>
            <div>
              <label
                for="vehicle1"
                className="font-pop text-xs text-placeholderColor "
              >
                Multi-Artist
              </label>{" "}
              &nbsp;
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
                className="border border-placeholderColor"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 ">
          <div className="flex flex-row gap-4">
            <Sizes data={productItem.child_list} />
            {stockType === "" && <Table data={[productItem.ordered]} />}
            {(stockType === "warehouse" || stockType === "") && (
              <Table data={productItem.warehouse} headerTitle="Warehouses" />
            )}
            {(stockType === "trailer" || stockType === "") && (
              <Table data={productItem.trailer} headerTitle="Trailers" />
            )}

            <Table data={[productItem.total]} />
            {stockType !== "" && <Table data={[productItem.avg_cost]} />}
          </div>
          {stockType === "" && (
            <div className="flex justify-end">
              <p className="font-pop text-sm font-meduim">
                {/* Gross Total | {productItem.sale_price ?? "N/A"}{" "} */}
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-col  gap-4 ">
          <p className="font-pop text-sm font-medium text-right">
            Total Merch : {productItem.quantity_total ?? 0}{" "}
          </p>
          {renderSizeGraphs(productItem?.graph)}
        </div>
      </div>
      <ImageSliderModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        images={modalImages}
      />
      <EditProduct show={showModal} onHide={handleCloseModal} />
    </div>
  );
};

const Products = () => {
  const [modalProps, setModalProps] = useState({
    visible: false,
    title: "",
    key: "",
  });
  const [addMerchData, setAddMerchData] = useState({});
  const [expandAddProductModal, setAddExpandModal] = useState(false);
  const [merchSizes, setMerchSizes] = useState([]);
  const [productsList, setProductList] = useState([]);
  const [merchDetails, setmerchDetails] = useState(null);
  const [stockTypes, setStockTypes] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [ProductDropdown, setProductDropdown] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    getAddMerchData();
    getMerchSizes();
    getMerchList();
  }, []);

  const getAddMerchData = async () => {
    const resp = await getApiCall(ApiUrl.addMerchData());
    if (resp.status) {
      setAddMerchData(resp.data);
    }
  };

  const getMerchSizes = async () => {
    const resp = await getApiCall(ApiUrl.merchSizes());
    if (resp.status) {
      setMerchSizes(resp.data);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setProductDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getMerchList = async () => {
    const resp = await getApiCall(ApiUrl.merchList("inventory"));
    if (resp.status) {
      setProductList(resp.data.merch_list || []);
    }
  };
  //console.log(productsList, 'products')

  const openAddProductModal = () => {
    setModalProps({
      visible: true,
      title: "General Information ",
      key: "addProduct",
    });
  };

  const closeModal = () => {
    setModalProps({
      visible: false,
      title: "",
      key: "",
    });
  };

  const addOrUpdateProduct = async ({ ...payload }, skuFeilds) => {
    //console.log(payload, 'proopay')
    payload.child = skuFeilds.map((item) => ({ ...item, size: item.id }));
    payload.category =
      addMerchData &&
      addMerchData.categories.filter(
        (item) => item.id == payload.category_id
      )[0]?.name;
    if (payload.pre_order && payload.release_date) {
      payload.release_date = convertToDbFormat(payload.release_date);
    }
    const resp = await apiCall(ApiUrl.addMerch(), payload);
    if (resp.status) {
      closeModal();
      getMerchList();
      toast.success(`Product added successfully.`);
    } else {
      if (resp?.message) {
        toast.error(resp?.message);
      } else {
        toast.error(`Product not added. Please try later.`);
      }
    }
  };

  //console.log(addMerchData, 'prooadd')

  const handleAssignMerchStock = (item) => {
    setmerchDetails(item);
    setModalProps({
      visible: true,
      title: "Merch Stock",
      key: "merchStock",
    });
  };
  const handleAssignMerchShipment = (item) => {
    setmerchDetails(item);
    setModalProps({
      visible: true,
      title: "Merch Shipment",
      key: "productsShipment",
    });
  };

  const getStockTypeList = async (stockType) => {
    const resp = await getApiCall(
      stockType === "Warehouse"
        ? ApiUrl.WarehousesList()
        : ApiUrl.TrailersList()
    );
    if (resp.status) {
      setStockTypes(resp.data);
    }
  };

  const getShipmentTypeLists = async () => {
    try {
      const warehouseResp = await getApiCall(ApiUrl.WarehousesList());
      const trailerResp = await getApiCall(ApiUrl.TrailersList());
      if (warehouseResp.status && trailerResp.status) {
        const combinedList = [
          ...warehouseResp.data.map((item) => ({ ...item, type: "warehouse" })),
          ...trailerResp.data.map((item) => ({ ...item, type: "trailer" })),
        ];
        setStockTypes(combinedList);
      } else {
        console.error("Failed to fetch warehouse or trailer list");
      }
    } catch (error) {
      console.error("Error fetching warehouse or trailer list:", error);
    }
  };

  const updateMerchStocks = async ({ ...payload }, merchSizes) => {
    const updatedPayload = merchSizes.map((item) => {
      return {
        ...item,
        stock_type: payload.stock_type,
        stock_id: payload.stock_id,
        merch_child_id: item.id,
      };
    });
    const resp = await apiCall(ApiUrl.addMerchStock(), updatedPayload);
    if (resp.status) {
      closeModal();
      getMerchList();
      toast.success(`Merch details updated successfully`);
    } else {
      toast.error(`Merch details not updated. Please try later`);
    }
  };

  //   const updateMerchShipment = async ({ stock_type, stock_id, from, to }, merchSizes) => {
  //     // alert('dsad');
  //     const payload = {
  //         stock_type,
  //         stock_id,
  //         from,
  //         to
  //         // Add other necessary fields from values
  //         // merchSizes can be used to construct additional payload data
  //     };
  //     const resp = await apiCall(ApiUrl.addMerchShipment(), payload);
  //     if (resp.status) {
  //         closeModal();
  //         getMerchList();
  //         toast.success(`Merch move updated successfully`);
  //     } else {
  //         toast.error(`Merch move not updated. Please try later`);
  //     }
  // };
  //   //console.log(productsList, 'prooli')

  const updateMerchShipment = async (payload) => {
    const { inventory_type, inventory_data } = payload;
    const updatedPayload = inventory_data.map((item) => ({
      merch_id: item.merch_id,
      merch_child_id: item.merch_child_id,
      from_stock_type: item.from_stock_type,
      from_stock_id: item.from_stock_id,
      to_stock_type: item.to_stock_type,
      to_stock_id: item.to_stock_id,
      quantity: item.quantity,
    }));

    const finalPayload = {
      inventory_type,
      inventory_data: updatedPayload,
    };

    try {
      if (
        finalPayload.inventory_data[0].from_stock_type !=
          finalPayload.inventory_data[0].to_stock_type ||
        finalPayload.inventory_data[0].from_stock_id !=
          finalPayload.inventory_data[0].to_stock_id
      ) {
        const resp = await apiCall(ApiUrl.addMerchShipment(), finalPayload);
        if (resp.status) {
          closeModal();
          getMerchList();
          toast.success(`Inventory moved successfully`);
        } else {
          if (resp.message) {
            toast.error(resp.message);
          } else {
            toast.error(`Inventory not moved. Please try later`);
          }
        }
      } else {
        toast.error(`From - To Warehouse/Trailer should not be same`);
      }
    } catch (error) {
      console.error("Error updating merch shipment:", error);
      toast.error(`An error occurred while updating merch shipment`);
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredProducts = productsList?.filter(
    (products) =>
      typeof products.product_name === "string" &&
      products.product_name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const productListRef = useRef(null);

  // Function to scroll to a product by name
  const scrollToProduct = (productId) => {
    const productElement = productListRef.current.querySelector(
      `#product_${productId}`
    );
    if (productElement) {
      productElement.scrollIntoView({ behavior: "smooth" });
    }
    setProductDropdown(!ProductDropdown);
    setSearchInput("");
  };
  return (
    <>
      <CustomModal
        handleModalClose={closeModal}
        modalProps={modalProps}
        className={`overflow-y-scroll w-full rounded-2xl shadow-xl ${
          expandAddProductModal && "h-[600px]"
        }`}
      >
        {modalProps.key === "merchStock" ? (
          <ProductsStockForm
            handleModalClose={closeModal}
            modalProps={modalProps}
            merchDetails={merchDetails}
            handleGetStockTypeList={getStockTypeList}
            stockTypes={stockTypes}
            handleMerchStockSubmit={updateMerchStocks}
            currentStockType=""
            currentStockId=""
          />
        ) : modalProps.key === "productsShipment" ? (
          <ProductShipmentForm
            handleModalClose={closeModal}
            modalProps={modalProps}
            merchDetails={merchDetails}
            handleGetShipmentTypeLists={getShipmentTypeLists}
            stockTypes={stockTypes}
            handleMerchShipmentSubmit={updateMerchShipment}
            currentInventoryType=""
            currentFromStockType=""
            currentFromStockId=""
            currentToStockType=""
            currentToStockId=""
          />
        ) : (
          <ProductsForm
            handleModalClose={closeModal}
            modalProps={modalProps}
            addMerchData={addMerchData}
            handleSetAddExpandModal={setAddExpandModal}
            merchSizes={merchSizes}
            handleAddProduct={addOrUpdateProduct}
          />
        )}
      </CustomModal>
      <div className="primary-header px-7 py-3 ">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row justify-center items-center">
            <p
              className="font-pop font-medium text-primary text-md cursor-pointer"
              onClick={() => setProductDropdown(!ProductDropdown)}
            >
              Products
            </p>
            <img
              src="/chevron_down.png"
              className="w-4 h-4 object-contain mt-2 mx-2 cursor-pointer"
            />
          </div>
          {ProductDropdown && (
            <div
              className="absolute left-7 w-60  border-primary rounded-md shadow-lg z-30 cursor-pointer"
              style={{ top: "178px" }}
              ref={dropdownRef}
            >
              <SearchInput
                searchInput={searchInput}
                handleSearchInputChange={handleSearchInputChange}
              />
              <ul className="bg-white border border-primary absolute top-19 left-1 w-60 rounded-md shadow-lg max-h-44 z-30 overflow-y-scroll hide-scrollbar">
                {filteredProducts.map((item) => (
                  <li
                    key={item.id}
                    className="shadow-md"
                    onClick={() => scrollToProduct(item.id)}
                  >
                    <p className="font-pop text-xs text-primary  hover:bg-primary hover:text-white border-b p-2">
                      {item.product_name}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="flex flex-row items-center justify-center gap-12">
            <p className="font-pop text-primary text-sm">Import Products</p>
            <p className="font-pop text-primary text-sm">
              Save Merch to Trailer/Warehouse
            </p>
            <div
              className="flex flex-row justify-center items-center bg-primary w-40 h-9 rounded-lg gap-2 cursor-pointer"
              onClick={openAddProductModal}
            >
              <img src={"/plus.png"} className="w-3 h-3 object-contain" />
              <p className="font-pop text-xs text-white">Add Merch</p>
            </div>
          </div>
        </div>
      </div>
      <div className="main-body px-3 py-4" ref={productListRef}>
        <ScrollButton />
        {productsList &&
          productsList.map((item) => (
            <ProductItem
              productItem={item}
              stockType={""}
              handleAssignMerchStock={handleAssignMerchStock}
              handleAssignMerchShipment={handleAssignMerchShipment}
            />
          ))}
        {productsList?.length == 0 && (
          <NoData description="No data found.Please check back later" />
        )}
      </div>
    </>
  );
};

const secondaryMenu = AppData.productsSecondaryMenu;
export default withHoc(Products, { secondaryMenu, page: "inventory" });

export const BASE_URL = "https://shows.prapadtechnologies.com";
//export const BASE_URL = "http://localhost/australia_admin";

export const ApiUrl = {
  login: "/auth/api/auth/login",
  logout: "/auth/api/auth/logout",
  searchVenue: "/shows/api/venue_address",
  addShow: "/shows/api/show_create",
  createVenue: "/shows/api/venue_create",

  showsList: (showId,status) => { return `/shows/api/shows_list/${showId}?status=${status}`} ,
  toursList: (tourStatus) => {
    return `/tour/api/tour_list?status=${tourStatus}`;
  },
  addToursData:'/tour/api/add_tours_data',
  addTour: '/tour/api/tour_create',
  tourEdit: (tourId) => {
    return `/tour/api/tour_edit/${tourId}`
  },
  cancelTour: (tourId) => {
    return `/tour/api/tour_cancel/${tourId}`
  },
  // countsShow: (showId) => {
  //   return ``
  // },
  merchCounts: (tourId, showId, stand_type) => {
    let url = `/merch/api/merch_counts`;
    url = url +  `?tour_id=${tourId}`;
    url = url + `&show_id=${showId}`;
    url = url + `&stand_type=${stand_type}`;
    return url;
  },
  countsMainStand: (tourId, showId) => {
    let url = `/merch/api/merch_counts_total`;
    url = url + `?tour_id=${tourId}`;
    url = url + `&show_id=${showId}`;
    return url;
  },
  countsCreate: () => {
    return `/merch/api/merch_count_create`
  },
  countsCostChange: () => {
    return `/merch/api/counts_change_price`
  },
  changeTaxMethod: () => {
    return `/merch/api/change_tax_method`
  },
  changeTaxPer: () => {
    return `/merch/api/counts_change_tax`
  },
  addNewStand: () => {
    return `/merch/api/add_stand_type`
  },
  postPoneShows : (showId) => {
    return `/shows/api/show_postponed/${showId}`
  },
  cancelShow: (showId) => {
    return `/shows/api/show_change_status/${showId}`
  },

  showEdit: (showId) => {
    return `/shows/api/show_edit/${showId}`
  },
  
  NoteUpdate : (showId) => `/shows/api/note_update/${showId}`,

  TicketSaleUpdate: (showId) => `/shows/api/ticketsale_update/${showId}`,
  
  addMerchData: () => {
    return `/merch/api/merch_data`
  },
  register: () => {
    return `/auth/api/auth/create_user`
  },
  ForgotPassword: () => `/auth/api/auth/forgot_password`,

  ResetPassword: () => '/auth/api/auth/reset_password',

  ChangePassword: () => '/auth/api/auth/change_password',
  
  merchSizes: () => {
    return `/merch/api/sizes`
  },
  addMerch: () => {
    return `/merch/api/merch_create`
  },
  editMerch: () => {
    return `/merch/api/merch_edit`
  },
  WarehousesList: () => {
    return `/warehouse/api/warehouse_list`
  },
  addWarehouse: () => {
    return `/warehouse/api/warehouse_create`
  },
  WarehouseEdit: (warehouse_id) => {
    return `/warehouse/api/warehouse_edit/${warehouse_id}`;
  },
  
  
  TrailersList: () => {
    return `/trailer/api/trailer_list`
  },
  addTrailer: () => {
    return `/trailer/api/trailer_create`
  },
  TrailerEdit: (trailer_id) => {
    return `/trailer/api/trailer_edit/${trailer_id}`;
  },
  merchList: (stockType, stockId) => {
    let url = `/merch/api/merch_list`;
    if(stockType !== 'inventory'){
      url = url +  `?stock_type=${stockType}`;
      url = url + `&stock_id=${stockId??0}`
    }
    return url;
  },
  addMerchStock: () => {
    return `/merch/api/merch_quantity_create`
  },
  addMerchShipment: () => {
    return `/merch/api/merch_move_inventory`
  },
};

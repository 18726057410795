import React, { useContext, useState } from 'react';
import Header from "../../../Components/Header";
import { Formik, Form, Field } from 'formik';
import TextInput from '../../../Components/TextInput';
import { toast } from 'react-toastify';
import { apiCall } from '../../../Utilites/Api';
import { ApiUrl } from '../../../Constants/Api';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { ChangePasswordSchema } from '../../../Utilites/Validation';
import { AuthContext } from '../../../Context/AuthContext';

const MyProfile = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const toggleShowOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };
    const authContext = useContext(AuthContext);
    const doChangePassword = async (payload) => {
        const resp = await apiCall(ApiUrl.ChangePassword(), payload);
        if (resp.status) {
            toast.success(resp.message);
            authContext.doLogout();
        } else {
            toast.error(resp.message);
        }
    };
        

        
    return (
        <>
            <Header currentPage="MyProfile" />
            <div className="flex flex-row items-center justify-between gap-4 py-3 px-6 border-third">
                <button className="flex justify-center items-center px-3 py-2 rounded-lg cursor-pointer merch-icons hoverable-button bg-white">Account info</button>
                <button className="flex justify-center items-center px-3 py-2 rounded-lg cursor-pointer merch-icons hoverable-button bg-white">Plan & payment</button>
                <button className="flex justify-center items-center px-3 py-2 rounded-lg cursor-pointer merch-icons hoverable-button bg-white">Taxes</button>
                <button className="flex justify-center items-center px-3 py-2 rounded-lg cursor-pointer merch-icons hoverable-button bg-white">Manage users & Email list</button>
                <p className="flex justify-center items-center px-3 py-2 rounded-lg cursor-pointer ml-auto font-pop">Settings</p>
            </div>
            <div className="flex">
                <div className="profile-card overflow-hidden rounded-lg px-3 py-4 h-auto">
                    <p className="font-pop ml-5 mt-5">Account Info</p>
                    <Formik
                        initialValues={{
                            artistName: '',
                            genre: '',
                            contactName: '',
                            contactEmail: '',
                            contactPhone: '',
                            paymentDetails: '',
                            legalCopy: '',
                            report: 'reportSoundscan'
                        }}
                        onSubmit={(values) => {
                            console.log(values);
                        }}
                    >
                        {({ values, setFieldValue }) => (
                            <Form className="bg-white my-4 flex-start flex flex-col gap-4 justify-center mx-6 profile-border">
                                <div className="flex">
                                    <div className="mr-8">
                                        <TextInput
                                            type="text"
                                            name="artistName"
                                            placeholder="Artist Name"
                                            page="MyProfile"
                                        />
                                    </div>
                                    <div className="mr-8">
                                        <TextInput
                                            type="text"
                                            name="genre"
                                            placeholder="Genre"
                                            page="MyProfile"
                                        />
                                    </div>
                                    <div className="mr-8">
                                        <TextInput
                                            type="text"
                                            name="contactName"
                                            placeholder="Contact Name"
                                            page="MyProfile"
                                        />
                                    </div>
                                    <div className="mr-8">
                                        <TextInput
                                            type="text"
                                            name="contactEmail"
                                            placeholder="Contact Email"
                                            page="MyProfile"
                                        />
                                    </div>
                                    <div>
                                        <TextInput
                                            type="text"
                                            name="contactPhone"
                                            placeholder="Contact Phone"
                                            page="MyProfile"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row  ml-5 gap-8">
                                    <div className="flex flex-row gap-6">
                                        <div>
                                            <p className="font-pop">Report to Soundscan</p>
                                            <div className="flex items-center mt-2">
                                                <label className="flex items-center mr-4">
                                                    <Field
                                                        type="radio"
                                                        name="report"
                                                        value="reportSoundscan"
                                                        className="mr-2"
                                                        checked={values.report === 'reportSoundscan'}
                                                        onClick={() => setFieldValue('report', 'reportSoundscan')}
                                                    />
                                                    Enable
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <p className="font-pop">Report to CMTA</p>
                                            <div className="flex items-center mt-2">
                                                <label className="flex items-center mr-4">
                                                    <Field
                                                        type="radio"
                                                        name="report"
                                                        value="reportCMTA"
                                                        className="mr-2"
                                                        checked={values.report === 'reportCMTA'}
                                                        onClick={() => setFieldValue('report', 'reportCMTA')}
                                                    />
                                                    Disable
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row gap-6">
                                        <div className="mr-8">
                                            <TextInput
                                                type="text"
                                                name="paymentDetails"
                                                placeholder="Payment Details"
                                                page="MyProfile"
                                                
                                            />
                                        </div>
                                        <div>
                                            <TextInput
                                                type="text"
                                                name="legalCopy"
                                                placeholder="Legal Copy"
                                                page="MyProfile"
                                               
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-20">
                                        <button className="bg-primary text-white font-bold py-2 px-4 rounded-lg mb-2 profile-button">
                                            Save
                                        </button>
                                        <button className="bg-grey text-gray-700 font-bold py-2 px-4 rounded-lg profile-button">
                                            Cancel
                                        </button>
                                    </div>

                                </div>
                                
                            </Form>

                        )}
                    </Formik>
                    <div>
                    <p className="font-pop ml-5 mt-5">Plan & Payment</p>
                    <Formik
                    
                        initialValues={{
                            cardNumber: '',
                            expireDate: '',
                            report: 'reportSoundscan'
                        }}
                        onSubmit={(values) => {
                            console.log(values);
                        }}
                    >
                        {({ values, setFieldValue }) => (
                            <Form className="bg-white my-4 flex-start flex flex-col gap-4 justify-center mx-6 profile-border">
                                <h1 className="ml-7 text-black-500">Select a Plan</h1>
                                
                                <div className="flex flex-row ml-5 gap-8 ">
                                    <div className="flex flex-col">
                                        <div className="flex flex-row items-center mb-2">
                                            <button className="bg-primary text-white font-pop py-2 px-4 rounded-lg mr-2 payment-button">
                                                Soundscan Reporter
                                            </button>
                                            <p className="flex-grow">$12/touring month</p>
                                        </div>
                                        <div className="flex flex-row items-center">
                                            <button className="bg-grey text-gray-500 font-pop py-2 px-4 rounded-lg mr-2 payment-button">
                                                Tour Merch Mgmt
                                            </button>
                                            <p>$68/touring month</p>
                                        </div>
                                    </div>

                                    <div className="flex flex-col">
                                        <div className="flex flex-row items-center mb-2">
                                            <button className="bg-grey text-gray-500 font-pop py-2 px-4 rounded-lg mr-2 payment-button">
                                                Advanced inventory Mgmt
                                            </button>
                                            <p className="flex-grow">$12/touring month</p>
                                        </div>
                                        <div className="flex flex-row items-center">
                                            <button className="bg-grey text-gray-500 font-pop py-2 px-4 rounded-lg mr-2 payment-button">
                                                MerchIQ
                                            </button>
                                            <p>$68/touring month</p>
                                        </div>
                                    </div>
                                    <div class="border-l h-30 border-secondary shadow-lg"></div>
                                    <div className="flex flex-col items-start" style={{ marginTop: '-40px' }}>
                                        <h1 className="text-black-500 mb-4">Pay with Card</h1>
                                        <div className="flex flex-col">
                                            <div className="">
                                                <TextInput
                                                    type="text"
                                                    name="cardNumber"
                                                    placeholder="Card Number"
                                                    page="MyProfile"
                                                />
                                            </div>
                                            <div className="flex items-center">
                                                <TextInput
                                                    type="text"
                                                    name="expireDate"
                                                    placeholder="Expire Date (MM/YY)"
                                                    page="MyProfile"
                                                />
                                            </div>
                                            <div className="flex flex-row gap-4">
                                                <button className="bg-primary text-white font-pop py-2 px-4 rounded-lg payment-button">
                                                    Save Payment Method
                                                </button>
                                                <button className="bg-grey text-gray-500 font-pop py-2 px-4 rounded-lg payment-button">
                                                    Show Transaction History
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="flex flex-row ml-5 gap-8" style={{ marginTop: '-60px' }}>
                                    <button className="bg-primary text-white font-pop py-2 px-4 rounded-lg profile-button">
                                        Save
                                    </button>
                                    <button className="bg-grey text-gray-500 font-pop py-2 px-4 rounded-lg profile-button">
                                        Cancel
                                    </button>
                                </div>
                                
                            </Form>
                        )}
                    </Formik>
                    </div>
                    <div>
                        <p className="font-pop ml-5 mt-5 ">Taxes</p>
                        <h1 className="ml-7 text-black-500 mt-5">Default Tax Method</h1>
                        <div className="flex flex-row ml-5 gap-8 mt-5 profile-border">
                            <div className="flex flex-row gap-4">
                                <button className="bg-primary text-white font-pop rounded-lg taxes-button">
                                    Tax Inclusive
                                </button>
                                <button className="bg-grey text-gray-500 font-pop rounded-lg taxes-button">
                                    Tax on Top
                                </button>
                            </div>
                            <div className="ml-5" style={{ marginTop: '-40px' }}>
                                <ul className="list-disc list-inside text-black-500">
                                    <li className="font-pop">The tax method you to update how taxes are collected when you're using UsBand Register.</li>
                                    <li className="font-pop">By changing the value to "Tax on Top",taxes will be added in top of the item price entered.</li>
                                    <li className="font-pop">Your Counts and Settlements will automatically adjust the prices to either include tax as part of the price or add it on top.</li>
                                    <li className="font-pop">Settlements are completed as usual as the taxes has been incorporated in either scenario.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="font-pop ml-5 mt-5">Manage Users & Email List</p>
                        <div className="flex flex-row ml-5 gap-8 mt-2 ">
                            <div className="flex flex-col gap-4 ml-2">
                                <h1 className="text-black-600 mt-5">Users</h1>
                                <p>Add & Remove Users from this account. Users have login access and are included on the nightly Settlement emails.</p>
                            </div>
                            <div class="border-l h-auto border-secondary shadow-lg"></div>
                            <div className="flex flex-col gap-4 ml-2">
                                <h1 className="text-black-600 mt-5">Email List</h1>
                                <p>Add & Remove email address from your nightly Settlement distribution list.Those listed below do not have login access to this account unless added as a user.</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="profile overflow-hidden rounded-lg px-3 py-4 h-auto ml-4">
                    <h1 className="text-black-600 mt-2 font-bold ml-2">My Settings</h1>
                    <div className="flex flex-col mt-4 ml-5">
                        <Formik
                            initialValues={{ old: '', new: '', new_confirm: '' }}
                            validationSchema={ChangePasswordSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);
                                doChangePassword(values);
                                setSubmitting(false);
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <div className="mt-4">
                                        <TextInput
                                            type="text"
                                            name="username"
                                            placeholder="Username"
                                            label="Username"
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <TextInput
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            label="Email"
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <TextInput
                                            type="text"
                                            name="phone"
                                            placeholder="Phone"
                                            label="Phone"
                                        />
                                    </div>
                                    <div className="flex flex-row ml-5 gap-8">
                                        <button className="bg-primary text-white font-pop py-2 px-4 rounded-lg profile-button">
                                            Save
                                        </button>
                                        <button className="bg-grey text-gray-500 font-pop py-2 px-4 rounded-lg profile-button">
                                            Cancel
                                        </button>
                                    </div>
                                    <div className="mt-5">
                                        <TextInput
                                            type={showOldPassword ? "text" : "password"}
                                            name="old"
                                            id="old"
                                            placeholder="Current Password"
                                            label="Current Password"
                                            page="MyProfile"
                                            rightIcon={
                                                showOldPassword ? (
                                                    <FaEyeSlash
                                                        className="text-grey1 cursor-pointer top-10"
                                                        onClick={toggleShowOldPassword}
                                                    />
                                                ) : (
                                                    <FaEye
                                                        className="text-grey1 cursor-pointer"
                                                        onClick={toggleShowOldPassword}
                                                    />
                                                )
                                            }
                                        />
                                        <TextInput
                                            type={showPassword ? "text" : "password"}
                                            name="new"
                                            id="new"
                                            placeholder="New Password"
                                            label="New Password"
                                            page="MyProfile"
                                            rightIcon={
                                                showPassword ? (
                                                    <FaEyeSlash
                                                        className="text-grey1 cursor-pointer"
                                                        onClick={toggleShowPassword}
                                                    />
                                                ) : (
                                                    <FaEye
                                                        className="text-grey1 cursor-pointer"
                                                        onClick={toggleShowPassword}
                                                    />
                                                )
                                            }
                                        />
                                        <TextInput
                                            type={showConfirmPassword ? "text" : "password"}
                                            name="new_confirm"
                                            id="new_confirm"
                                            placeholder="Confirm Password"
                                            label="Confirm Password"
                                            page="MyProfile"
                                            rightIcon={
                                                showConfirmPassword ? (
                                                    <FaEyeSlash
                                                        className="text-grey1 cursor-pointer"
                                                        onClick={toggleShowConfirmPassword}
                                                    />
                                                ) : (
                                                    <FaEye
                                                        className="text-grey1 cursor-pointer"
                                                        onClick={toggleShowConfirmPassword}
                                                    />
                                                )
                                            }
                                        />
                                        <ul className="list-disc list-inside text-black-500">
                                            <li className="text-sm">Password must be at least 8 characters long</li>
                                            <li className="text-sm">Include at least one uppercase letter</li>
                                            <li className="text-sm">Include at least one lowercase letter</li>
                                            <li className="text-sm">Include at least one digit</li>
                                            <li className="text-sm">Include at least one special character</li>
                                        </ul>
                                    </div>
                                    <div className="flex flex-row ml-5 gap-8 mt-4">
                                        <button type="submit" className="bg-primary text-white font-pop py-2 px-4 rounded-lg profile-button" disabled={isSubmitting}>
                                            Save
                                        </button>
                                        <button type="reset" className="bg-grey text-gray-500 font-pop py-2 px-4 rounded-lg profile-button">
                                            Cancel
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyProfile;

import React from "react";
import { useField } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { max } from "moment";

const CustomDatepicker = ({
  name = "",
  placeholder,
  className,
  id,
  startDate,
  endDate,
  hanldeSetModalHeight,
}) => {
  const [field, meta, helpers] = useField(name);

  const { value } = meta;
  const { setValue } = helpers;

  const minimumDate = () => {
    if (startDate) {
      const formattedDate = moment(startDate);
      return formattedDate.add(0, "d")._d;
    }
    return new Date();
  };

  const maximumDate = () => {
    if (endDate) {
      const formattedDate = moment(endDate);
      return formattedDate.subtract(0, "d")._d;
    }
    return null;
  };

  return (
    <div className="flex flex-col	">
      <DatePicker
        {...field}
        selected={value ? new Date(value) : null}
        dateFormat="dd/MM/yyyy"
        onChange={(date) => {
          setValue(date);
          hanldeSetModalHeight && hanldeSetModalHeight(false);
        }}
        className={`border border-secondary p-2 rounded-lg placeholder-placeholderColor font-pop text-xs w-44 ${className}`}
        placeholderText={placeholder}
        autoComplete={"off"}
        minDate={minimumDate()}
        maxDate={maximumDate()}
        onFocus={() => hanldeSetModalHeight && hanldeSetModalHeight(true)}
        onBlur={() => hanldeSetModalHeight && hanldeSetModalHeight(false)}
      />
    </div>
  );
};

export default CustomDatepicker;

import React from "react";
import { useField } from "formik";
import TextField from '@mui/material/TextField';
import { FormControl } from "@mui/material";
const TextInput = (props) => {
  const { placeholder, type = "text", rightIcon, className, disabled, page } = props;
  const [field, meta, helpers] = useField(props);
  const inputCustomStyles = page === "MyProfile" ? { fontSize: '20px', height: '20px' } : {};
  const labelCustomStyles = page === "MyProfile" ? { fontSize: '13px' } : {};
 
  const handleInputChange = (e) => {
    let value = e.target.value;
 
    // Handle percentage field: strip out non-numeric characters for Formik state
    if (props.name === "vend_percentage") {
      value = value.replace(/\D/g, ""); // Only keep numeric part
    }
 
    helpers.setValue(value);
  };
 
  const displayValue = props.name === "vend_percentage" && field.value
    ? `${field.value}%` // Append `%` on display for vend_percentage
    : field.value;
 
  return (
    <div className="flex flex-col h-16 relative">
      <FormControl size="small">
        <TextField
          {...field}
          {...props}
          value={displayValue} // Show formatted value
          size="small"
          id="outlined-basic"
          variant="outlined"
          type={type}
          disabled={disabled}
          label={placeholder}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: page === "MyProfile" && rightIcon ? (
              <div style={{ paddingTop: '4px', right: '1rem', position: 'absolute', paddingLeft: '10px' }} className="flex items-center cursor-pointer">
                {rightIcon}
              </div>
            ) : null,
          }}
          sx={{
            "& .MuiInputLabel-root": {
              fontSize: '13px',
              display: 'flex',
              alignItems: 'center',
              ...labelCustomStyles,
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
            },
            "& .MuiInputBase-input": {
              fontSize: '13px',
              height: 'auto',
              padding: '8px',
              ...inputCustomStyles,
            },
            "& .MuiOutlinedInput-input": {
              padding: '8px',
            },
          }}
        />
      </FormControl>
 
      {rightIcon && page !== "MyProfile" && (
        <div className="absolute right-4 top-4 pl-3  flex items-center">
          {rightIcon}
        </div>
      )}
 
      {meta.touched && meta.error ? (
        <span className="text-danger font-pop text-xs my-1">{meta.error}</span>
      ) : null}
    </div>
  );
};
 
export default TextInput;
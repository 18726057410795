import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";
import { AuthContext } from "../../Context/AuthContext";
import { AppImages } from "../../Constants/Images";
import CustomModal from "../../Components/Modal";
import {
  AppData,
  showsMenu,
  inventoryMenu,
  reportMenu,
  allMenu,
} from "../../Constants/AppData";
import { ReactComponent as ToursIcn } from "../../Assets/h_tours.svg";
import { ReactComponent as MerchIcn } from "../../Assets/h_merch.svg";
import { ReactComponent as HandIcn } from "../../Assets/h_hand.svg";
import { CSSTransition } from "react-transition-group";
import { FaAngleDown } from "react-icons/fa";

const Header = (props) => {
  const navigate = useNavigate();
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [headerDropdownMenu, setHeaderDropdownMenu] = useState([]);
  const [submenuTimeout, setSubmenuTimeout] = useState(null);
  const [isSettingsHovered, setIsSettingsHovered] = useState(false);
  const [isLoveHovered, setIsLoveHovered] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [menuId, setMenuId] = useState();
  const [isHover, setIsHover] = useState(false);
  const [allMenuHover, setMenuHover] = useState(false);
  const [roadTitleHover, setRoadTitleHover] = useState(false);
  const [isreportTitleHover, setReportTitleHover] = useState(false);


  const dropdownRef = useRef(null);

  const handleSettingsClick = () => {
    // Handle click event for settings icon
  };

  const handleProfileClick = () => {
    navigate("/my-profile");
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setShowMegaMenu(false); // Close MegaMenu when profile dropdown is toggled
    setIsDropdownVisible(false); // Close other dropdown when profile dropdown is toggled
  };

  const handleLoveClick = () => {
    // Handle click event for love icon
  };

  const handleItemClick = (title) => {
    setClickedItem(title === clickedItem ? null : title);
  };

  const navigation = useNavigate();
  const { currentPage } = props;
  const authContext = useContext(AuthContext);

  const handleNavigation = (menuItem) => {
    setShowMegaMenu(false);
    navigation(`/${menuItem.path}`);
  };

  const goToMerch = () => {
    navigation(`/products`);
  };

  const handleToursClick = () => {
    setIsDropdownVisible(!isDropdownVisible);
    setIsOpen(false); // Close profile dropdown when inventory dropdown is toggled
    setShowMegaMenu(false); // Close MegaMenu when inventory dropdown is toggled
  };

  const handleMouseEnterSubMenu = (id) => {
    setMenuId(id);
    if (submenuTimeout) {
      clearTimeout(submenuTimeout);
    }
  };

  const handleMouseLeaveSubMenu = () => {
    // Use a timeout to delay hiding the submenu
    setSubmenuTimeout(
      setTimeout(() => {
        setShowMegaMenu(false); // Hide the mega menu
        setMenuId(null);
        setClickedItem(null); // Reset the clicked item
      }, 5000)
    ); // Adjust the delay as needed
  };

  const handleSubMenu = (id) => {
    setMenuId(id);
  };

  const handleMenuHover = (title) => {
    setClickedItem(title);
    setShowMegaMenu(true);

    if (title === "Tours") {
      setHeaderDropdownMenu(showsMenu);
    } else if (title === "Merch") {
      setHeaderDropdownMenu(inventoryMenu);
    } else if (title === "Reports") {
      setHeaderDropdownMenu(reportMenu);
    } else if (title === "All Menu") {
      setHeaderDropdownMenu(allMenu);
    }
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setShowMegaMenu(false); // Hide the mega menu
      setClickedItem(null); // Reset the clicked item
    }, 15000);
  };

  const handleMenuMouseHover = () => {
    setMenuHover(true);
  };

  const handleMenuMouseDown = () => {
    setMenuHover(false);
  };

  const handleMouseHover = () => {
    setIsHover(true);
  };
  const handleMouseDown = () => {
    setIsHover(false);
  };

  const handleRoadMouseHover = () => {
    setRoadTitleHover(true);
  };

  const handleRoadMouseDown = () => {
    setRoadTitleHover(false);
  };

  const handleReportMouseHover = () => {
    setReportTitleHover(true);
  }
  
  const handleReportMouseDown = () => {
    setReportTitleHover(false);
  }

  // const renderMenuItems = () => {
  //   if (isMobileView) {
  //     return (
  //       <div className="flex flex-col items-start gap-2">
  //         {showsMenu.map((item) => (
  //           <div className="flex flex-col" key={item.id}>
  //             <div
  //               className="h-7 w-24"
  //               onClick={item.title === "Tours" ? handleToursClick : null}
  //             >
  //               <p className="text-sm text-primary font-medium font-pop">
  //                 {item.title}
  //               </p>
  //             </div>
  //             {item.title === "Tours" && (
  //               <CSSTransition
  //                 in={isDropdownVisible}
  //                 timeout={300}
  //                 classNames="dropdown"
  //                 unmountOnExit
  //               >
  //                 <div className="flex flex-col drop">
  //                   {showsMenu
  //                     .filter((menuItem) => menuItem.title !== "Tours")
  //                     .map((menuItem) => (
  //                       <p
  //                         className="text-xs text-black font-pop my-2 w-32 cursor-pointer"
  //                         key={menuItem.id}
  //                       >
  //                         {menuItem.title}
  //                       </p>
  //                     ))}
  //                 </div>
  //               </CSSTransition>
  //             )}
  //           </div>
  //         ))}
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div
  //         className="flex flex-col items-start gap-2 dropdown"
  //         onMouseEnter={() => handleMouseEnterSubMenu(menuId)}
  //         onMouseLeave={handleMouseLeave}
  //       >
  //         {headerDropdownMenu.map((item) => (
  //           <div className="flex flex-col" key={item.id}>
  //             <div
  //               className="w-30"
  //               onClick={() => handleSubMenu(item.id)}
  //               onMouseEnter={() => handleMouseEnterSubMenu(item.id)}
  //               onMouseLeave={handleMouseLeaveSubMenu}
  //             >
  //               <p className="flex flex-row text-sm text-primary font-medium font-pop gap-2 cursor-pointer">
  //                 {item.title}
  //                 {menuId && menuId == item.id && (
  //                   <img src={AppImages.rightChevron} className="w-5 h-5" />
  //                 )}
  //               </p>
  //             </div>
  //             <div
  //               className={`${
  //                 menuId && menuId == item.id
  //                   ? "showMenu z-40 shadow-placeholderColor shadow-2xl rounded-xl mx-5"
  //                   : "hideMenu"
  //               }`}
  //             >
  //               <div className="flex flex-col">
  //                 {item.subMenu.map((subItem) => (
  //                   <a
  //                     href={`/${subItem.path}`}
  //                     className="text-xs text-black font-pop my-2 w-32 cursor-pointer "
  //                     onClick={() => handleNavigation(subItem)}
  //                     key={subItem.id}
  //                   >
  //                     {subItem.title}
  //                   </a>
  //                 ))}
  //               </div>
  //             </div>
  //           </div>
  //         ))}
  //       </div>
  //     );
  //   }
  // };

  const renderMenuItems = () => {
    if (isMobileView) {
      return (
        <div className="flex flex-col items-start gap-2">
          {showsMenu.map((item) => (
            <div className="flex flex-col" key={item.id}>
              <div
                className="h-7 w-24"
                onClick={item.title === "Tours" ? handleToursClick : null}
              >
                <p className="text-sm  font-medium font-pop">{item.title}</p>
              </div>
              {item.title === "Tours" && (
                <CSSTransition
                  in={isDropdownVisible}
                  timeout={300}
                  classNames="dropdown"
                  unmountOnExit
                >
                  <div className="flex flex-col drop">
                    {showsMenu
                      .filter((menuItem) => menuItem.title !== "Tours")
                      .map((menuItem) => (
                        <p
                          className="text-xs text-black font-pop my-2 w-32 cursor-pointer"
                          key={menuItem.id}
                        >
                          {menuItem.title}
                        </p>
                      ))}
                  </div>
                </CSSTransition>
              )}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div
          className="flex flex-row items-start gap-5 dropdown center-menu"
          onMouseEnter={() => handleMouseEnterSubMenu(menuId)}
          onMouseLeave={handleMouseLeave}
        >
          {headerDropdownMenu.map((item) => (
            <div className="flex flex-col" key={item.id}>
              <div
                className="w-30"
                onClick={() => handleSubMenu(item.id)}
                onMouseEnter={() => handleMouseEnterSubMenu(item.id)}
                onMouseLeave={handleMouseLeaveSubMenu}
              >
                <p className="flex flex-row text-sm font-pop gap-2 cursor-pointer font-bold">
                  {item.title}
                  
                  {/* {menuId && menuId == item.id && (
                    <img src={AppImages.rightChevron} className="w-5 h-5" />
                  )} */}
                </p>
              </div>
              <div className="flex flex-col">
                  {item.subMenu.map((subItem) => (
                    <a
                      href={`/${subItem.path}`}
                      className="text-xs text-black font-pop cursor-pointer "
                      style={{ marginTop: "10px" }}
                      onClick={() => handleNavigation(subItem)}
                      key={subItem.id}
                    >
                      {subItem.title}
                    </a>
                  ))}
                </div>
              {/* <div
                className={`${
                  menuId && menuId == item.id
                    ? "showMenu z-40 shadow-placeholderColor shadow-2xl rounded-xl mx-5"
                    : "hideMenu"
                }`}
              > */}
                {/* <div className="flex flex-col">
                  {item.subMenu.map((subItem) => (
                    <a
                      href={`/${subItem.path}`}
                      className="text-xs text-black font-pop my-2 w-32  cursor-pointer "
                      style={{ marginLeft: "20px" }}
                      onClick={() => handleNavigation(subItem)}
                      key={subItem.id}
                    >
                      {subItem.title}
                    </a>
                  ))}
                </div> */}
              {/* </div> */}
            </div>
          ))}
        </div>
      );
    }
  };

  const renderHeaderIcons = (item) => {
    const iconColor = item.page.includes(currentPage) ? "#1C1C1C" : "#1C1C1C";

    return (
      <div
        className="flex items-center relative roadHover"
        style={{ cursor: "pointer", width: "auto" }}
      >
        {item.title === "Tours" && (
          <div onMouseOver={handleRoadMouseHover} onMouseLeave={handleRoadMouseDown} className="flex items-center">
            <ToursIcn
              fill={iconColor}
              onClick={() => handleShowMegaMenu("Tours")}
              className="transition-transform duration-200 hover:scale-105"
            />
            {roadTitleHover && (
              <p className="font-pop text-md font-medium text-sm px-2 transition-opacity duration-200 opacity-100">
                Road
              </p>
            )} 
            
          </div>
        )}
        {item.title === "Merch" && (
          <div onMouseOver={handleMouseHover}
          onMouseLeave={handleMouseDown} className="flex items-center ">
            <MerchIcn
              fill={iconColor}
              onClick={() => handleShowMegaMenu("Merch")}
              className="transition-transform duration-200 hover:scale-105"
            />
            {isHover && (
              <p className="font-pop text-md font-medium text-sm px-2 transition-opacity duration-200 opacity-100">
               Office
              </p>
            )}
             
          </div>
        )}
        {item.title === "Reports" && (
          <div onMouseOver={handleReportMouseHover} onMouseLeave={handleReportMouseDown} className="flex items-center">
          <HandIcn
            fill={iconColor}
            onClick={() => handleShowMegaMenu("Reports")}
            className="transition-transform duration-200 hover:scale-105"
          />
          {isreportTitleHover && (
            <p className="font-pop text-md font-medium text-sm px-2 transition-opacity duration-200 opacity-100">
             Reports
            </p>
          )}
          </div>
        )}
        {item.title === "All Menu" && (
          <div
            onMouseOver={handleMenuMouseHover}
            onMouseLeave={handleMenuMouseDown}
            className="flex items-center"
          >
            <FaAngleDown
              size={20}
              color={iconColor}
              onClick={() => handleShowMegaMenu("All Menu")}
              className="transition-transform duration-200 hover:scale-105"
            /> 
            {allMenuHover && (
              <p className="font-pop text-md font-medium text-sm px-2 transition-opacity duration-200 opacity-100">
               All
              </p>
            )}
            
          </div>
        )}
      </div>
    );
  };

  const handleShowMegaMenu = (title) => {
    if (title === clickedItem) {
      setShowMegaMenu(false);
      setClickedItem(null);
    } else {
      setShowMegaMenu(true);
      setHeaderDropdownMenu([]);
      if (title === "Tours") {
        setHeaderDropdownMenu(showsMenu);
      } else if (title === "Merch") {
        setHeaderDropdownMenu(inventoryMenu);
      } else if (title === "Reports") {
        setHeaderDropdownMenu(reportMenu);
      } else if (title === "All Menu") {
        setHeaderDropdownMenu(allMenu);
      }
      setIsOpen(false);
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowMegaMenu(false);
        setIsOpen(false);
        setIsDropdownVisible(false);
      }
    };

    window.addEventListener("resize", handleResize);
    document.addEventListener("mousedown", handleClickOutside);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="bg-white px-6 py-3 first-header main-heading"
      ref={dropdownRef}
    >
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row gap-6">
          {AppData.headerMenu.map((item) => (
            <div
              key={item.title}
              className={`flex justify-center items-center px-3 py-2 rounded-lg cursor-pointer merch-icons hoverable-button ${
                item.page.includes(currentPage) ? "bg-white" : "bg-white"
              } ${clickedItem === item.title ? "zoom-in" : ""}`}
              // onClick={() => {
              //   handleItemClick(item.title);
              //   handleShowMegaMenu(item.title);
              // }}

              onMouseEnter={() => handleMenuHover(item.title)}
              onMouseLeave={handleMouseLeave}
            >
              {renderHeaderIcons(item)}
              {item.page.includes(currentPage) && (
                <p className="font-pop text-primary text-md font-medium text-sm">
                  {/* {item.title}  */}
                </p>
              )}
            </div>
          ))}
          {showMegaMenu &&
            clickedItem !== "All Menu" &&
            clickedItem !== "Merch" &&
            clickedItem !== "Reports" && (
              <div
                className="absolute top-20 lg:top-20 bg-white z-40 shadow-placeholderColor shadow-2xl rounded-xl px-6 py-6 mainMenu"
                onMouseLeave={handleMouseLeave}
              >
                <div className="flex flex-row gap-0 mx-2">
                  {renderMenuItems()}
                </div>
              </div>
            )}

          {showMegaMenu && clickedItem === "Merch" && (
            <div
              className="absolute bg-white z-40 shadow-placeholderColor shadow-2xl rounded-xl px-12 py-6 right-menu" // Adjust styles for right display
              style={{ marginLeft: "80px", marginTop: "50px" }}
              onMouseLeave={handleMouseLeave}
            >
              <div className="flex flex-row gap-0 mx-2">
                {renderMenuItems()}
              </div>
            </div>
          )}
          {showMegaMenu && clickedItem === "Reports" && (
            <div
              className="absolute bg-white z-40 shadow-placeholderColor shadow-2xl rounded-xl px-12 py-6 right-menu" // Adjust styles for right display
              style={{ marginLeft: "250px", marginTop: "50px" }}
              onMouseLeave={handleMouseLeave}
            >
              <div className="flex flex-row gap-0 mx-2">
                {renderMenuItems()}
              </div>
            </div>
          )}

          {showMegaMenu && clickedItem === "All Menu" && (
            <div
              className="absolute bg-white z-40 shadow-placeholderColor shadow-2xl rounded-xl px-12 py-6 center-menu"
              style={{ marginLeft: "320px", marginTop: "50px" }}
              onMouseLeave={handleMouseLeave}
            >
              <div className="flex flex-row gap-0 mx-2">
                {renderMenuItems()}
              </div>
            </div>
          )}
        </div>

        <div className="flex items-center ml-auto">
          <div className="flex items-center">
            <SearchBar />
          </div>
          <div
            className={`flex items-center ${isMobileView ? "hidden" : "flex"}`}
            style={{ marginLeft: 10 }}
          >
            <div
              className="flex justify-center items-center bg-white h-10 w-10 rounded-full transform transition-transform duration-300 hoverable-button header-icons"
              onMouseEnter={() => setIsSettingsHovered(true)}
              onMouseLeave={() => setIsSettingsHovered(false)}
              onClick={handleSettingsClick}
              style={{
                transform: isSettingsHovered ? "scale(1.2)" : "scale(1)",
                marginRight: "10px",
              }}
            >
              <img
                src="/settings.png"
                className="w-4 h-4 object-contain"
                style={{
                  filter: isSettingsHovered
                    ? "brightness(0.8)"
                    : "brightness(1)",
                }}
              />
            </div>
            <div
              className="flex justify-center items-center bg-white h-10 w-10 rounded-full transform transition-transform duration-300 hoverable-button header-icons"
              onMouseEnter={() => setIsLoveHovered(true)}
              onMouseLeave={() => setIsLoveHovered(false)}
              onClick={handleLoveClick}
              style={{
                transform: isLoveHovered ? "scale(1.2)" : "scale(1)",
                marginRight: "10px",
              }}
            >
              <img
                src="/love.png"
                className="w-3 h-3 object-contain"
                style={{
                  filter: isLoveHovered ? "brightness(0.8)" : "brightness(1)",
                }}
              />
              <span className="absolute ml-10 w-5 h-5 text-center rounded-full p-1 bg-secondary text-white text-[9px] font-pop">
                4
              </span>
            </div>
          </div>
          <div className="relative ml-6">
            <CSSTransition
              in={isOpen}
              timeout={300}
              classNames="dropdown"
              unmountOnExit
            >
              <div className="absolute right-0 mt-4 top-16 w-48 bg-white rounded-lg shadow-lg z-10">
                <div className="py-1">
                  <button
                    onClick={handleProfileClick}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-primary hover:text-white"
                  >
                    My Profile
                  </button>
                  {isMobileView && (
                    <React.Fragment>
                      <button
                        onClick={handleSettingsClick}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-primary hover:text-white"
                      >
                        Settings
                      </button>
                      <button
                        onClick={handleLoveClick}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-primary hover:text-white"
                      >
                        Wishlist
                      </button>
                    </React.Fragment>
                  )}
                  <button
                    onClick={authContext.doLogout}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-primary hover:text-white"
                  >
                    Logout
                  </button>
                </div>
              </div>
            </CSSTransition>
            <div
              className="flex justify-center items-center bg-white h-10 w-10 rounded-full cursor-pointer top-16"
              onClick={toggleDropdown}
            >
              <img
                src="/profile.png"
                alt="Profile"
                className="w-10 h-10 object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

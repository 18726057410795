import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import Shows from "../Pages/Home/Shows";
import Products from "../Pages/Home/Products";
import Login from "../Pages/Authentication/Login";
import ProtectedRoutes from "../Navigation/ProtectedRoutes";
import Tours from "../Pages/Home/Tours";
import NotFound from "../Pages/Common/NotFound";
import Register from "../Pages/Authentication/Register";
import Myprofile from '../Pages/Authentication/Myprofile';
import ForgotPassword from "../Pages/Authentication/ForgotPassword";
import ResetPassword from '../Pages/Authentication/ResetPassword'; 
import ChangePassword from '../Pages/Authentication/ChangePassword';
import Warehouse from "../Pages/Home/Warehouse";
import RadialMenu from "../Pages/Home/RadialMenu";
import ModalCustom from "../Pages/Home/ModalCustom";
import ModalCustomWarehouse from "../Pages/Home/ModalCustomWarehouse";
import ModalCustomProducts from "../Pages/Home/ModalCustomProducts";
import ModalCustomTrailers from "../Pages/Home/ModalCustomTrailers";
import Trailer from '../Pages/Home/Trailers';
import ShowsCounts from "../Pages/Home/Shows/ShowsCounts";
import ScrollButton from "../Pages/Home/ScrollButton";
import Shipments from "../Pages/Home/Shipments";
import Orders from "../Pages/Home/Orders";
import ViewPage from "../Pages/Home/Shipments/ViewPage";
import EditPage from "../Pages/Home/Shipments/EditPage";
import EditOrder from "../Pages/Home/Orders/EditOrder";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route element={<ProtectedRoutes />}>
        <Route element={<Tours />} path="/" />
        <Route element={<Shows />} path="/shows">
          <Route path=":tourId/:status" />
          <Route path=":tourId" />
          <Route path=":status" />
        </Route>
        <Route element={<ShowsCounts />} path="/shows/:tourId/count" />
        <Route element={<Products />} path="/products" />
        <Route element={<RadialMenu />} path="/RadialMenu" />
        <Route element={<ScrollButton />} path="/ScrollButton" />
        <Route element={<ModalCustom />} path="/ModalCustom" />
        <Route element={<ModalCustomWarehouse />} path="/ModalCustomWarehouse" />
        <Route element={<ModalCustomProducts />} path="/ModalCustomProducts" />
        <Route element={<ModalCustomTrailers />} path="/ModalCustomTrailers" />
        <Route element={<Warehouse />} path="/warehouses" />
        <Route element={<Shipments />} path="/shipment" />
        <Route element={<Orders />} path="/orders" />
         <Route element={<ViewPage/>} path="/ViewPage" /> 
         <Route element={<EditPage/>} path="/EditPage" /> 
         <Route element={<EditOrder/>} path="/editorder" /> 
        '<Route element={<Trailer />} path="/trailers" />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:code" element={<ResetPassword />} />
      <Route path="/my-profile" element={<Myprofile />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

export default router;

import React, { useState, useContext, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { ClipLoader } from "react-spinners";

import loginBg from "../../../Assets/login_bg.svg";
import TextInput from "../../../Components/TextInput";
import CustomButton from "../../../Components/CustomButton";
import { RegisterSchema } from "../../../Utilites/Validation";
import { AuthContext } from "../../../Context/AuthContext";
import { Navigate } from "react-router-dom";
import { ApiUrl } from "../../../Constants/Api";
import { apiCall } from "../../../Utilites/Api";
import { toast } from "react-toastify";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const loginContext = useContext(AuthContext);
  const navigate = useNavigate();

  const handleShowPassword = () => {
    setShowPassword(false);
  };

  const handleHidePassword = () => {
    setShowPassword(true);
  };

  const doUserRegister = async (payload) => {
    const resp = await apiCall(ApiUrl.register(), payload);
    if (resp.status) {
      toast.success(resp.message);
      navigate("/login");
    } else {
      toast.error(resp.message);
    }
  };

  useEffect(() => {
    if (loginContext.accessToken) {
      navigate("/shows");
    }
  }, [loginContext.accessToken]);

  return (
    <>
      <div className="flex flex-row-col bg-white">
        <div className="w-1/2 flex items-start hidden-on-mobile">
          <img
            src={loginBg}
            alt="Login background image"
            className="w-full object-contain h-screen"
          />
        </div>
        <div className="flex flex-col justify-center items-center bg-primary w-full md:w-1/2 form-mobile-fullscreen">
          <div className="bg-primary rounded-lg p-10 w-full max-w-md">
          <p className="font-pop text-4xl font-bold text-white text-center my-1">
            Register Now!
          </p>
          <p className="font-pop text-sm text-white text-center my-1">
            Please enter your details
          </p>
          <Formik
            initialValues={{ name: "", email: "", mobile: "", password: "" }}
            validationSchema={RegisterSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              doUserRegister(values);
            }}
          >
            {({
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="bg-white rounded-xl my-8 p-16 flex flex-col gap-4 justify-center items-center">
                  <div className="relative">
                    <TextInput
                      type="text"
                      name="name"
                      placeholder="Name"
                      rightIcon={null}
                    />
                  </div>
                  <div className="relative">
                    <TextInput
                      type="text"
                      name="mobile"
                      placeholder="Mobile number"
                      rightIcon={null}
                    />
                  </div>
                  <div className="relative">
                    <TextInput
                      type="text"
                      name="email"
                      placeholder="Email address"
                      rightIcon={null}
                    />
                  </div>
                  <div className="relative">
                    <TextInput
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      rightIcon={
                        showPassword ? (
                          <FaEyeSlash
                            className="text-grey1"
                            onClick={handleShowPassword}
                          />
                        ) : (
                          <FaEye
                            className="text-grey1"
                            onClick={handleHidePassword}
                          />
                        )
                      }
                    />
                  </div>
                  <CustomButton
                    className="bg-primary w-5/12"
                    title="Sign up"
                    disabled={isSubmitting}
                    type={"submit"}
                  />
                </div>
              </Form>
            )}
          </Formik>
          <p className="font-pop text-white cursor-pointer text-center" onClick={() => navigate('/login')}>Already have an account? Sign In</p>
        </div>
        </div>
      </div>
    </>
  );
};

export default Login;

import React, { useState } from 'react';
import withHoc from "../../../../Components/Hoc";
import { useNavigate } from 'react-router-dom';
import { AppData } from "../../../../Constants/AppData";
import EditPage from "../EditPage";
import { AppImages } from "../../../../Constants/Images";


const ViewPage = () => {
  const navigate = useNavigate();
  const handleEditClick = () => {
    navigate('/EditPage');
  };

 
  return (
    <div className="flex justify-center mt-5">
      <div className="view-card  mx-3 rounded-lg p-4 bg-white shadow">
        <div className="flex flex-row mt-10 ml-10">
        <h1 className="text-xl font-pop mr-20 text-primary">Shipments</h1>
           <h2 className="mr-20">Status: <span className="font-pop">Completed</span></h2>
           <p className="mr-10">From: Default Warehouse</p>
           <p className="mr-10">To: First Trailer</p>
           <button onClick={handleEditClick} className="font-pop text-xs text-white text-center ml-20 mr-5 font-medium edit-button bg-primary rounded-xl">Edit</button>
           <div className="flex justify-center  w-7 h-7  border border-secondary rounded-md cursor-pointer hoverable-button mr-5">
              <img src={AppImages.pdf} className="w-3 h-3" />
           </div>                               
        </div>
        <hr  className="mt-4 hrclr"/>
        <div className="flex flex-row ">
          <div className=" p-5 ml-10" style={{width:"50%"}}>
             <h1 className="text-secondary mb-5">Shipped Date</h1>   
              <div className="flex flex-row ">
                  <p className="text-secondary pr-10">From</p>
                  <ul className="mb-5">
                    <li>Default Warehouse</li>
                    <li>United States</li>
                  </ul>
              </div>
              <p className="text-secondary mb-5">Contact</p>
              <p className="text-secondary mb-5">Phone</p>
              <p className="text-secondary mb-5" >Email</p>
              <div className="flex flex-row " >
              <p className="text-secondary mb-5 mr-10">Shipper</p>
              <p className="text-secondary mb-5 ml-10">Act No:</p>
              </div>
              <p className="text-secondary mb-5">Tracking <br/> Number</p>
              <p className="text-secondary mb-5">Shipper Notes </p>
          </div>
          <div className=" p-5" style={{width:"50%"}}>
             <h1 className="text-secondary mb-5" >Delivery Date</h1>
             <div className="flex flex-row ">
                  <p className="text-secondary pr-10">From</p>
                  <ul className="mb-5">
                    <li>First Trailer</li>
                    <li>United States</li>
                  </ul>
              </div>
              <p className="text-secondary mb-5">Contact</p>
              <p className="text-secondary mb-5">Phone</p>
              <p className="text-secondary mb-5" >Email</p>
              <p className="text-secondary mb-5 mr-10">Shipping Cost</p>
              <p className="text-secondary mb-5">Apply Cost To </p>
          </div>
          </div>
          <hr  className="mt-4 hrclr"/> 
          <div className="flex flex-row">
            <div  className="p-5 ml-10" style={{width:"40%"}}>
              <h1 className="font-pop">Blue T-shirt</h1>
              <div className="flex flex-row">
              <div className="flex flex-col justify-center">
              <img src="https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg" alt="Preview" className="w-24 h-24 z-10 mt-10 mb-5 rounded mr-5"/>
                    </div>
                <ul>
                  <li className="pt-5 pb-2">Type</li>
                  <li className="pb-2">Colour</li>
                  <li className="pb-2">Sex</li>
                  <li className="pb-2 pr-2">Reorder</li>
                  <li className="pb-2">Lead</li>
                  <li className="pb-2">For</li>
                </ul>
                <ul>
                  <li className="pt-5 pb-2 text-secondary">1/4 Zip Pullover</li>
                  <li className="pb-2 text-secondary">Baby Blue</li>
                  <li className="pb-2 text-secondary">Male</li>
                  <li className="pb-2 text-secondary">Priority</li>
                  <li className="pb-5 mb-2 text-secondary"></li>
                  <li className="text-secondary">H</li>
                </ul>

              </div>
            </div>
            <div  className="p-5 ml-10" style={{width:"60%"}} >
              <div className="flex flex-col"> 
               <ul className="text-secondary font-pop flex flex-row">
                <li className="pr-10 mb-5">SKU</li>
                <li className="pr-10 mb-5">Size</li>
                <li className="pr-10 mb-5">Shipped</li>
                <li className="pr-10 mb-5">Received</li>
                <li className="pr-9 mb-5">Short/Over</li>
                <li className="pr-10 mb-5">Short/Over $</li>
               </ul>
               <ul className="text-secondary font-pop mr-10 flex flex-row">
                <li className="pr-11 mb-5 mr-3">123</li>
                <li className="pr-20 mb-5">XS</li>
                <li className="pr-20 mb-5">10</li>
                <li className="pr-20 mb-5 mr-5">10</li>
                <li className="pr-20 mb-5 mr-10">0</li>
                <li className="pr-20 mb-5">0.0</li>
               </ul> 
               <ul className="text-secondary font-pop flex flex-row">
                <li className="pr-11 mr-3"></li>
                <li className="pr-20">Total</li>
                <li className="pr-20">10</li>
                <li className="pr-20 mr-7">0</li>
                <li className="pr-20 mr-10">0</li>
                <li className="pr-10">0</li>
               </ul>
               </div>
            </div>
          </div>
          <hr className="hrclr"/> 
          
          <ul className="text-secondary font-pop flex flex-row" style={{justifyContent:"flex-end"}}>
                <li className="pr-11 mr-3"></li>
                <li className="pr-20">Total</li>
                <li className="pr-20">10</li>
                <li className="pr-20 mr-7">0</li>
                <li className="pr-20 mr-8">0</li>
                <li className="pr-10 mr-20">0</li>
          </ul>
      </div>
    </div>
            
  
  );
};
  
//export default ViewPage;

const secondaryMenu = AppData.productsSecondaryMenu;
 export default withHoc(ViewPage, { secondaryMenu, page: "ViewPage" });

import React, { useState } from 'react';
import withHoc from "../../../Components/Hoc";
import { useNavigate } from 'react-router-dom';
import { AppData } from "../../../Constants/AppData";
import { Field, Formik } from "formik";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const OrdersPage = () => {
  const [showStatusCheckboxes, setShowStatusCheckboxes] = useState(false);
  const [statusCheckboxes, setStatusCheckboxes] = useState({
    pending: true,
    toBeApproved: true,
    approved: true,
    placed: true,
    completed: true,
  });
  const navigate = useNavigate();

  const toggleStatusCheckboxes = () => {
    setShowStatusCheckboxes(!showStatusCheckboxes);
  };

  const handleClearClick = () => {
    setStatusCheckboxes({
      pending: false,
      toBeApproved: false,
      approved: false,
      placed: false,
      completed: false,
    });
  };

  const handleEditOrderClick = () => {
    navigate('/editorder');
  };

  return (
    <Formik
      initialValues={{
        statusCheckboxes,
      }}
      enableReinitialize
    >
      {({ values, setFieldValue }) => (
        <div className="flex mt-5">
          <div className="shippment-card w-full mx-3 rounded-lg p-4 bg-white shadow">
            <table className="w-full mt-3" cellPadding={20}>
              <thead className="shadow-md bg-black table-th table-header">
                <tr className="text-white font-medium rounded-xl shippment-table">
                  <th>
                    <p className="text-secondary font-sm">
                      Status
                    </p>
                  </th>
                  <th>
                    <p className="text-secondary font-sm">
                      Created On
                    </p>
                  </th>
                  <th>
                    <p className="text-secondary font-sm">
                      Items
                    </p>
                  </th>
                  <th>
                    
                  </th>
                </tr>
              </thead>
              <tbody className="shadow-md">
                <tr className="font-pop text-xs text-secondary text-center font-medium">
                  <td>Pending</td>
                  <td>2024-19-06</td>
                  <td>Black T-shirt</td>
                  <td>
                    <button onClick={handleEditOrderClick} className="font-pop text-xs text-secondary text-center font-medium view-button bg-grey rounded-xl" style={{width:"100px"}}>Edit & Order</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="shippment-card w-80 mx-3 rounded-lg p-4 bg-white shadow">
            <h1 className="text-black font-pop rounded-lg shippment-filter">Filter</h1>
            <div className="mt-3">
              <h6 onClick={toggleStatusCheckboxes} className="cursor-pointer flex justify-between mb-3 ">
                <span>Status</span>
                {showStatusCheckboxes ? <FaChevronUp className="mr-5" /> : <FaChevronDown className="mr-5" />}
              </h6>
              {showStatusCheckboxes && (
                <div>
                  <div className="ml-2 mb-15">
                    {Object.keys(statusCheckboxes).map(status => (
                      <div key={status}>
                        <Field
                          type="checkbox"
                          id={status}
                          name={`statusCheckboxes.${status}`}
                          checked={values.statusCheckboxes[status]}
                          onChange={(e) => {
                            setFieldValue(`statusCheckboxes.${status}`, e.target.checked);
                            setStatusCheckboxes(prevState => ({
                              ...prevState,
                              [status]: e.target.checked,
                            }));
                          }}
                          className="mr-2 mb-3"
                        />
                        <label htmlFor={status}>{status.charAt(0).toUpperCase() + status.slice(1)}</label><br />
                      </div>
                    ))}
                  </div>
                  <div className="mt-3">
                    <button onClick={handleClearClick} className="px-4 py-2 bg-primary text-white rounded-lg ml-20">Clear</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

const secondaryMenu = AppData.productsSecondaryMenu;
export default withHoc(OrdersPage, { secondaryMenu, page: "OrdersPage" });

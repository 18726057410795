import React, { useState } from "react";
import { Formik, Form, Field, useFormikContext } from "formik";

import { AppImages } from "../../../../Constants/Images";
import { ProductsSchema } from "../../../../Utilites/Validation";
import TextInput from "../../../../Components/TextInput";
import CustomSelectPicker from "../../../../Components/CustomSelect";
import CustomDatepicker from "../../../../Components/CustomDatePicker";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const ProductsForm = (props) => {
  const {
    handleModalClose,
    modalProps,
    addMerchData,
    handleSetAddExpandModal,
    merchSizes,
    handleAddProduct,
  } = props;

  const [showEndDate, setShowEndDate] = useState(false);

  const [formStep, setFormStep] = useState(1);

  const [imageOne, setImageOne] = useState(null);

  const [image_1, setImage_1] = useState(null);

  const [image_2, setImage_2] = useState(null);

  const [imageTwo, setImageTwo] = useState(null);

  const [productTypes, setProductTypes] = useState([]);

  const [category, setCategory] = useState("");

  const [sizes, setSizes] = useState(merchSizes?.size_types || []);

  const [showSizeSelection, setShowSizeSelection] = useState(false);

  const [skuInputFeilds, setSkuInputFeilds] = useState([]);

  const { sub_categories, colours, gender, country } = addMerchData;

  const handleShowEndDate = () => {
    setShowEndDate(!showEndDate);
  };

  const getFormttedSubCategoriesData = (data) => {
    const formattedList =
      data &&
      data.length > 0 &&
      data.map((item) => {
        return {
          ...item,
          label: `${item.name}`,
          value: `${item.id}`,
        };
      });
    return formattedList;
  };

  const getFormttedColorsData = (data) => {
    const formattedList =
      data &&
      data.length > 0 &&
      data.map((item) => {
        return {
          ...item,
          label: `${item.colour_name}`,
          value: `${item.id}`,
        };
      });
    return formattedList;
  };

  const getFormttedGenderData = (data) => {
    const formattedList =
      data &&
      data.length > 0 &&
      data.map((item) => {
        return {
          ...item,
          label: `${item}`,
          value: `${item}`,
        };
      });
    return formattedList;
  };

  const getFormttedCountryData = (data) => {
    const formattedList =
      data &&
      data.length > 0 &&
      data.map((item) => {
        return {
          ...item,
          label: `${item.countryname}`,
          value: `${item.id}`,
        };
      });
    return formattedList;
  };

  const getProductTypes = (categoryId) => {
    setProductTypes([]);
    const productTypesArr = sub_categories.filter(
      (item) => item.id == categoryId
    );
    const formattedProductTypes = productTypesArr[0].sub_cateogire_list.map(
      (item) => ({
        ...item,
        label: `${item.name}`,
        value: `${item.id}`,
      })
    );
    setProductTypes(formattedProductTypes);
    setCategory(productTypesArr[0].name);
  };

  const handleSetSizeTypes = (sizeType, subSizeType) => {
    const tempSizes = sizes.map((item) => {
      if (item.id === sizeType.id && item?.isActive === true) {
        return { ...item, isActive: false };
      } else if (item.id === sizeType.id) {
        return { ...item, isActive: true };
      } else {
        return { ...item };
      }
    });
    setSizes(tempSizes);
  };

  const handleSetSelectedSubType = ({ ...subSizeTypeItem }) => {
    const tempskuStockInputFeilds = [...skuInputFeilds];
    const isSubItemAlreadyExists = tempskuStockInputFeilds.some(
      (subSizeItem) =>
        JSON.stringify(subSizeItem) === JSON.stringify(subSizeTypeItem)
    );
    if (!isSubItemAlreadyExists) {
      subSizeTypeItem.sku_code = "";
      subSizeTypeItem.product_code = "";
      subSizeTypeItem.sale_price = "";
      subSizeTypeItem.cost = "";
      tempskuStockInputFeilds.push(subSizeTypeItem);
      setSkuInputFeilds(tempskuStockInputFeilds);
    }
  };

  const isSubSizeSelected = (subSize) => {
    const filterSubSizes = skuInputFeilds.filter(
      (item) => item.size_name == subSize.size_name
    );
    return filterSubSizes.length > 0 ? true : false;
  };

  const renderSkuForm = () => {
    const handleSkuFormChange = (index, event) => {
      const tempSkuInputFeilds = [...skuInputFeilds];
      tempSkuInputFeilds[index][event.target.name] = event.target.value;
      setSkuInputFeilds(tempSkuInputFeilds);
    };

    const handleRemoveSkuInput = (index) => {
      const tempSkuInputFeilds = [...skuInputFeilds];
      tempSkuInputFeilds.splice(index, 1);
      setSkuInputFeilds(tempSkuInputFeilds);
    };
    skuInputFeilds.sort(function (item, i) {
      return item.id - i.id;
    });
    return (
      <>
        <div className="border my-4" />
        {skuInputFeilds &&
          skuInputFeilds.length > 0 &&
          skuInputFeilds.map((skuItem, index) => (
            <div className="flex flex-row items-center justify-between">
              <div className="my-2">
                <input
                  type="text"
                  className={` border border-placeholderColor p-2.5 w-20 rounded-lg placeholder-placeholderColor font-pop text-xs`}
                  disabled={true}
                  value={skuItem.size_name}
                />
              </div>
              <div className="my-2">
                <input
                  type="text"
                  name="sku_code"
                  className={` border border-placeholderColor p-2.5 w-32 rounded-lg placeholder-placeholderColor font-pop text-xs`}
                  placeholder="Sku code"
                  value={skuItem.sku_code}
                  onChange={(event) => handleSkuFormChange(index, event)}
                />
              </div>
              <div className="my-2">
                <input
                  type="text"
                  className={` border border-placeholderColor p-2.5 w-32 rounded-lg placeholder-placeholderColor font-pop text-xs`}
                  placeholder="Product code"
                  name="product_code"
                  value={skuItem.product_code}
                  onChange={(event) => handleSkuFormChange(index, event)}
                />
              </div>
              <div className="my-2 relative z-20">
                <input
                  type="text"
                  className={` border border-placeholderColor p-2.5 w-32 rounded-lg placeholder-placeholderColor font-pop text-xs`}
                  placeholder="Sale price"
                  name="sale_price"
                  value={skuItem.sale_price}
                  onChange={(event) => handleSkuFormChange(index, event)}
                />
              </div>
              <div className="my-2">
                <input
                  type="text"
                  className={` border border-placeholderColor p-2.5 w-32 rounded-lg placeholder-placeholderColor font-pop text-xs`}
                  placeholder="Cost"
                  name="cost"
                  value={skuItem.cost}
                  onChange={(event) => handleSkuFormChange(index, event)}
                />
              </div>
              <button
                type="button"
                className="flex justify-center items-center  bg-grey h-8 w-8 rounded-lg shadow-lg"
                onClick={() => handleRemoveSkuInput(index)}
              >
                <img src={AppImages.cancelBlack} className="w-3 h-3" />
              </button>
            </div>
          ))}
      </>
    );
  };

  const renderFeildBasedOnCategory = (
    category,
    errors,
    touched,
    isPreOrder
  ) => {
    const renderSku = () => {
      return (
        <div className="flex flex-row gap-2 items-center">
          <TextInput
            type="text"
            name="sku"
            placeholder="SKU"
            rightIcon={null}
            className="p-2.5 w-52 border-placeholderColor"
          />
          <TextField
            type="text"
            name="sale_price"
            placeholder="Sale Price"
            className="p-2.5 w-40 border-placeholderColor"
            InputProps={{
              startAdornment: <InputAdornment position="end">$</InputAdornment>,
            }}
            sx={{
              ".MuiInputBase-root": {
                height: "38px",
                marginTop: "-23px",
                borderRadius: "10px",
                "& .MuiInputBase-input": {
                  padding: 0, // Adjust padding to ensure the text fits well
                },
              },
            }}
          />
          <TextField
            type="text"
            name="cost"
            placeholder="Cost"
            variant="outlined"
            className="w-40  border-placeholderColor "
            InputProps={{
              startAdornment: <InputAdornment position="end">$</InputAdornment>,
            }}
            sx={{
              ".MuiInputBase-root": {
                height: "38px",
                marginTop: "-23px",
                borderRadius: "10px",
                "& .MuiInputBase-input": {
                  padding: 0, // Adjust padding to ensure the text fits well
                },
              },
            }}
          />
        </div>
      );
    };

    const renderProductSizes = () => {
      return (
        <>
          <div className="flex flex-row flex-wrap gap-2">
            {sizes &&
              sizes.map((item) => (
                <>
                  <div
                    key={item.id}
                    className={`flex justify-center items-center  rounded-lg shadow-lg ${
                      item?.isActive
                        ? "bg-primary border border-white"
                        : "bg-white border border-primary"
                    } cursor-pointer`}
                    onClick={() => handleSetSizeTypes(item)}
                  >
                    <p
                      className={`font-pop text-xs  px-3 py-1 ${
                        item?.isActive ? "text-white" : "text-primary"
                      }`}
                    >
                      {item.size_type}
                    </p>
                  </div>
                </>
              ))}
          </div>
          {sizes &&
            sizes?.length > 0 &&
            sizes?.map(
              (subSizeItem, index) =>
                subSizeItem.isActive && (
                  <div
                    className={`bg-grey flex flex-row gap-4 flex-wrap p-4 items-center rounded-lg shadow-lg ${
                      index === 0 ? "mt-5 my-3" : "my-3"
                    } `}
                  >
                    {subSizeItem?.size_list &&
                      subSizeItem?.size_list?.length > 0 &&
                      subSizeItem?.size_list.map((subType) => (
                        <p
                          className={`text-primary font-pop cursor-pointer w-20  ${
                            isSubSizeSelected(subType)
                              ? "font-semibold text-sm"
                              : "text-xs"
                          }`}
                          key={subType.id}
                          onClick={() => handleSetSelectedSubType(subType)}
                        >
                          {subType.size_name}
                        </p>
                      ))}
                  </div>
                )
            )}

          {renderSkuForm()}
        </>
      );
    };

    return category === "Apparel" ? (
      <>
        <div className="flex flex-row gap-2">
          <div className="h-16">
            <CustomSelectPicker
              isMulti={false}
              placeHolder={"Gender"}
              data={getFormttedGenderData(gender)}
              name={"gender"}
              className="px-2.5 w-44 border-placeholderColor"
            />
            <span className="text-danger text-sm font-pop text-xs">
              {errors.gender && touched.gender && errors.gender}
            </span>
          </div>
          <div className="h-16 relative z-40">
            <CustomSelectPicker
              isMulti={false}
              placeHolder={"Colors"}
              data={getFormttedColorsData(colours)}
              name={"colour"}
              className="px-2.5 w-44 border-placeholderColor"
            />
            <span className="text-danger text-sm font-pop text-xs">
              {errors.colour && touched.colour && errors.colour}
            </span>
          </div>
          <div
            className="flex flex-row justify-center items-center bg-primary w-40 h-9 mt-0.5 rounded-lg gap-2 cursor-pointer"
            onClick={() => setShowSizeSelection(true)}
          >
            <img src={"/plus.png"} className="w-3 h-3 object-contain" />
            <p className="font-pop text-xs text-white">Add Sizes</p>
          </div>
        </div>
        {showSizeSelection && renderProductSizes()}
      </>
    ) : category === "Others" ? (
      <>
        <div className="flex flex-row gap-2">
          <div className="h-16">
            <CustomSelectPicker
              isMulti={false}
              placeHolder={"Gender"}
              data={getFormttedGenderData(gender)}
              name={"gender"}
              className="px-2.5 w-44 border-placeholderColor"
            />
            <span className="text-danger text-sm font-pop text-xs">
              {errors.gender && touched.gender && errors.gender}
            </span>
          </div>
          <div className="h-16 relative z-40">
            <CustomSelectPicker
              isMulti={false}
              placeHolder={"Colors"}
              data={getFormttedColorsData(colours)}
              name={"color"}
              className="px-2.5 w-44 border-placeholderColor"
            />
            <span className="text-danger text-sm font-pop text-xs">
              {errors.color && touched.color && errors.color}
            </span>
          </div>
        </div>
        {renderSku()}
      </>
    ) : category === "Music" ? (
      <>
        <div className="flex flex-row gap-2">
          <TextInput
            type="text"
            name="upc_1"
            placeholder="UPC1"
            rightIcon={null}
            className="p-2.5 w-56 border-placeholderColor"
          />
          <TextInput
            type="text"
            name="upc_2"
            placeholder="UPC2"
            rightIcon={null}
            className="p-2.5 w-56 border-placeholderColor"
          />
          <div className="my-3">
            <p className="text-secondary font-medium text-xs">
              <Field type="checkbox" name="pre_order" /> Pre-Order{" "}
            </p>
          </div>
        </div>
        {isPreOrder && (
          <div className="flex flex-row gap-2">
            <div className="h-16">
              <CustomDatepicker
                name="release_date"
                className="p-2.5 w-60 border-placeholderColor"
                placeholder="Release date"
                id="release_date"
                hanldeSetModalHeight={handleSetAddExpandModal}
              />
              {touched.release_date && errors.release_date ? (
                <span className="text-danger font-pop text-xs">
                  {errors.release_date}
                </span>
              ) : null}
            </div>
            <TextInput
              type="text"
              name="record_rep_mail"
              placeholder="Record Rep Contact Email"
              rightIcon={null}
              className="p-2.5 w-56 border-placeholderColor"
            />
          </div>
        )}
        {renderSku()}
      </>
    ) : null;
  };

  const renderProductsForm = (errors, touched, isPreOrder) => {
    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          console.log("called: ", fileReader.result.split(",")[1]);
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          console.log(error);
        };
      });
    };

    const handleImageUpload = async (event) => {
      const file = event.target.files[0];
      const base64 = await convertToBase64(file);
      if (imageOne != null) {
        setImageTwo(base64.split(",")[1]);
        setImage_2(base64);
      } else {
        setImageOne(base64.split(",")[1]);
        setImage_1(base64);
      }
    };

    return (
      <div className="flex flex-row gap-5 items-center">
        <div className="relative">
          <div className="flex flex-col gap-2 mt-2">
            {!imageOne && (
              <label
                htmlFor="fileInput1"
                className="flex flex-col items-center justify-between w-24 h-28 p-1 cursor-pointer bg-white rounded-md border-2 border-secondary border-dashed"
              >
                <p className="text-xs">image1</p>
                <div className="bg-gray-200 w-10 h-10 flex items-center justify-center">
                  <img
                    src="/uploadFile.png"
                    alt="upload File"
                    className="bg-white rounded"
                  />
                </div>
                <p className="text-xs">Click to upload file</p>
              </label>
            )}
            {!imageTwo && (
              <label
                htmlFor="fileInput2"
                className="flex flex-col items-center justify-between w-24 h-28 p-1 cursor-pointer bg-white rounded-md border-2 border-secondary border-dashed"
              >
                <p className="text-xs">image2</p>
                <div className="bg-gray-200 w-10 h-10 flex items-center justify-center">
                  <img
                    src="/uploadFile.png"
                    alt="upload File"
                    className="bg-white rounded"
                  />
                </div>
                <p className="text-xs">Click to upload file</p>
              </label>
            )}
            {!imageOne && (
              <input
                id="fileInput1"
                className="w-24 hidden"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
              />
            )}
            {!imageTwo && (
              <input
                id="fileInput2"
                className="w-24 hidden"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
              />
            )}
          </div>
          {imageOne && (
            <img
              src={image_1}
              alt="Preview"
              className="w-24 h-24 z-10 rounded"
            />
          )}
          {imageTwo && (
            <img
              src={image_2}
              alt="Preview"
              className="w-24 h-24 absolute top-[30px] left-[14px] bg-grey z-[-1] rounded"
            />
          )}
        </div>
        <div className="flex flex-col mt-5">
          <div className="flex flex-row gap-2 items-center">
            <TextInput
              type="text"
              name="product_name"
              placeholder="Product name"
              rightIcon={null}
              className="p-2.5 w-56 border-placeholderColor"
            />
            <div className="h-16  relative z-30">
              <CustomSelectPicker
                isMulti={false}
                placeHolder={"Product Category"}
                data={getFormttedSubCategoriesData(sub_categories)}
                name={"category_id"}
                className="px-2.5 w-44 border-placeholderColor"
                handleCallback={getProductTypes}
              />
              <span className="text-danger text-sm font-pop text-xs">
                {errors.category_id &&
                  touched.category_id &&
                  errors.category_id}
              </span>
            </div>
            <div className="h-16">
              <CustomSelectPicker
                isMulti={false}
                placeHolder={"Product Type"}
                data={productTypes}
                name={"product_type"}
                className="px-2.5 w-44 border-placeholderColor"
              />
              <span className="text-danger text-sm font-pop text-xs">
                {errors.product_type &&
                  touched.product_type &&
                  errors.product_type}
              </span>
            </div>
          </div>
          {renderFeildBasedOnCategory(category, errors, touched, isPreOrder)}
        </div>
      </div>
    );
  };

  const renderPaginationView = () => {
    return (
      <div className="flex flex-row gap-4 mt-6 justify-center items-center">
        <div
          className={`${
            formStep === 1 ? "bg-primary" : "bg-white"
          } h-7 w-7 flex justify-center items-center border rounded-md cursor-pointer`}
          onClick={() => setFormStep(1)}
        >
          <p
            className={`font-pop text-xs ${
              formStep === 1 ? "text-white" : "text-primary"
            }`}
          >
            {" "}
            1
          </p>
        </div>
        <p className="text-primary font-pop"></p>
        <div
          className={`${
            formStep === 2 ? "bg-primary" : "bg-white"
          } h-7 w-7 flex justify-center items-center border rounded-md cursor-pointer`}
          onClick={() => setFormStep(2)}
        >
          <p
            className={`font-pop text-xs ${
              formStep === 2 ? "text-white" : "text-primary"
            }`}
          >
            {" "}
            2
          </p>
        </div>
      </div>
    );
  };

  const renderProductsStep2Form = (errors, touched) => {
    return (
      <>
        <div className="">
          <p className="font-pop text-lg text-primary my-6">Supplier Details</p>
          <div className="flex flex-row gap-2 items-center">
            <TextInput
              type="text"
              name="supplier_name"
              placeholder="Supplier name"
              rightIcon={null}
              className="p-2.5 w-80 border-placeholderColor"
            />
            <TextInput
              type="text"
              name="supplier_mobile"
              placeholder="Supplier Mobile"
              rightIcon={null}
              className="p-2.5 w-80 border-placeholderColor"
            />
          </div>
          <div className="flex flex-row gap-2 items-center">
            <TextInput
              type="text"
              name="supplier_mail"
              placeholder="Supplier Email"
              rightIcon={null}
              className="p-2.5 w-80 border-placeholderColor"
            />
            <TextInput
              type="text"
              name="supplier_address"
              placeholder="Supplier Address"
              rightIcon={null}
              className="p-2.5 w-80 border-placeholderColor"
            />
          </div>
        </div>

        <div>
          <p className="font-pop text-lg text-primary mb-6">Printer Details</p>
          <div className="flex flex-row gap-2 items-center">
            <TextInput
              type="text"
              name="printer_name"
              placeholder="Printer name"
              rightIcon={null}
              className="p-2.5 w-80 border-placeholderColor"
            />
            <TextInput
              type="text"
              name="printer_mobile"
              placeholder="Printer Mobile"
              rightIcon={null}
              className="p-2.5 w-80 border-placeholderColor"
            />
          </div>
          <div className="flex flex-row gap-2 items-center">
            <TextInput
              type="text"
              name="printer_email"
              placeholder="Printer Email"
              rightIcon={null}
              className="p-2.5 w-52 border-placeholderColor"
            />
            <TextInput
              type="text"
              name="printer_address"
              placeholder="Street Address"
              rightIcon={null}
              className="p-2.5 w-52 border-placeholderColor"
            />
            <TextInput
              type="text"
              name="printer_appartment"
              placeholder="Apt, Suite etc"
              rightIcon={null}
              className="p-2.5 w-52 border-placeholderColor"
            />
          </div>

          <div className="flex flex-row gap-2 items-center">
            <TextInput
              type="text"
              name="printer_city"
              placeholder="City"
              rightIcon={null}
              className="p-2.5 w-52 border-placeholderColor"
            />
            <div className="h-16">
              <CustomSelectPicker
                isMulti={false}
                placeHolder={"Country"}
                data={getFormttedCountryData(country)}
                name={"printer_country"}
                className="px-2.5 w-52 border-placeholderColor"
              />
              <span className="text-danger text-sm font-pop text-xs">
                {errors.printer_country &&
                  touched.printer_country &&
                  errors.printer_country}
              </span>
            </div>
            <TextInput
              type="text"
              name="printer_zipcode"
              placeholder="Zipcode"
              rightIcon={null}
              className="p-2.5 w-52 border-placeholderColor"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <Formik
      initialValues={{
        product_name: "",
        image1: "",
        image2: "",
        category_id: "",
        product_type: "",
        supplier_name: "",
        supplier_mobile: "",
        supplier_mail: "",
        supplier_address: "",
        printer_name: "",
        printer_mobile: "",
        printer_email: "",
        printer_address: "",
        printer_appartment: "",
        printer_city: "",
        printer_country: "",
        printer_zipcode: "",
        sizes: [{ size: "", quantity: "" }],
        gender: "Unisex",
      }}
      validationSchema={ProductsSchema}
      onSubmit={(values, { setSubmitting }) => {
        values.image1 = imageOne;
        values.image2 = imageTwo;
        setSubmitting(false);
        handleAddProduct(values, skuInputFeilds);
        console.log(values, "valuesform");
        // console.log(setSubmitting.payload,'iss')
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        errors,
        touched,
        values,
        // image,
        // imageTwo,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit} className="m-3">
          <div className="p-4">
            <div className=" flex flex-row justify-between items-center gap-20">
              <div className="flex flex-col">
                <p className="font-pop text-xl font-medium text-primary">
                  {formStep === 1
                    ? modalProps.title
                    : "Supplier & Printer Details"}
                </p>
              </div>
              <div className="flex flex-row gap-4">
                <button
                  type="button"
                  className="flex justify-center items-center  bg-grey h-8 w-8 rounded-lg shadow-lg"
                  onClick={handleModalClose}
                >
                  <img src={AppImages.cancelBlack} className="w-3 h-3" />
                </button>

                <button
                  type="submit"
                  className="flex justify-center items-center  bg-secondary h-8 w-8 rounded-lg shadow-lg"
                >
                  <img src={AppImages.checkIcon} className="w-3 h-3" />
                </button>
              </div>
            </div>
            <p className="font-pop text-xs  text-placeholderColor">
              Add Product
            </p>
          </div>
          <div className="border mx-4" />
          <div className="mx-4">
            {formStep === 1 &&
              renderProductsForm(errors, touched, values.pre_order)}
            {formStep === 2 &&
              renderProductsStep2Form(errors, touched, values.pre_order)}
          </div>
          {renderPaginationView()}
        </Form>
      )}
    </Formik>
  );
};

export default ProductsForm;

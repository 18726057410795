import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { AppImages } from "../../../../Constants/Images";
import { MerchStockSchema } from "../../../../Utilites/Validation";
import CustomSelectPicker from "../../../../Components/CustomSelect";

const stockType = [
  {
    id: 1,
    label: "Warehouse",
    value: "Warehouse",
  },
  {
    id: 2,
    label: "Trailer",
    value: "Trailer",
  },
];

const StockForm = (props) => {
  const {
    handleModalClose,
    modalProps,
    merchDetails,
    handleMerchStockSubmit,
    handleGetStockTypeList,
    stockTypes,
    currentStockType,
    currentStockId,
  } = props;
  const [merchSizes, setMerchSizes] = useState(merchDetails.child_list);
  const getStockTypesList = (stockType) => {
    handleGetStockTypeList(stockType);
  };

  const getFormttedVenuStockTypeseData = () => {
    const formattedstockTypesList =
      stockTypes &&
      stockTypes.length > 0 &&
      stockTypes.map((item) => {
        return {
          ...item,
          label: `${item.trailer_name || item.warehouse_name}`,
          value: `${item.id}`,
        };
      });
    return formattedstockTypesList;
  };

  const renderStockForm = () => {
    const handleMerchSizeFormChange = (index, event) => {
      const tempMerchSizes = [...merchSizes];
      tempMerchSizes[index][event.target.name] = event.target.value;
      setMerchSizes(tempMerchSizes);
    };

    return (
      merchSizes &&
      merchSizes.map((item, index) => (
        <div className="flex flex-row items-center justify-between">
          <div className="my-2">
            <input
              type="text"
              className={` border border-placeholderColor p-2.5 w-60 rounded-lg placeholder-placeholderColor font-pop text-xs`}
              disabled={true}
              value={item.size_name}
            />
          </div>
          <div className="my-2">
            <input
              type="number"
              name="quantity"
              className={` border border-placeholderColor p-2.5 w-60 rounded-lg placeholder-placeholderColor font-pop text-xs`}
              placeholder="Quantity"
              value={item.quantity}
              onChange={(event) => handleMerchSizeFormChange(index, event)}
            />
          </div>
          <div className="my-2">
            <input
              type="number"
              className={` border border-placeholderColor p-2.5 w-60 rounded-lg placeholder-placeholderColor font-pop text-xs`}
              placeholder="Product cost"
              name="cost"
              value={item.cost}
              onChange={(event) => handleMerchSizeFormChange(index, event)}
            />
          </div>
        </div>
      ))
    );
  };

  return (
    <Formik
      initialValues={{
        stock_type: currentStockType ?? "",
        stock_id: currentStockId ?? "",
      }}
      validationSchema={MerchStockSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        handleMerchStockSubmit(values, merchSizes);
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        errors,
        touched,
        values,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit} className="m-3">
          <>
            <div className=" flex flex-row justify-between items-center">
              <div className="flex flex-col">
                <p className="font-pop text-xl font-medium text-primary">
                  Merch Stock
                </p>
              </div>
              <div className="flex flex-row  gap-4">
                <button
                  type="button"
                  className="flex justify-center items-center  bg-grey h-8 w-8 rounded-lg shadow-lg"
                  onClick={handleModalClose}
                >
                  <img src={AppImages.cancelBlack} className="w-3 h-3" />
                </button>
                <button
                  type="submit"
                  className="flex justify-center items-center  bg-secondary h-8 w-8 rounded-lg shadow-lg"
                >
                  <img src={AppImages.checkIcon} className="w-3 h-3" />
                </button>
              </div>
            </div>
            <p className="font-pop text-xs  text-placeholderColor">Stock</p>
          </>
          <div className="border my-6" />

          <div className="flex flex-row items-center gap-2">
            {currentStockType === "" && (
              <div className="h-16">
                <CustomSelectPicker
                  isMulti={false}
                  label={""}
                  placeHolder={"Select Stock Type"}
                  data={stockType}
                  name={"stock_type"}
                  className="px-2.5 w-80 border-placeholderColor"
                  handleCallback={getStockTypesList}
                />
                <span className="text-danger text-sm font-pop text-xs">
                  {errors.stock_type && touched.stock_type && errors.stock_type}
                </span>
              </div>
            )}

            {currentStockType === "" && (
              <div className="h-16">
                <CustomSelectPicker
                  isMulti={false}
                  label={""}
                  placeHolder={"Select Warehouse/Trailer"}
                  data={getFormttedVenuStockTypeseData()}
                  name={"stock_id"}
                  className="px-2.5 w-80 border-placeholderColor"
                />
                <span className="text-danger text-sm font-pop text-xs">
                  {errors.stock_id && touched.stock_id && errors.stock_id}
                </span>
              </div>
            )}
          </div>

          {values.stock_id > 0 && renderStockForm()}
        </Form>
      )}
    </Formik>
  );
};

export default StockForm;

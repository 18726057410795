import React, { useEffect, useState, useRef } from "react";

function ScrollButton() {
    const [showScrollButton, setShowScrollButton] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 100) {
            setShowScrollButton(true);
          } else {
            setShowScrollButton(false);
          }
        };
    
        window.addEventListener("scroll", handleScroll);
    
        // Clean up the event listener
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

      const scrollToTop = () => {
        window.scrollTo({ top: 4, behavior: 'smooth' });
      };
    return (
        <div>
             {showScrollButton && (
          <div
            className="fixed bottom-14 right-8 cursor-pointer mt-20"
            onClick={scrollToTop}
          >
            <img
              src="https://static-00.iconduck.com/assets.00/circle-arrow-up-icon-256x256-l8o2ckt0.png"
              alt="Scroll to top"
              className="w-8 h-8"
            />
          </div>
        )}

            
        </div>
    )
}

export default ScrollButton

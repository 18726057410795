import React from "react";
import Modal from "react-responsive-modal";

const EditProduct = ({ show, onHide }) => {
  return (
    <Modal
      open={show}
      onClose={onHide}  
      center
    >
      <h1>Edit Product</h1>
      <div>
        <button type="button" className="btn btn-primary" onClick={onHide}>
          Close
        </button>
      </div>
    </Modal>
  );
};

export default EditProduct;

import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
  identity: Yup.string()
    .min(2, "Username too Short!")
    .max(50, "Username too Long!")
    .required("Username is required"),
  password: Yup.string()
    .min(2, "Password too Short!")
    .max(50, "Password too Long!")
    .required("Password is required"),
});

export const RegisterSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email address is required"),
  mobile: Yup.string().required("Mobile number is required"),
  password: Yup.string()
    .min(2, "Password too Short!")
    .max(50, "Password too Long!")
    .required("Password is required"),
});

export const ForgotPasswordSchema = Yup.object().shape({
  identity: Yup.string().email('Invalid email address').required('Email address is required'),
});

export const ResetPasswordSchema = Yup.object().shape({
  new: Yup.string()
    .min(6, 'Password too short!')
    .max(50, 'Password too long!')
    .required('Password is required'),
  new_confirm: Yup.string()
    .oneOf([Yup.ref('new'), null], 'Passwords must match')
    .required('Confirm Password is required')
});
export const ChangePasswordSchema = Yup.object().shape({
  old: Yup.string()
    .min(6, 'Password too short!')
    .max(50, 'Password too long!')
    .required('Old Password is required'),
  new: Yup.string()
    .min(6, 'Password too short!')
    .max(50, 'Password too long!')
    .required('New Password is required'),
  new_confirm: Yup.string()
    .oneOf([Yup.ref('new'), null], 'Passwords must match')
    .required('Confirm Password is required')
});
export const ShowsSchema = Yup.object().shape({
  // start_date: Yup.date().required("Start date is required"),
  // end_date: Yup.date().optional(),
  //tour_id: Yup.date().required("Tour is required"),
  no_of_shows: Yup.string().required("No of shows is required"),
  venue_address: Yup.string().required("Venue address is required"),
  // tour_id: Yup.string().required("Tour is required"),
  show_type: Yup.string().required("Show type is required"),
  // add_tours_data: Yup.string().required("Tour type is required"),
  //show_capacity: Yup.string().required("Show capacity is required"),
  tax_method: Yup.string().required("Tax method is required"),
  tax_apparel: Yup.number()
    .min(0, 'Apparel tax greater than or equal to 0')
    .max(20, 'Tax percentage less than or equal to 20')
    .required('Apparel tax is required'),
  tax_others: Yup.number()
    .min(0, 'Other tax greater than or equal to 0')
    .max(20, 'Other tax less than or equal to 20')
    .required("Other tax is required"),
  tax_music: Yup.number()
    .min(0, 'Music tax greater than or equal to 0')
    .max(20, 'Music tax less than or equal to 20')
    .required("Music tax is required"),
  // venue_rep_name: Yup.string().required("Venue rep name is required"),
  // venue_rep_email: Yup.string().required("Venue rep email is required"),
  // venue_rep_phone: Yup.string().required("Venue rep phone is required"),
  fee_apparel: Yup.number()
    .min(0, 'Apparel fee less than or equal to 0')
    .max(20, 'Apparel fee less than or equal to 20')
    .required("Apparel fee is required"),
  fee_others: Yup.number()
    .min(0, 'Other fee less than or equal to 0')
    .max(20, 'Other fee less than or equal to 20')
    .required("Other fee is required"),
  fee_music: Yup.number()
    .min(0, 'Music fee less than or equal to 0')
    .max(20, 'Music fee less than or equal to 20')
    .required("Music fee is required"),
  // concession_company: Yup.string().required("Concession company is required"),
  //ticket_sales: Yup.string().required("Ticket is required"),

  //merchandise_contact_name: Yup.string().required(
    //"Merchandise contact name is required"
  //),
  //merchandise_contact_number: Yup.string().required(
   // "Merchandise phone nunmber is required"
 // ),
});

export const ToursSchema = Yup.object().shape({
  // start_date: Yup.date().required("Start date is required"),
  // end_date: Yup.date().required("End date is required"),
  tour_name: Yup.string().required("Tour name is required"),
  vend_percentage: Yup.number()
    .min(0, 'vend percentage must be greater than or equal to 0')
    .max(20, 'vend percentage must be less than or equal to 20')
  // tour_type: Yup.string().required("Tour type is required"),
  // report_currency: Yup.string().required("Select your currency"),
});

export const PostponeShowsSchema = Yup.object().shape({
  start_date: Yup.date().required("Start date is required"),
  end_date: Yup.date().optional(),
});


export const ProductsSchema = Yup.object().shape({
  product_name: Yup.string().required("Product name is required"),
  category_id: Yup.string().required("Select category"),
  // product_type: Yup.string().required("Select type"),
  // supplier_name: Yup.string().required("Supplier name is required"),
  // supplier_mobile: Yup.string().required("Supplier mobile currency"),
  // image_one: Yup.string().required("ImageOne is required"),
  // image_two: Yup.string().required("ImageTwo is required"),
  // supplier_mail: Yup.string().required("Supplier email is required"),
  // supplier_address: Yup.string().required("Supplier address is required"),
  // printer_name: Yup.string().required("Printer name is required"),
  // printer_mobile: Yup.string().required("Printer mobile is required"),
  // printer_email: Yup.string().required("Printer email your required"),
  // printer_address: Yup.string().required("Street address is required"),
  // printer_appartment: Yup.string().optional(),
  // printer_city: Yup.string().required("Printer city is required"),
  // printer_country: Yup.string().required("Printer country is required"),
  // printer_zipcode: Yup.string().required("Printer zipcode is required"),

});
export const WarehouseSchema = Yup.object().shape({
  warehouse_name: Yup.string().required("Warehouse name is required"),
  contact_person: Yup.string().required("Contact person is required"),
  phone_number: Yup.string().required("Phone number is required"),
  address: Yup.string().required("Address is required"),
  
});


export const Trailerschema = Yup.object().shape({
  trailer_name: Yup.string().required("Trailer name is required"),
  contact_person: Yup.string().required("Contact person is required"),
  phone_number: Yup.string().required("Phone number is required") 
});


export const MerchStockSchema = Yup.object().shape({
  stock_type: Yup.string().required("Select stock type"),
  stock_id: Yup.string().required("Select Warehouse/Trailer")
});

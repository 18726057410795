import React, { useEffect, useState, useRef } from "react";
import withHoc from "../../../Components/Hoc";
import CustomModal from "../../../Components/Modal";
import WarehouseForm from "./WarehouseForm";
import LoadingOverlay from "react-loading-overlay";
import { BeatLoader } from "react-spinners";
import { AppData } from "../../../Constants/AppData";
import { apiCall, getApiCall } from "../../../Utilites/Api";
import { ApiUrl } from "../../../Constants/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ProductItem } from "../Products";
import { AppImages } from "../../../Constants/Images";
import ProductsStockForm from "../../Home/Products/ProductsStockForm";
import ProductShipmentForm from "../../Home/Products/ProductShipmentForm";
import SearchInput from "../../../Components/SearchInput";
import ScrollButton from "../../../Pages/Home/ScrollButton";

const Warehouse = () => {
  const [warehousesData, setWarehousesData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [stockTypes, setStockTypes] = useState([]);
  const [warehouseDetails, setWarehouseDetails] = useState(null);
  const [warehouseDropdown, setWarehouseDropdown] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [merchDetails, setMerchDetails] = useState(null);
  const [productsList, setProductList] = useState([]);

  const navigate = useNavigate();
  const [modalProps, setModalProps] = useState({
    visible: false,
    title: "",
    key: "",
  });

  const dropdownRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    getWarehouses();
  }, []);

  useEffect(() => {
    const selectedWarehouseFromStorage = JSON.parse(localStorage.getItem("selectedWarehouse"));
    if (selectedWarehouseFromStorage) {
      setSelectedWarehouse(selectedWarehouseFromStorage);
    }
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setWarehouseDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  const getMerchList = async (warehouseItem) => {
    const resp = await getApiCall(
      ApiUrl.merchList("warehouse", warehouseItem?.id)
    );
    if (resp.status) {
      setProductList(resp.data?.merch_list);
    }
  };

  const getWarehouses = async () => {
    const resp = await getApiCall(ApiUrl.WarehousesList());
    if (resp.status) {
      setIsLoading(false);
      setWarehousesData(resp.data);
      const selectedWarehouseFromStorage = JSON.parse(localStorage.getItem("selectedWarehouse"));
      if (selectedWarehouseFromStorage && resp.data.some(warehouse => warehouse.id === selectedWarehouseFromStorage.id)) {
        setSelectedWarehouse(selectedWarehouseFromStorage);
      } else {
        setSelectedWarehouse(resp.data[0] || null);
      }
      getMerchList(selectedWarehouseFromStorage || resp.data[0] || null);
    } else {
      setIsLoading(false);
    }
  };

  const openAddWarehouseModal = () => {
    setWarehouseDetails(null);
    setModalProps({
      visible: true,
      title: "",
      key: "addWarehouse",
    });
  };

  const closeModal = () => {
    setModalProps({
      visible: false,
      title: "",
      key: "",
    });
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const openEditWarehouseModal = (selectedWarehouse) => {
    setWarehouseDetails(selectedWarehouse);
    setModalProps({
      visible: true,
      title: "Edit Warehouse",
      key: "editWarehouse",
    });
  };

  const addOrUpdateNewWarehouse = async ({ ...payload }) => {
    setIsLoading(true);
    const resp = await apiCall(
      warehouseDetails?.id
        ? ApiUrl.WarehouseEdit(warehouseDetails?.id)
        : ApiUrl.addWarehouse(),
      payload
    );
    if (resp.status) {
      closeModal();
      getWarehouses();
      if (warehouseDetails?.id !== selectedWarehouse?.id) {
        localStorage.setItem("selectedWarehouse", JSON.stringify(resp.data));
        setSelectedWarehouse(resp.data);
      } else { 
        localStorage.setItem("selectedWarehouse", JSON.stringify(resp.data));
        setSelectedWarehouse(resp.data);
      }

      toast.success(
        `Warehouse ${warehouseDetails?.id ? "updated" : "added"} successfully`
      );
    } else {
      toast.error(
        `Warehouse not ${warehouseDetails?.id ? "updated" : "added"
        }. Please try later`
      );
    }
  };



  const handleWarehouseClick = (warehouseItem) => {
    setSelectedWarehouse(warehouseItem);
    setWarehouseDropdown(!warehouseDropdown);
    getMerchList(warehouseItem);
    setSearchInput("");
    localStorage.setItem("selectedWarehouse", JSON.stringify(warehouseItem));
  };

  const handleAssignMerchStock = (item) => {
    setMerchDetails(item);
    setModalProps({
      visible: true,
      title: "Merch Stock",
      key: "merchStock",
    });
  };


  const handleAssignMerchShipment = (item) => {
    setMerchDetails(item);
    setModalProps({
      visible: true,
      title: "Merch Shipment",
      key: "productsShipment",
    });
  };

  const updateMerchStocks = async ({ ...payload }, merchSizes) => {
    const updatedPayload = merchSizes.map((item) => {
      return {
        ...item,
        stock_type: payload.stock_type,
        stock_id: payload.stock_id,
        merch_child_id: item.id,
      };
    });
    const resp = await apiCall(ApiUrl.addMerchStock(), updatedPayload);
    if (resp.status) {
      closeModal();
      getMerchList(selectedWarehouse);
      toast.success(`Merch details updated successfully`);
    } else {
      toast.error(`Merch details not updated. Please try later`);
    }
  };

  const getShipmentTypeLists = async () => {
    try {
      console.log(selectedWarehouse.warehouse_name)
      const warehouseResp = await getApiCall(ApiUrl.WarehousesList());
      const trailerResp = await getApiCall(ApiUrl.TrailersList());
      if (warehouseResp.status && trailerResp.status) {
        const combinedList = [
          ...warehouseResp.data.map(item => ({ ...item, type: "warehouse" })),
          ...trailerResp.data.map(item => ({ ...item, type: "trailer" }))
        ];
        setStockTypes(combinedList);
      } else {
        console.error("Failed to fetch warehouse or trailer list");
      }
    } catch (error) {
      console.error("Error fetching warehouse or trailer list:", error);
    }
  };

  const updateMerchShipment = async (payload) => {
    const { inventory_type, inventory_data } = payload;
    const updatedPayload = inventory_data.map((item) => ({
      merch_id: item.merch_id,
      merch_child_id: item.merch_child_id,
      from_stock_type: item.from_stock_type,
      from_stock_id: item.from_stock_id,
      to_stock_type: item.to_stock_type,
      to_stock_id: item.to_stock_id,
      quantity: item.quantity,
    }));

    const finalPayload = {
      inventory_type,
      inventory_data: updatedPayload,
    };

    try {
      if ((finalPayload.inventory_data[0].from_stock_type != finalPayload.inventory_data[0].to_stock_type) || (finalPayload.inventory_data[0].from_stock_id != finalPayload.inventory_data[0].to_stock_id)) {
        const resp = await apiCall(ApiUrl.addMerchShipment(), finalPayload);
        if (resp.status) {
          closeModal();
          getMerchList(selectedWarehouse);
          toast.success(`Inventory moved successfully`);
        } else {
          if (resp.message) {
            toast.error(resp.message);
          } else {
            toast.error(`Inventory not moved. Please try later`);
          }
        }
      } else {
        toast.error(`From - To Warehouse/Trailer should not be same`);
      }
    } catch (error) {
      console.error("Error updating merch shipment:", error);
      toast.error(`An error occurred while updating merch shipment`);
    }
  };

  const filteredWarehouses = warehousesData?.filter((warehouse) =>
    typeof warehouse.warehouse_name === "string" &&
    warehouse.warehouse_name.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <LoadingOverlay
      active={isLoading}
      classNamePrefix="customLoader"
      spinner={<BeatLoader color="white" size={20} />}
    >
      <CustomModal
        handleModalClose={closeModal}
        modalProps={modalProps}
        className={`overflow-y-scroll w-full rounded-2xl shadow-xl`}
      >
        {modalProps.key === "merchStock" ? (
          <ProductsStockForm
            handleModalClose={closeModal}
            modalProps={modalProps}
            merchDetails={merchDetails}
            stockTypes={[]}
            handleMerchStockSubmit={updateMerchStocks}
            currentStockType={"warehouse"}
            currentStockId={selectedWarehouse.id}
          />
        ) : modalProps.key === "productsShipment" ? (
          <ProductShipmentForm
            handleModalClose={closeModal}
            modalProps={modalProps}
            merchDetails={merchDetails}
            stockTypes={stockTypes}
            handleGetShipmentTypeLists={getShipmentTypeLists}
            handleMerchShipmentSubmit={updateMerchShipment}
            currentInventoryType=""
            currentFromStockType={"warehouse"}
            currentFromStockID={selectedWarehouse.id}
            currentToStockType=""
            currentToStockId=""
            selectedWarehouseName={selectedWarehouse?.warehouse_name}

          />
        ) : (
          <WarehouseForm
            handleModalClose={closeModal}
            handleAddOrUpdateWarehouse={addOrUpdateNewWarehouse}
            singleWarehouseDetails={warehouseDetails}
            modalTitle={modalProps.title}
          />
        )}
      </CustomModal>
      <div className="primary-header px-7 py-3">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row">
            <div
              className="flex flex-row justify-center items-center gap-10 cursor-pointer relative"
              ref={dropdownRef}
            >
              <div
                className="flex flex-row justify-between items-center"
                onClick={() => setWarehouseDropdown(!warehouseDropdown)}
              >
                <div className="flex flex-row justify-center items-center">
                  <p className="font-pop font-medium text-primary text-sm">
                    {selectedWarehouse?.warehouse_name ?? "Manage Warehouses"}
                  </p>
                  <img
                    src="/chevron_down.png"
                    className="w-4 h-4 object-contain mt-2 mx-2"
                  />
                </div>
              </div>
              <div
                className="flex justify-center items-center w-5 h-5 rounded-md"
                style={{ marginLeft: "-2rem" }}
                onClick={() => openEditWarehouseModal(selectedWarehouse)}
              >
                <img
                  src={AppImages.edit}
                  className="w-5 h-5 object-contain mt-2"
                />
              </div>
              {warehouseDropdown && (
                <div className="absolute top-8 left-0 w-60 rounded-md shadow-lg z-30 cursor-pointer">
                  <SearchInput
                    searchInput={searchInput}
                    handleSearchInputChange={handleSearchInputChange}
                  />
                  <ul className="bg-white border border-primary absolute top-19 left-1 w-60 rounded-md shadow-lg max-h-44 z-30 overflow-y-scroll hide-scrollbar">
                    {filteredWarehouses.map((item) => (
                      <li
                        key={item.id}
                        className="shadow-md"
                        onClick={() => handleWarehouseClick(item)}
                      >
                        <p className="font-pop text-xs text-primary  hover:bg-primary hover:text-white border-b p-2">
                          {item.warehouse_name}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}


            </div>
          </div>

          <div className="flex flex-row items-center justify-center gap-12">
            <div
              className="flex flex-row justify-center items-center bg-primary w-40 h-9 rounded-lg gap-2 cursor-pointer"
              onClick={openAddWarehouseModal}
            >
              <img src={"/plus.png"} className="w-3 h-3 object-contain" />
              <p className="font-pop text-xs text-white">Add Warehouse</p>
            </div>

          </div>
        </div>
      </div>

      <div className="main-body px-3 py-4">
        <ScrollButton />
        {productsList &&
          productsList.map((item) => (
            <ProductItem
              key={item.id}
              productItem={item}
              stockType="warehouse"
              handleAssignMerchStock={handleAssignMerchStock}
              handleAssignMerchShipment={handleAssignMerchShipment}
            />
          ))}
      </div>
    </LoadingOverlay>
  );
};
const secondaryMenu = AppData.productsSecondaryMenu;
export default withHoc(Warehouse, { secondaryMenu, page: "warehouse" });

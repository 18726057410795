import React, { useEffect, useState, useCallback } from "react";
import withHoc from "../../../Components/Hoc";
import CustomModal from "../../../Components/Modal";
import ShowsForm from "./ShowsForm";
import { apiCall, getApiCall } from "../../../Utilites/Api";
import { ApiUrl } from "../../../Constants/Api";
import { toast } from "react-toastify";
import { convertToDbFormat } from "../../../Utilites/Date";
import moment from "moment";
import { AppData } from "../../../Constants/AppData";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { BeatLoader } from "react-spinners";
import NoData from "../../../Components/NoData";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import ScrollButton from "../../../Pages/Home/ScrollButton";
import { AppImages } from "../../../Constants/Images";
import TextInput from "../../../Components/TextInput";

const ShowItem = ({
  item,
  handlePostponeShow,
  handleCancelShow,
  handleEditShow,
  handleNoteChange,
  handleTicketSale,
}) => {
  const navigate = useNavigate();
  const [note, setNote] = useState(item?.note || "");
  const [total_tickets, setTotalTickets] = useState(item?.total_tickets || "");
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateTimeout, setUpdateTimeout] = useState(null);

  let showStatusClass = "";

  if (item?.status === "inactive") {
    showStatusClass = "bg-danger";
  } else if (item?.status === "completed") {
    showStatusClass = "bg-primary";
  }

  if (item.status === "inactive") {
    showStatusClass = "bg-danger";
  } else if (item.status === "completed") {
    showStatusClass = "bg-primary";
  }

  const handleShow = (item) => {
    console.log(item, "lll");
    navigate(`/shows/${item.tour_id}/count`, { state: { item } });
  };

  const updateNoteChange = (e) => {
    const updatedNote = e.target.value;
    if (item.status === "active") {
      setNote(updatedNote);
      // if (updateTimeout) {
      //   clearTimeout(updateTimeout);
      // }
      // const timeout = setTimeout(async () => {
      //   setIsUpdating(true);
      //   try {
      //     const resp = await apiCall(ApiUrl.NoteUpdate(item.id), { note: updatedNote });
      //     if (resp.status) {
      //       toast.success("Note updated successfully");
      //     } else {
      //       toast.error("Failed to update note. Please try again");
      //     }
      //   } catch (error) {
      //     toast.error("An error occurred. Please try again");
      //   } finally {
      //     setIsUpdating(false);
      //   }
      // }, 1000);
      // setUpdateTimeout(timeout);
    }
  };
  const updateTotalTicketsChange = (e) => {
    const updatedTotalTickets = e.target.value;
    if (item.status === "active") {
      setTotalTickets(updatedTotalTickets);
    }
  };
  const handleTotalTicketsBlur = () => {
    handleTicketSale(item, total_tickets);
  };

  return (
    <div className={`show-card rounded-xl p-4 my-6 shadow-xl `}>
      <div className="flex items-center  justify-between flex-row gap-3">
        <ScrollButton />
        <div className="flex flex-col w-1/12 h-44">
          <p className="font-medium 2xl:text-sm xl:text-xs lg:xs text-primary font-pop">
            {item.city && item.state
              ? item.city + ", " + item.state
              : item.city}
          </p>
          <div className="border border-secondary rounded-lg">
            <div className="flex flex-col gap-2">
              <p className="font-pop text-xs text-primary text-center">
                {moment(item.start_date).format("MMM")}
                {moment(item.start_date).format("MMM") !==
                  moment(item.end_date).format("MMM") &&
                  ` - ${moment(item.end_date).format("MMM")}`}
              </p>

              <div className="bg-primary">
                <p className="font-pop text-lg text-white font-medium text-center">
                  {moment(item.start_date).format("DD")}
                  {item.start_date !== item.end_date &&
                    ` - ${moment(item.end_date).format("DD")}`}
                </p>
                <p
                  className="font-pop text-xs text-white text-center"
                  dangerouslySetInnerHTML={{
                    __html:
                      moment(item.start_date).format("ddd") +
                      (item.start_date !== item.end_date
                        ? "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +
                          moment(item.end_date).format("ddd")
                        : ""),
                  }}
                />
                {/* <p className="font-pop text-xs text-white text-center">
                  {moment(item.start_date).format("ddd")}
                  {item.start_date !== item.end_date &&    `  &nbsp;&nbsp;&nbsp;&nbsp;${moment(item.end_date).format("ddd")}`}
                </p> */}
              </div>
              <p className="font-pop text-xs text-primary text-center">
                {moment(item.start_date).format("YYYY")}
                {moment(item.start_date).format("YYYY") !==
                  moment(item.end_date).format("YYYY") &&
                  ` - ${moment(item.end_date).format("YYYY")}`}
                {/* {moment(item.start_date).format("YYYY")} */}
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center border border-secondary rounded-lg w-24 h-14 mt-1">
            <p className="font-pop text-[10px] text-secondary font-medium text-center">
              Ticket sales
            </p>
            <input
              className="font-pop text-sm text-secondary font-medium text-center w-20 outline-none border-0 border-transparent focus:border-black"
              value={total_tickets}
              onChange={updateTotalTicketsChange}
              onBlur={handleTotalTicketsBlur}
              disabled={isUpdating}
            />
          </div>
        </div>

        <div
          className="flex 4xl:w-4/12 xl:w-7/12 lg:9/12 lg:w-6/12 h-44 flex-row border border-primary rounded-lg items-center justif-center gap-4 p-4 cursor-pointer"
          onClick={() => handleShow(item)}
        >
          <div className="w-1/2 flex flex-col gap-1">
            <p className="font-medium 2xl:text-sm xl:text-xs lg:xs text-primary font-pop">
              {item.venue_name}
            </p>
            <p className="text-xs text-secondary font-pop">
              {item.venue_address}
            </p>
            <p className="text-xs text-secondary font-pop">
              No.Of Shows: {item.no_of_shows} Shows
            </p>
            {/* <div className="flex gap-4 items-center flex-row">
              <div>
                <p className="text-xs text-secondary font-pop">Capacity</p>
                <p className="text-xs font-semibold text-primary font-pop">
                  {item.show_capacity}
                </p>
              </div>
              <div>
                <p className="text-xs text-secondary font-pop">No of Shows</p>
                <p className="text-xs font-semibold text-primary font-pop">
                  {item.no_of_shows}{" "}
                </p>
              </div>
            </div> */}
            <hr className="mt-5" />
            <p className="font-medium 1xl:text-sm xl:text-xs text-primary font-pop">
              Venue Tax
            </p>
            <div className="flex gap-2 justify-evenly items-center flex-row">
              <div>
                <p className="2xl:text-[11px] text-xs text-secondary  font-pop">
                  Apparel
                </p>
                <p className="2xl:text-[11px] text-xs font-semibold text-primary font-pop">
                  {item.tax_apparel}%
                </p>
              </div>
              <div>
                <p className="2xl:text-[11px] text-xs text-secondary font-pop">
                  Musix
                </p>
                <p className="2xl:text-[11px] text-xs font-semibold text-primary font-pop">
                  {item.tax_music}%
                </p>
              </div>
              <div>
                <p className="2xl:text-[11px] text-xs text-secondary  font-pop">
                  Other
                </p>
                <p className="2xl:text-[11px] text-xs font-semibold text-primary font-pop">
                  {item.tax_others}%
                </p>
              </div>
            </div>
          </div>
          <div className="border-l h-32 border-secondary shadow-lg" />

          <div className="w-1/2 flex flex-col gap-1">
            <p className="font-medium 2xl:text-sm xl:text-xs lg:xs text-primary font-pop">
              Promoter Details
            </p>
            <p className="text-xs text-secondary font-pop">
              Name: <b>{item.promoter_name}</b>
            </p>
            <p className="text-xs text-secondary font-pop">
              Email: <b>{item.promoter_email}</b>
            </p>
            <p className="text-xs text-secondary font-pop">
              Phone: <b>{item.promoter_phone}</b>
            </p>
            <hr />
            <p className="font-medium 1xl:text-sm xl:text-xs text-primary font-pop">
              Hall Fee
            </p>
            <div className="flex gap-2 justify-evenly items-center flex-row">
              <div>
                <p className="2xl:text-[11px] text-xs text-secondary  font-pop">
                  Apparel
                </p>
                <p className="2xl:text-[11px] text-xs font-semibold text-primary font-pop">
                  {item.tax_apparel}%
                </p>
              </div>
              <div>
                <p className="2xl:text-[11px] text-xs text-secondary font-pop">
                  Musix
                </p>
                <p className="2xl:text-[11px] text-xs font-semibold text-primary font-pop">
                  {item.tax_music}%
                </p>
              </div>
              <div>
                <p className="2xl:text-[11px] text-xs text-secondary  font-pop">
                  Other
                </p>
                <p className="2xl:text-[11px] text-xs font-semibold text-primary font-pop">
                  {item.tax_others}%
                </p>
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col gap-1">
            <p className="font-medium 2xl:text-sm xl:text-xs text-primary font-pop">
              Hall Fee
            </p>
            <p className="text-xs text-secondary font-pop"></p>
            <div className="flex gap-8 items-center flex-row">
              <div>
                <p className="text-xs text-secondary font-pop">Apparel</p>
                <p className="text-xs font-semibold text-primary font-pop">
                  {item.fee_apparel}%
                </p>
              </div>
              <div>
                <p className="text-xs text-secondary font-pop">Other</p>
                <p className="text-xs font-semibold text-primary font-pop">
                  {item.fee_others}%
                </p>
              </div>
            </div>
            <div className="flex gap-8 items-center flex-row">
              <div>
                <p className="text-xs text-secondary  font-pop">Music</p>
                <p className="text-xs font-semibold text-primary font-pop">
                  {item.fee_music}%
                </p>
              </div>
            </div>
          </div> */}
        </div>
        <div className="flex w-11/12 justify-center items-center">
          <textarea
            className="border border-primary rounded-lg p-4 placeholder-secondary h-44 w-full font-pop text-sm"
            rows={8}
            name="note"
            placeholder="Notes . . ."
            id={item.id}
            value={note}
            onChange={updateNoteChange} // Modified line
            onBlur={() => handleNoteChange(item, note)}
            disabled={isUpdating}
          />
        </div>
        <div
          className={`flex flex-col h-44 gap-4 border border-primary rounded-lg p-6 justify-center ${showStatusClass}`}
        >
          {item.status === "completed" && (
            <div>
              <p className="font-pop text-sm text-white">
                <strong>Completed</strong>
              </p>
            </div>
          )}
          {item.status === "inactive" && (
            <div>
              <p className="font-pop text-sm text-white">
                <strong>Cancelled</strong>
              </p>
            </div>
          )}
          {item.status === "active" && (
            <div
              className="w-24 h-8 bg-primary flex justify-center items-center rounded-md cursor-pointer"
              onClick={() => handleEditShow(item)}
            >
              <p className="font-pop text-white text-xs">Edit</p>
            </div>
          )}
          {item.status === "active" && (
            <div
              className="w-24 h-8 bg-grey flex justify-center items-center rounded-md cursor-pointer"
              onClick={() => handlePostponeShow(item)}
            >
              <p className="font-pop text-secondary text-xs">Re-Schedule</p>
            </div>
          )}

          {item.status === "active" && (
            <div
              className="w-24 h-8 bg-grey flex justify-center items-center rounded-md  cursor-pointer"
              onClick={() => handleCancelShow(item)}
            >
              <p className="font-pop text-secondary text-xs">Cancel</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Shows = () => {
  const location = useLocation();
  //const tourItemObj = location?.state?.item;
  const tourItemObj =
    typeof location?.state?.item === "undefined" || location?.state.item === ""
      ? JSON.parse(localStorage.getItem("tourItemObj"))
      : location?.state.item;
  console.log("Shows Trou data:" + JSON.stringify(tourItemObj));
  const { tourId: tourIdFromParams, status } = useParams();
  const tourIdFromState = location?.state?.tourId;
  const tourId = tourIdFromParams || tourIdFromState;
  const [modalProps, setModalProps] = useState({
    visible: false,
    title: "",
    key: "",
  });
  const [venueList, setVenueList] = useState([]);
  const [showsData, setShowsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  //const [toursData, setToursData] = useState(null);
  const [showDetails, setShowDetails] = useState(null);
  // const [selectedTourStatus, setSelectedTourStatus] = useState("upcoming");

  const [SearchParams, setSearchParams] = useSearchParams();
  const [selectedShowStatus, setSelectedShowStatus] = useState(
    SearchParams.get("status") ? SearchParams.get("status") : "left"
  );
  const [expandAddShowModal, setAddExpanShowdModal] = useState(false);
  const [cancelConfirmationData, setCancelConfirmationData] = useState(null);

  //alert(SearchParams.get('status'));
  // const { tourId, status } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    getVenueList();
  }, []);

  useEffect(() => {
    if (status) {
      setSelectedShowStatus(status);
    }
  }, [status]);

  // useEffect(() => {
  //   alert('ds');
  //   if (tourId) {
  //     getShowsList(tourId, selectedShowStatus);
  //   }
  // }, [selectedShowStatus, tourId]);

  useEffect(() => {
    if (typeof tourId === "undefined") {
      getTours();
    } else {
      getShowsList(tourId, selectedShowStatus);
    }
  }, [selectedShowStatus, tourId]);

  const getVenueList = async () => {
    const resp = await getApiCall(ApiUrl.searchVenue);
    if (resp.status) {
      setVenueList(resp.data);
    }
  };

  const getTours = async () => {
    const resp = await getApiCall(ApiUrl.toursList("upcoming"));
    if (resp.status && resp.data.list.length>0
    ) {
      setIsLoading(false);
      //setToursData(resp.data);
      //console.log('dsad:'+ JSON.stringify(resp.data.list[0]));
      localStorage.setItem("initalTourId", resp.data.list[0]?.id);
      localStorage.setItem("tourItemObj", JSON.stringify(resp.data.list[0]));
      getShowsList(resp.data.list[0]?.id, selectedShowStatus);
      //console.log('OBJ:'+localStorage.getItem("tourItemObj"));
    } else {
      setIsLoading(false);
    }
  };
  const getShowsList = async (tourId, selectedShowStatus) => {
    const resp = await getApiCall(ApiUrl.showsList(tourId, selectedShowStatus));
    if (resp.status) {
      setIsLoading(false);
      setShowsData(resp.data);
    } else {
      setIsLoading(false);
    }
  };

  const openAddShowModal = (item) => {
    setShowDetails(null);
    setModalProps({
      visible: true,
      title: "Add Show",
      key: "addShow",
      item: item,
      tourItemObj: tourItemObj, // Pass tourItemObj instead of tourId
      pageTitle: "shows",
    });
  };

  const openEditShowModal = () => {
    setModalProps({
      visible: true,
      title: "Edit Show",
      key: "editShow",
    });
  };

  const closeModal = () => {
    setModalProps({
      visible: false,
      title: "",
      key: "",
    });
  };

  const addUpdateShow = async ({ ...payload }) => {
    payload.venue_name = payload.venue_address.split(",")[0];
    payload.venue_address = payload.venue_address.split(",")[1];
    payload.tax_music = payload.tax_music;
    payload.start_date = convertToDbFormat(payload.start_date);
    if (payload.end_date) {
      payload.end_date = convertToDbFormat(payload.end_date);
    } else {
      payload.end_date = convertToDbFormat(payload.start_date);
    }
    payload.tour_id = tourId;
    const resp = await apiCall(
      showDetails?.id ? ApiUrl.showEdit(showDetails.id) : ApiUrl.addShow,
      //ApiUrl.showEdit(showDetails.id),
      payload
    );
    if (resp.status) {
      closeModal();
      getShowsList(tourId, "left");
      toast.success(
        `Show ${showDetails?.id ? "updated" : "added"} successfully`
      );
    } else {
      toast.error(
        `Show not ${showDetails?.id ? "updated" : "added"}. Please try later`
      );
    }
  };

  const submitPostPoneShows = async ({ ...payload }) => {
    payload.start_date = convertToDbFormat(payload.start_date);
    if (payload.end_date) {
      payload.end_date = convertToDbFormat(payload.end_date);
    } else {
      payload.end_date = convertToDbFormat(payload.start_date);
    }
    const resp = await apiCall(ApiUrl.postPoneShows(showDetails.id), payload);
    if (resp.status) {
      closeModal();
      getShowsList(tourId, "left");
      toast.success("Show Re-Scheduled successfully");
    } else {
      toast.error("Show not Re-Scheduled. Please try later");
    }
  };

  const handleSetShowsStatus = (item) => {
    setIsLoading(true);
    //navigate(`/shows/${tourId}/${item.key}`);
    //navigate("/shows/", { state: { tourId: tourId,itemkey: item.key ,item } });
    //alert(item.key);
    setSelectedShowStatus(item.key);
    navigate(`/shows?status=${item.key}`);
    //getShowsList(tourId, item.key);
  };

  const postPoneShows = (showItem) => {
    setShowDetails(showItem);
    setModalProps({
      visible: true,
      title: "Re-Schedule Show",
      key: "postponeShow",
    });
  };

  const submitShowForm = (formData) => {
    if (modalProps.key === "addShow" || modalProps.key === "editShow") {
      addUpdateShow(formData);
    } else {
      submitPostPoneShows(formData);
    }
  };

  const cancelShow = async (item) => {
    setCancelConfirmationData(item);
  };

  const updateShowNote = async (item, note) => {
    //alert(note);
    try {
      const resp = await apiCall(ApiUrl.NoteUpdate(item.id), { note: note });
      if (resp.status) {
        getShowsList(tourId, "left");
        toast.success("Note updated successfully");
      } else {
        toast.error("Failed to update note. Please try again");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again");
    } finally {
      //setIsUpdating(false);
    }
  };
  const updateTicket = async (item, total_tickets) => {
    try {
      const resp = await apiCall(ApiUrl.TicketSaleUpdate(item.id), {
        total_tickets: total_tickets,
      });
      if (resp.status) {
        getShowsList(tourId, "left");
        toast.success("Ticket sales updated successfully");
      } else {
        toast.error("Failed to update ticket sales. Please try again");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again");
    }
  };

  const handleCancelConfirmation = async () => {
    setCancelConfirmationData(null);

    const resp = await apiCall(ApiUrl.cancelShow(cancelConfirmationData.id), {
      status: "inactive",
    });
    if (resp.status) {
      getShowsList(tourId, "left");
      toast.success("Show cancelled successfully");
    } else {
      toast.error("Show not cancelled. Please try later");
    }
  };

  const editShow = ({ ...tourItem }) => {
    tourItem.start_date = moment(tourItem.start_date);
    tourItem.end_date = moment(tourItem.end_date);
    setShowDetails(tourItem);
    openEditShowModal();
  };

  return (
    <LoadingOverlay
      active={isLoading}
      classNamePrefix="customLoader"
      spinner={<BeatLoader color="white" size={20} />}
    >
      <CustomModal
        handleModalClose={closeModal}
        modalProps={modalProps}
        className={`overflow-y-scroll w-full rounded-2xl shadow-xl ${
          expandAddShowModal && "h-[500px]"
        }`}
      >
        <ShowsForm
          handleModalClose={closeModal}
          tourId={tourItemObj}
          venueList={venueList}
          handleSubmitForm={submitShowForm}
          singleShowDetails={showDetails}
          modalProps={modalProps}
          handleSetAddExpandModal={setAddExpanShowdModal}
          getVenueList={getVenueList}
        />
      </CustomModal>
      <CustomModal
        handleModalClose={() => setCancelConfirmationData(null)}
        modalProps={{
          visible: !!cancelConfirmationData,
          title: "Cancel Show Confirmation",
          key: "cancelShowConfirmation",
        }}
      >
        {cancelConfirmationData && (
          <CancelForm
            handleModalClose={() => setCancelConfirmationData(null)}
            handleCancel={handleCancelConfirmation}
          />
        )}
      </CustomModal>
      <div className="px-7 py-2 third-header">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row gap-8" style={{marginTop: "-100px"}}>
            {showsData &&
              showsData.shows_status.map((item) => (
                <div
                  className="flex flex-row justify-center items-center gap-2 cursor-pointer"
                  key={item.key}
                  onClick={() => handleSetShowsStatus(item)}
                >
                  <div
                    className={`flex justify-center items-center hoverable-button ${
                      selectedShowStatus === item.key ? "border-1" : "border"
                    } border border-secondary h-7 w-7 rounded-lg`}
                  >
                    <p
                      className={`font-pop text-xs text-secondary ${
                        selectedShowStatus === item.key && "font-semibold"
                      }`}
                    >
                      {item.count}
                    </p>
                  </div>
                  <p
                    className={`font-pop text-xs text-primary ${
                      selectedShowStatus === item.key && "font-semibold"
                    }`}
                  >
                    {item.label}
                  </p>
                </div>
              ))}
          </div>

          <div
            className="flex flex-row justify-center items-center bg-primary w-40 h-9 rounded-lg gap-2 cursor-pointer"
            onClick={() => openAddShowModal(tourItemObj)}
          >
            <img src={"/plus.png"} className="w-3 h-3 object-contain" />
            <p className="font-pop text-xs text-white">Add Show</p>
          </div>
        </div>
      </div>
      <div className="main-body px-3 my-4">
        {showsData &&
          showsData.list.length > 0 &&
          showsData.list.map((item) => (
            <ShowItem
              key={item.id}
              item={item}
              handlePostponeShow={postPoneShows}
              handleCancelShow={cancelShow}
              handleEditShow={editShow}
              handleNoteChange={updateShowNote}
              handleTicketSale={updateTicket}
            />
          ))}
        {showsData && showsData.list.length === 0 && (
          <NoData description={"No data found.Please check back later"} />
        )}
      </div>
    </LoadingOverlay>
  );
};

export const CancelForm = ({
  handleModalClose,
  handleCancel,
  handleCancelConfirmation,
}) => {
  return (
    <Formik
      initialValues={{
        note: "",
        total_tickets: "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        handleCancel();
      }}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <div className="p-4">
            <div className="flex flex-row justify-between items-center gap-20 w-200">
              <div className="flex flex-col">
                <p className="font-pop text-xl font-medium text-primary">
                  Are you sure you want to cancel ?
                </p>
              </div>
            </div>
          </div>
          <div className="border mx-4" />
          <div className="mx-4">
            <div className="flex flex-row gap-4 mt-6 justify-center items-center">
              <button
                type="submit"
                className="flex justify-center items-center bg-secondary text-white h-8 w-20 rounded-lg shadow-lg"
                onClick={handleCancelConfirmation}
              >
                Yes
              </button>

              <button
                className="flex justify-center items-center bg-grey h-8 w-20 rounded-lg shadow-lg"
                onClick={handleModalClose}
              >
                No
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const secondaryMenu = AppData.toursSecondaryMenu;

export default withHoc(Shows, { secondaryMenu, page: "shows" });

import React, { useEffect, useState } from "react";

const SearchInput = ({ searchInput, handleSearchInputChange }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(searchInput);
  }, [searchInput]);

  return (
    <div className="relative">
      <input
        type="text"
        className="border border-gray-300 bg-white h-10 px-5 pr-10 rounded-full border-primary text-sm focus:outline-none w-full"
        placeholder="Search here..."
        value={value}
        onChange={handleSearchInputChange}
       
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="absolute right-3 top-3 h-4 w-4 text-gray-400"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M9 3a6 6 0 015.33 8.79l5.4 5.39-1.42 1.42-5.39-5.4A6 6 0 119 3zm0 2a4 4 0 100 8 4 4 0 000-8z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default SearchInput;

import React from "react";
import CustomModal from "../../../Components/Modal";
import ProductsForm from "../../../Pages/Home/Products/ProductsForm";
import ProductsStockForm from "../../Home/Products/ProductsStockForm";

const ModalCustomProducts = ({
  showModal,
  closeModal,
  modalProps,
  merchDetails,
  updateMerchStocks,
  selectedWarehouse,
  addOrUpdateNewWarehouse,
  warehouseDetails,
  expandAddProductModal, // Assuming this is defined
  getStockTypeList, // Assuming this is defined
  stockTypes, // Assuming this is defined
  addMerchData, // Assuming this is defined
  setAddExpandModal, // Assuming this is defined
  merchSizes, // Assuming this is defined
  addOrUpdateProduct // Assuming this is defined
}) => {
  const handleClickClose = () => {
    closeModal();
  };

  return (
    <CustomModal
      handleModalClose={closeModal}
      modalProps={modalProps}
      className={`overflow-y-scroll w-full rounded-2xl shadow-xl ${expandAddProductModal && "h-[600px]"
        }`}
    >
      {modalProps.key === "merchStock" ? (
        <ProductsStockForm
          handleModalClose={closeModal}
          modalProps={modalProps}
          merchDetails={merchDetails}
          handleGetStockTypeList={getStockTypeList}
          stockTypes={stockTypes}
          handleMerchStockSubmit={updateMerchStocks}
          currentStockType={""}
          currentStockId={""}
        />
      ) : (
        <ProductsForm
          handleModalClose={closeModal}
          modalProps={modalProps}
          addMerchData={addMerchData}
          handleSetAddExpandModal={setAddExpandModal}
          merchSizes={merchSizes}
          handleAddProduct={addOrUpdateProduct}
        />
      )}
    </CustomModal>
  );
};

export default ModalCustomProducts;

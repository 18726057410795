import React, { useEffect, useState, useRef } from "react";
import CustomModal from "../../../Components/Modal";
import TrailerForm from "../../../Pages/Home/Trailers/TrailerForm";
import ProductsStockForm from "../../Home/Products/ProductsStockForm";
import ProductShipmentForm from "../../Home/Products/ProductShipmentForm";

const ModalCustomProducts = ({
  showModal,
  closeModal,
  modalProps,
  merchDetails,
  updateMerchStocks,
  updateMerchShipment,
  getShipmentTypeLists,
  selectedTrailer, // Assuming this is a prop you need
  addOrUpdateNewTrailer, // Assuming this is a function you need
  TrailerDetails // Assuming this is a prop you need
}) => {
  const handleClickClose = () => {
    closeModal();
  };
  const [stockTypes, setStockTypes] = useState([]);

  useEffect(() => {
    if (modalProps.key === "productsShipment") {
      // Fetch the shipment type lists when the shipment modal is opened
      const fetchStockTypes = async () => {
        const types = await getShipmentTypeLists();
        setStockTypes(types);
      };
      fetchStockTypes();
    }
  }, [modalProps.key, getShipmentTypeLists]);

  return (
    <CustomModal
      handleModalClose={closeModal}
      modalProps={modalProps}
      className={`overflow-y-scroll w-full rounded-2xl shadow-xl`}
    >
      {modalProps.key === "merchStock" ? (
        <ProductsStockForm
          handleModalClose={closeModal}
          modalProps={modalProps}
          merchDetails={merchDetails}
          stockTypes={[]}
          handleMerchStockSubmit={updateMerchStocks}
          currentStockType={"trailer"}
          currentStockId={selectedTrailer.id}
        />
      ) : modalProps.key === "productsShipment" ? (
        <ProductShipmentForm
          handleModalClose={closeModal}
          modalProps={modalProps}
          merchDetails={merchDetails}
          stockTypes={stockTypes}
          handleGetShipmentTypeLists={getShipmentTypeLists}
          handleMerchShipmentSubmit={updateMerchShipment}
          currentInventoryType=""
          currentFromStockType={"trailer"}
          currentFromStockID={selectedTrailer.id}
          currentToStockType=""
          currentToStockId=""
          selectedTrailerName={selectedTrailer?.trailer_name}

        />
      ) : (
        <TrailerForm
          handleModalClose={closeModal}
          handleAddOrUpdateTrailer={addOrUpdateNewTrailer}
          singleTrailerDetails={TrailerDetails}
          modalTitle={modalProps.title}
        />
      )}
    </CustomModal>
  );
};

export default ModalCustomProducts;

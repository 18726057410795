import React, { useState } from "react";
import { Formik, Form, Field } from "formik";

import { AppImages } from "../../../../Constants/Images";
import { WarehouseSchema } from "../../../../Utilites/Validation";
import TextInput from "../../../../Components/TextInput";
import CustomSelectPicker from "../../../../Components/CustomSelect";
import CustomDatepicker from "../../../../Components/CustomDatePicker";
import moment from 'moment';

const ToursForm = (props) => {
  const { handleModalClose, handleAddOrUpdateWarehouse, singleWarehouseDetails, modalTitle } = props;

  const getFormttedTourTypeData = (tourTypes) => {
    const formattedTourTypes =
      tourTypes &&
      tourTypes.length > 0 &&
      tourTypes.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    return formattedTourTypes;
  };

  const getFormttedCurrencyData = (currencyTypes) => {
    const formattedCurrency =
      currencyTypes &&
      currencyTypes.length > 0 &&
      currencyTypes.map((item) => {
        return {
          ...item,
          label: item.name + " " + item.symbol,
          value: item.code,
        };
      });
    return formattedCurrency;
  };

  return (
    <Formik
      initialValues={{
        warehouse_name: singleWarehouseDetails ? singleWarehouseDetails.warehouse_name : '',
        contact_person: singleWarehouseDetails ? singleWarehouseDetails.contact_person : '',
        phone_number: singleWarehouseDetails ? singleWarehouseDetails.phone_number : '',
        address: singleWarehouseDetails ? singleWarehouseDetails.address : '',

      }}
      validationSchema={WarehouseSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        handleAddOrUpdateWarehouse(values);
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        errors,
        touched,
        values,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit} className="m-3">
          <>
            <div className=" flex flex-row justify-between items-center">
              <div className="flex flex-col">
                {modalTitle === "Edit Warehouse" ? (
                  null
                ) : (
                  <p className="font-pop text-xl font-medium text-primary">Add Warehouse</p>
                )}
                <p className="font-pop text-xl font-medium text-primary">{modalTitle}</p>
              </div>
              <div className="flex flex-row  gap-4">
                <button
                  type="button"
                  className="flex justify-center items-center  bg-grey h-8 w-8 rounded-lg shadow-lg"
                  onClick={handleModalClose}
                >
                  <img src={AppImages.cancelBlack} className="w-3 h-3" />
                </button>
                <button
                  type="submit"
                  className="flex justify-center items-center  bg-secondary h-8 w-8 rounded-lg shadow-lg"
                >
                  <img src={AppImages.checkIcon} className="w-3 h-3" />
                </button>
              </div>
            </div>
            {/* {modalTitle === "Edit Warehouse" ? (
              null
            ) : (
              <p className="font-pop text-xs text-placeholderColor">Add Warehouse</p>
            )} */}
          </>
          <div className="border my-6" />
          <>
            <div className="flex flex-row gap-4 items-center">
              <TextInput
                type="text"
                name="warehouse_name"
                placeholder="Warehouse Name"
                rightIcon={null}
                className="p-2.5 w-80 border-placeholderColor"
              />
              <TextInput
                type="text"
                name="contact_person"
                placeholder="Contact person"
                rightIcon={null}
                className="p-2.5 w-96 border-placeholderColor"
              />
            </div>
            <div className="flex flex-row gap-4  items-center">
              <TextInput
                type="text"
                name="phone_number"
                placeholder="Phone Number"
                rightIcon={null}
                className="p-2.5 w-80 border-placeholderColor"
              />
              <TextInput
                type="text"
                name="address"
                placeholder="Address"
                rightIcon={null}
                className="p-2.5 w-96 border-placeholderColor"
              />
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
};

export default ToursForm;

import React, { useState, useMemo, useEffect } from 'react';
import './styles.css';
import { ReactComponent as ShowsIcon } from "../../../Assets/shows.svg";
import { ReactComponent as ToursIcn } from "../../../Assets/h_tours.svg";
import { ReactComponent as MerchIcn } from "../../../Assets/h_merch.svg";
import { ReactComponent as WarehouseIcon } from "../../../Assets/warehouse.svg";
import CustomModal from "../../../Components/Modal";
import ShowsForm from "../../../Pages/Home/Shows/ShowsForm";
import ToursForm from "../../../Pages/Home/Tours/ToursForm";
import { ReactComponent as TrailerIcon } from "../../../Assets/trailer.svg";
import ModalCustomTrailers from "../../Home/ModalCustomTrailers";
import { toast } from "react-toastify";
import { json, useLocation, useParams } from "react-router-dom";
import { convertToDbFormat } from "../../../Utilites/Date";
import ModalCustom from "../../Home/ModalCustom";
import ModalCustomWarehouse from "../../Home/ModalCustomWarehouse";
import ModalCustomProducts from "../../Home/ModalCustomProducts";
import { ApiUrl } from "../../../Constants/Api";
import { apiCall, getApiCall } from "../../../Utilites/Api";
import { stringify } from 'postcss';


function RadialMenu({ pageTitle }) {
  const [isActive, setIsActive] = useState(false);
  const [toursData, setToursData] = useState(null);
  const [warehousesData, setWarehousesData] = useState(null);
  const [TrailersData, setTrailersData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showsData, setShowsData] = useState(null);
  const [showModalType, setShowModalType] = useState("");
  const [selectedTourStatus, setSelectedTourStatus] = useState("upcoming");
  const [merchDetails, setmerchDetails] = useState(null);
  const [selectedShowStatus, setSelectedShowStatus] = useState("left");
  const [warehouseDetails, setWarehouseDetails] = useState(null);
  const [addMerchData, setAddMerchData] = useState({});
  const [productsList, setProductList] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [merchSizes, setMerchSizes] = useState([]);
  const [expandAddProductModal] = useState(false);
  const [addToursData, setAddToursData] = useState(null);
  const [TrailerDetails, setTrailerDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTrailer, setSelectedTrailer] = useState("");
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [expandAddTourModal, setAddExpandModal] = useState(false);
  const [expandAddShowModal, setAddExpandShowModal] = useState(false);
  const [tourDetails, setTourDetails] = useState(null);
  const [showDetails, setShowDetails] = useState(null);
  const [venueList, setVenueList] = useState([]);
  const location = useLocation();
  const tourItemObj = location?.state?.item;
  console.log("Radial Menu:"+JSON.stringify(tourItemObj));
  const { tourId, status } = useParams();
  useEffect(() => {
    setIsLoading(true);
    getAddToursData();
  }, []);

  useEffect(() => {
    getWarehouses();
  }, []);

  useEffect(() => {
    getShowsList(tourId, selectedShowStatus);
  }, [selectedShowStatus, tourId]);


  useEffect(() => {
    getTrailers();
  }, []);


  useEffect(() => {
    setIsLoading(true);
    getVenueList();
  }, []);

  const getAddMerchData = async () => {
    const resp = await getApiCall(ApiUrl.addMerchData());
    if (resp.status) {
      setAddMerchData(resp.data);
    }
  };

  const getMerchSizes = async () => {
    const resp = await getApiCall(ApiUrl.merchSizes());
    if (resp.status) {
      setMerchSizes(resp.data);
    }
  };

  useEffect(() => {
    getAddMerchData();
    getMerchSizes();
    getMerchList();
  }, []);

  const [modalProps, setModalProps] = useState({
    visible: false,
    title: "",
    key: "",
  });

  const getShowsList = async (tourId, selectedShowStatus) => {
    const resp = await getApiCall(ApiUrl.showsList(tourId, selectedShowStatus));
    if (resp.status) {
      setIsLoading(false);
      setShowsData(resp.data);
    } else {
      setIsLoading(false);
    }
  };

  const getVenueList = async () => {
    const resp = await getApiCall(ApiUrl.searchVenue);
    if (resp.status) {
      setVenueList(resp.data);
    }
  };


  useEffect(() => {
    getTours(selectedTourStatus);
  }, [selectedTourStatus]);

  const getTours = async (selectedTourStatus) => {
    const resp = await getApiCall(ApiUrl.toursList(selectedTourStatus));
    if (resp.status && resp.data.list.length>0
    ) {
      setIsLoading(false);
      setToursData(resp.data);
      localStorage.setItem("initalTourId", resp.data.list[0]?.id);
    } else {
      setIsLoading(false);
    }
  };

  const getAddToursData = async () => {
    const resp = await getApiCall(ApiUrl.addToursData);
    if (resp.status) {
      setAddToursData(resp.data);
    }
  };


  const iconsToShow = useMemo(() => {
    let icons = [];
    switch (pageTitle) {
      case 'shows':
        icons = ['shows', 'tours'];
        break;
      case 'tours':
        icons = ['tours', 'shows'];
        break;
      case 'inventory':
        icons = ['merch', 'warehouse', 'trailer'];
        break;
      case 'warehouse':
        icons = ['warehouse', 'trailer', 'merch'];
        break;
      case 'trailer':
        icons = ['trailer', 'warehouse', 'merch'];
        break;
      default:
        icons = [];
        break;
    }
    return icons;
  }, [pageTitle]);

  const handleButtonClick = (e) => {
    e.preventDefault();
    setIsActive(!isActive);
  };

  const addOrUpdateNewTour = async ({ ...payload }) => {
    setIsLoading(true);
    if (payload.start_date) {
      payload.start_date = convertToDbFormat(payload.start_date);
    }
    if (payload.end_date) {
      payload.end_date = convertToDbFormat(payload.end_date);
    }
    payload.vend_fee = payload.vend_fee ? "Enable" : "Disable";
    payload.settlement_email = payload.settlement_email ? "Enable" : "Disable";
    try {
      console.log(JSON.stringify(payload));
      const resp = await apiCall(
        tourDetails?.id ? ApiUrl.tourEdit(tourDetails?.id) : ApiUrl.addTour,
        payload
      );
      if (resp.status) {
        closeModal();
        getTours(selectedTourStatus);
        setSelectedTourStatus("upcoming");
        toast.success(
          `Tour ${tourDetails?.id ? "updated" : "added"} successfully`
        );
        //setIsLoading(false);
        //window.location.reload();
      } else {
        toast.error(
          `Tour not ${tourDetails?.id ? "updated" : "added"} . Please try later`

        );
    
        if (resp.status) {
          // Update the tour data in the component's state
          setToursData(prevState => {
            const updatedList = tourDetails?.id ? prevState.list.map(tour => 
              tour.id === tourDetails.id ? { ...tour, ...payload } : tour
            ) : [...prevState.list, resp.data];
            return { ...prevState, list: updatedList };
          });
    
          closeModal();
          setSelectedTourStatus("upcoming");
          toast.success(`Tour ${tourDetails?.id ? "updated" : "added"} successfully`);
        } else {
          toast.error(`Tour not ${tourDetails?.id ? "updated" : "added"}. Please try later`);
        }
      }
    } catch (error) {
      console.error("Error updating tour:", error);
      toast.error(`An error occurred. Please try again.`);
    }
  
    setIsLoading(false);
  };
  const openAddTrailerModal = () => {
    setModalProps({
      visible: true,
      title: "Add Trailer",
      key: "addTrailer"
    });
    setShowModal(true);
    setShowModalType('trailer');
  };

  const updateMerchStocks = async ({ ...payload }, merchSizes) => {
    const updatedPayload = merchSizes.map((item) => {
      return {
        ...item,
        stock_type: payload.stock_type,
        stock_id: payload.stock_id,
        merch_child_id: item.id,
      };
    });
    const resp = await apiCall(ApiUrl.addMerchStock(), updatedPayload);
    if (resp.status) {
      closeModal();
      getMerchList();
      toast.success(`Merch details updated successfully`);
    } else {
      toast.error(`Merch details not updated. Please try later`);
    }
  };


  const openAddTourModal = () => {
    setTourDetails(null);
    setShowModalType('tours');
    setModalProps({
      visible: true,
      title: "Add Tour",
      key: "addTour",
    });
  };
  const openAddWarehouseModal = () => {
    setWarehouseDetails(null);
    setModalProps({
      visible: true,
      title: "Add Warehouse",
      key: "addWarehouse",
    });
    setShowModal(true); 
    setShowModalType('warehouse');
  };

  const getWarehouses = async () => {
    const resp = await getApiCall(ApiUrl.WarehousesList());
    if (resp.status) {
      setIsLoading(false);
      setWarehousesData(resp.data);
      setSelectedWarehouse(resp.data[0]);
      getMerchList(resp.data[0]);
    } else {
      setIsLoading(false);
    }
  };

  const addOrUpdateNewWarehouse = async ({ ...payload }) => {
    setIsLoading(true);
    const resp = await apiCall(
      warehouseDetails?.id
        ? ApiUrl.WarehouseEdit(warehouseDetails?.id)
        : ApiUrl.addWarehouse(),
      payload
    );
    if (resp.status) {
      closeModal();
      getWarehouses();
      toast.success(
        `Warehouse ${warehouseDetails?.id ? "updated" : "added"} successfully`
      );
    } else {
      toast.error(
        `Warehouse not ${warehouseDetails?.id ? "updated" : "added"
        } . Please try later`
      );
    }
  };

  useEffect(() => {
    localStorage.setItem("initialTourId", tourId); 
  }, [tourId]);

  const addOrUpdateNewTrailer = async ({ ...payload }) => {
    setIsLoading(true);
    const resp = await apiCall(
      TrailerDetails?.id
        ? ApiUrl.TrailerEdit(TrailerDetails?.id)
        : ApiUrl.addTrailer(),
      payload
    );
    if (resp.status) {
      closeModal();
      getTrailers();
      toast.success(
        `Trailer ${TrailerDetails?.id ? "updated" : "added"} successfully`
      );
    } else {
      toast.error(
        `Trailer not ${TrailerDetails?.id ? "updated" : "added"
        } . Please try later`
      );
    }
  };


  const getTrailers = async () => {
    const resp = await getApiCall(ApiUrl.TrailersList());
    if (resp.status) {
      setIsLoading(false);
      setTrailersData(resp.data);
      setSelectedTrailer(resp.data[0]);
      getMerchList(resp.data[0]);
    } else {
      setIsLoading(false);
    }
  };

  const getMerchList = async (trailerItem) => {
    const resp = await getApiCall(ApiUrl.merchList("trailer", trailerItem?.id));
    if (resp.status) {
      setProductList(resp.data.merch_list);
    }
  };

  const closeModal = () => {
    setModalProps({
      visible: false,
      title: "",
      key: "",
    });
  };
  const getStockTypeList = async (stockType) => {
    const resp = await getApiCall(
      stockType === "Warehouse"
        ? ApiUrl.WarehousesList()
        : ApiUrl.TrailersList()
    );
    if (resp.status) {
      setStockTypes(resp.data);
    }
  };

  const openAddProductModal = () => {
    setModalProps({
      visible: true,
      title: "General Information ",
      key: "addProduct",
      pageTitle:pageTitle
    });
    setShowModalType('merch');
  };
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addOrUpdateProduct = async ({ ...payload }, skuFeilds) => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    console.log(payload, 'proopay')
    payload.child = skuFeilds.map((item) => ({ ...item, size: item.id }));
    payload.category =
      addMerchData &&
      addMerchData.categories.filter(
        (item) => item.id == payload.category_id
      )[0]?.name;
    if (payload.pre_order && payload.release_date) {
      payload.release_date = convertToDbFormat(payload.release_date);
    }
    const resp = await apiCall(ApiUrl.addMerch(), payload);
    if (resp.status) {
      closeModal();
      getMerchList();
      toast.success(`Product added successfully`);
      //window.location.reload();
    } else {
      toast.error(`Product not added. Please try later`);
    }

    setIsSubmitting(false);
  };

  const openAddShowModal = (item) => {
    if (tourItemObj?.status === 'completed') {
      toast.error("Tour is already completed. You cannot add a show.");
      return;
    } else if (tourItemObj?.status === 'inactive') {
      toast.error("Tour is inactive. You cannot add a show.");
      return;
    }
  
    setShowDetails(null);
    setShowModalType('shows');
    setModalProps({
      visible: true,
      title: "Add Show",
      key: "addShow",
      item: item,
      tourItemObj: tourItemObj, // Pass tourItemObj instead of tourId
      pageTitle: pageTitle
    });
  };
  const submitShowForm = (formData) => {
    if (modalProps.key === "addShow" || modalProps.key === "editShow") {
      addUpdateShow(formData, modalProps.tourItemObj); // Pass tourItemObj instead of tourId
    } else {
      // submitPostPoneShows(formData);
    }
  };


  const addUpdateShow = async ({...payload }, tourItemObj) => {
    payload.venue_name = payload.venue_address.split(",")[0];
    payload.venue_address = payload.venue_address.split(",")[1];
    payload.tax_music = payload.tax_music;
    payload.start_date = convertToDbFormat(payload.start_date);
    if (payload.end_date) {
      payload.end_date = convertToDbFormat(payload.end_date);
    } else {
      payload.end_date = convertToDbFormat(payload.start_date);
    }
    if(tourItemObj){
      payload.tour_id = tourItemObj.id;
    } else {
      payload.tour_id = tourId;
    }
    const resp = await apiCall(
      ApiUrl.addShow,
      payload
    );
    if (resp.status) {
      closeModal();
      //getShowsList(tourItemObj.id, "left"); // Use tourItemObj.id instead of tourId
      toast.success(
        `Show ${showDetails?.id? "updated" : "added"} successfully`
      );
      //window.location.reload();
    } else {
      toast.error(
        `Show not ${showDetails?.id? "updated" : "added"}. Please try later`
      );
    }
  };
  

  return (
    <div>
      {showModalType === 'warehouse' ? (
        <ModalCustomWarehouse
          closeModal={closeModal}
          modalProps={modalProps}
          selectedWarehouse={selectedWarehouse}
          addOrUpdateNewWarehouse={addOrUpdateNewWarehouse}
          getWarehouses={getWarehouses} 
        />
      ) : showModalType === 'merch' ? (
        <ModalCustomProducts
          showModal={modalProps.visible}
          closeModal={closeModal}
          modalProps={modalProps}
          merchDetails={merchDetails}
          updateMerchStocks={updateMerchStocks}
          //selectedWarehouse={selectedWarehouse}
          addOrUpdateProduct={addOrUpdateProduct}
          //warehouseDetails={warehouseDetails}
          expandAddProductModal={expandAddProductModal}
          getStockTypeList={getStockTypeList}
          stockTypes={stockTypes}
          addMerchData={addMerchData}
          setAddExpandModal={setAddExpandModal}
          merchSizes={merchSizes}
        //addOrUpdateProduct={addOrUpdateProduct}
        />
      ) : showModalType === 'tours' ? (
        <CustomModal
          handleModalClose={closeModal}
          modalProps={modalProps}
          className={`overflow-y-scroll w-full ${expandAddTourModal && "h-[600px]"
            } rounded-2xl shadow-xl`}
        >
          <ToursForm
            handleModalClose={closeModal}
            handleAddOrUpdateTour={addOrUpdateNewTour}
            addToursData={addToursData}
            handleSetAddExpandModal={setAddExpandModal}
            singleTourDetails={tourDetails}
          />
        </CustomModal>


      ) : showModalType === 'shows' ? (
        <CustomModal
          handleModalClose={closeModal}
          modalProps={modalProps}
          className={`overflow-y-scroll w-full rounded-2xl shadow-xl ${expandAddShowModal && "h-[500px]"
            }`}
        >
          <ShowsForm
            handleModalClose={closeModal}
            tourId={tourItemObj}
            venueList={venueList}
            handleSubmitForm={submitShowForm}
            singleShowDetails={showDetails}
            modalProps={modalProps}
            handleSetAddExpandModal={setAddExpandShowModal}
          />
        </CustomModal>


      ) : (
        <ModalCustomTrailers
          showModal={showModal}
          closeModal={closeModal}
          modalProps={modalProps}
          merchDetails={merchDetails}
          // updateMerchStocks={updateMerchStocks}
          selectedTrailer={selectedTrailer}
          addOrUpdateNewTrailer={addOrUpdateNewTrailer}
          TrailerDetails={TrailerDetails}
        />
      )}

      <div className={`radial ${isActive ? 'active' : ''}`}>
      <a className="triggerButton" href="" onClick={handleButtonClick}>
    {isActive ? (
      <div className="justify-between items-center" alt="Close icon" > X </div>
    ) : (
      <img src={"/plus.png"} className="w-4 h-4 object-contain" alt="Plus icon" />
    )}
  </a>
        {isActive && (
          <ul className="radialMenu">
            {iconsToShow.includes('tours') && (
              <li className="radialItem" data-hover-text="Tours">

                <a class=" hoverable-button" href="javascript:void(0)" onClick={openAddTourModal}>
                  <ToursIcn className="icon" />
                </a>
              </li>
            )}
            {iconsToShow.includes('shows') && (
              <li className="radialItem" data-hover-text="Shows">

                <a class=" hoverable-button" href="javascript:void(0)" onClick={() => openAddShowModal(tourItemObj, tourId)} >
                  <ShowsIcon className="icon" />
                </a>

              </li>
            )}
            {iconsToShow.includes('merch') && (
              <li className="radialItem" data-hover-text="Merch">
                <a class=" hoverable-button" href="javascript:void(0)" onClick={openAddProductModal} >
                  <MerchIcn className="icon" />
                </a>
              </li>
            )}
            {iconsToShow.includes('warehouse') && (
              <li className="radialItem" data-hover-text="Warehouses">


                <a class=" hoverable-button" href="javascript:void(0)" onClick={openAddWarehouseModal}>
                  <WarehouseIcon className="icon" /> {/* Make sure this icon is associated with openAddWarehouseModal */}
                </a>
              </li>
            )}

            {iconsToShow.includes('trailer') && (
              <li className="radialItem" data-hover-text="Trailers">
                {/* {showModal && ( // Render the ModalCustomProducts if showModal is true */}
                <a class=" hoverable-button" href="javascript:void(0)" onClick={openAddTrailerModal} >
                  <TrailerIcon className="icon" />
                </a>
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
}

export default RadialMenu;

import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import loginBg from '../../../Assets/login_bg.svg';
import TextInput from '../../../Components/TextInput';
import CustomButton from '../../../Components/CustomButton';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { apiCall } from '../../../Utilites/Api';
import { ApiUrl } from '../../../Constants/Api';
import { ResetPasswordSchema } from "../../../Utilites/Validation";

const ResetPassword = () => {
    const { code } = useParams();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const doUserResetPassword = async (values) => {
        const payload = { ...values, code: code };
        const resp = await apiCall(ApiUrl.ResetPassword(), payload);
        if (resp.status) {
            toast.success(resp.message);
            navigate("/login");
        } else {
            toast.error(resp.message);
        }
    };

    return (
        <div className="flex flex-row-col bg-white">
            <div className="w-1/2 flex items-start hidden-on-mobiles">
                <img
                    src={loginBg}
                    alt="Login background image"
                    className="w-full object-contain h-screen"
                />
            </div>
            <div className="flex flex-col justify-center items-center bg-primary w-full md:w-1/2 form-mobile-fullscreen">
                <div className="bg-primary rounded-lg p-10 w-full max-w-md">
                    <p className="font-pop text-4xl font-bold text-white text-center my-1">Reset Password!</p>
                    <p className="font-pop text-sm text-white text-center my-3">Enter your reset password.</p>
                    <Formik
                        initialValues={{ new: '', new_confirm: '' }}
                        validationSchema={ResetPasswordSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true);
                            doUserResetPassword(values);
                            setSubmitting(false);
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form className="bg-white rounded-xl my-8 p-16 flex flex-col gap-4 justify-center items-center">
                                <div className="relative">
                                    <TextInput
                                        type={showPassword ? "text" : "password"}
                                        name="new"
                                        placeholder="Enter new password"
                                        rightIcon={
                                            showPassword ? (
                                                <FaEyeSlash
                                                    className="text-grey1 cursor-pointer"
                                                    onClick={toggleShowPassword}
                                                />
                                            ) : (
                                                <FaEye
                                                    className="text-grey1 cursor-pointer"
                                                    onClick={toggleShowPassword}/>
                                            )
                                        }
                                    />
                                </div>
                                <div className="relative">
                                    <TextInput
                                        type={showPassword ? "text" : "password"}
                                        name="new_confirm"
                                        placeholder="Confirm new password"
                                        rightIcon={
                                            showPassword ? (
                                                <FaEyeSlash
                                                    className="text-grey1 cursor-pointer"
                                                    onClick={toggleShowPassword}
                                                />
                                            ) : (
                                                <FaEye
                                                    className="text-grey1 cursor-pointer"
                                                    onClick={toggleShowPassword}
                                                />
                                            )
                                        }
                                    />
                                </div>
                                <CustomButton
                                    type="submit"
                                    title="Submit"
                                    disabled={isSubmitting}
                                    className="bg-primary w-5/12"
                                />
                            </Form>
                        )}
                    </Formik>
                    <p className="font-pop text-white cursor-pointer underline text-center" onClick={() => navigate('/login')}>Back to login</p>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;

import React, { useEffect, useState } from "react";
import withHoc from "../../../Components/Hoc";
import CustomModal from "../../../Components/Modal";
import ToursForm from "./ToursForm";
import LoadingOverlay from "react-loading-overlay";
import { BeatLoader } from "react-spinners";
import { AppData, TableHeaderData } from "../../../Constants/AppData";
import { apiCall, getApiCall } from "../../../Utilites/Api";
import { ApiUrl } from "../../../Constants/Api";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import {
  covertToReadableFormat,
  convertToDbFormat,
} from "../../../Utilites/Date";
import { AppImages } from "../../../Constants/Images";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CurrentTours } from "../../../Assets/current_tours.svg";
import { ReactComponent as CompletedTours } from "../../../Assets/completed_tours.svg";
import { ReactComponent as ClosedTours } from "../../../Assets/closed_tours.svg";
import moment from "moment";
import NoData from "../../../Components/NoData";
import ScrollButton from "../../../Pages/Home/ScrollButton";


export const TableData = ({ data, hanldeTourClick, handleEditTour, handlecancelTour, tourStatus }) => {
  return (
    <div className="tours-card overflow-hidden rounded-lg px-3 py-4 h-20">
      <ScrollButton/>
      <div className="overflow-x-auto">
        <table className="w-full bg-white" cellPadding={20}>
          <thead className="shadow-md bg-black table-th table-header">
            <tr>
              {TableHeaderData.tours.map((item) => (
                <th key={item.title}>
                  {!(item.title === 'Action' && tourStatus !== 'upcoming') && (
                    <p className="font-pop text-sm text-secondary font-medium text-center">
                      {item.title}
                    </p>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="shadow-md">
            {data && data.list.length > 0 ? data.list.map((item, index) => (
              <tr
                key={index}
                className={`${index % 2 !== 0 && "bg-grey"}`}
              >
                <td className="cursor-pointer" onClick={() => hanldeTourClick(item)}>
                  <p className="font-pop text-xs text-secondary text-center font-medium">
                    {index + 1}
                  </p>
                </td>
                <td className="cursor-pointer" onClick={() => hanldeTourClick(item)}>
                  <p className="font-pop text-xs text-secondary text-center font-medium">
                    {item.tour_name}
                  </p>
                </td>
                <td className="cursor-pointer" onClick={() => hanldeTourClick(item)}>
                  <p className="font-pop text-xs text-secondary text-center font-medium">
                    {item.start_date ? 
                      <p>
                        {covertToReadableFormat(item.start_date)}
                        {item.start_date !== item.end_date && ` - ${covertToReadableFormat(item.end_date)}`}
                      </p> :
                      <div className="bg-danger bg-opacity-20 p-1 rounded-lg">
                        <p className="font-pop text-xs text-center font-medium">
                          Date Not Fixed Yet
                        </p>
                      </div>
                    }
                  </p>
                </td>
                <td className="cursor-pointer" onClick={() => hanldeTourClick(item)}>
                  <div className={`${item.vend_fee === "Enable" ? "bg-success" : "bg-danger"} bg-opacity-20 p-1 rounded-lg`}>
                    <p className={`font-pop text-xs ${item.vend_fee === "Enable" ? "text-primary" : "text-danger"} text-center font-medium`}>
                      {item.vend_fee}
                    </p>
                  </div>
                </td>
                <td className="cursor-pointer" onClick={() => hanldeTourClick(item)}>
                  <p className="font-pop text-xs text-secondary text-center font-medium">
                    {item.tour_type}
                  </p>
                </td>
                <td>
                  <div className="flex flex-row justify-center items-center gap-10">
                    <p className="font-pop text-xs text-secondary text-center font-medium w-20">
                      {item.report_currency}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex flex-row justify-center items-center gap-6">
                    {tourStatus === "upcoming" && (
                      <>
                        <div className="flex justify-center items-center w-7 h-7 rounded-md cursor-pointer z-50" onClick={() => handleEditTour(item)}>
                          <img src={AppImages.edit} className="w-5 h-5 object-contain mt-1" />
                        </div>
                        <div className="flex justify-center items-center text-white bg-danger w-7 h-7 border border-secondary rounded-md cursor-pointer z-50" onClick={() => handlecancelTour(item)}>
                          X
                        </div>
                        {/* <div className="flex justify-center items-center w-7 h-7 rounded-md cursor-pointer z-50" onClick={() => handleEditTour(item)}>
                          <img src={AppImages.close} className="w-5 h-5 object-contain mt-1" />
                        </div> */}
                      </>
                    )}
                  </div>
                </td>
              </tr>
            )) : (
              <tr>
                <td colSpan={TableHeaderData.tours.length}>
                  <NoData description="No data found. Please check back later" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};


const Tours = () => {
  const [toursData, setToursData] = useState(null);
  const [addToursData, setAddToursData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTourStatus, setSelectedTourStatus] = useState("upcoming");
  const [expandAddTourModal, setAddExpandModal] = useState(false);
  const [tourDetails, setTourDetails] = useState(null);
  const [cancelConfirmationData, setCancelConfirmationData] = useState(null);
  const navigate = useNavigate();
  const [modalProps, setModalProps] = useState({
    visible: false,
    title: "",
    key: "",
  });

  useEffect(() => {
    setIsLoading(true);
    getAddToursData();
  }, []);

  useEffect(() => {
    getTours(selectedTourStatus);
  }, [selectedTourStatus]);

  const getTours = async (selectedTourStatus) => {
    const resp = await getApiCall(ApiUrl.toursList(selectedTourStatus));
    if (resp.status && resp.data.list.length>0
    ) {
      setIsLoading(false);
      setToursData(resp.data);
      //console.log('dsad:'+ JSON.stringify(resp.data.list[0]));
      localStorage.setItem("initalTourId", resp.data.list[0]?.id);
      localStorage.setItem("tourItemObj", JSON.stringify(resp.data.list[0]));
      //console.log('OBJ:'+localStorage.getItem("tourItemObj"));
    } else {
      setIsLoading(false);
    }
  };

  const getAddToursData = async () => {
    const resp = await getApiCall(ApiUrl.addToursData);
    if (resp.status) {
      setAddToursData(resp.data);
    }
  };

  const cancelTour = async (item) => {
    setCancelConfirmationData(item);
  };

  const handleCancelConfirmation = async () => {
    setCancelConfirmationData(null);
    setIsLoading(true);
    const resp = await apiCall(ApiUrl.cancelTour(cancelConfirmationData.id), {
      status: "inactive",
    });
    if (resp.status) {
      setIsLoading(false);
      getTours(selectedTourStatus);
      toast.success("Tour cancelled successfully");
    } else {
      toast.error("Tour not cancelled. Please try later");
    }
  };

  const openAddTourModal = () => {
    setTourDetails(null);
    setModalProps({
      visible: true,
      title: "",
      key: "addTour",
    });
  };

//  const openAddTourModal = () => {
//   setTourDetails(null);
//   setModalProps({
//     visible: true,
//     title: "Add Tour", // Set the title to "Add Tour" when opening the Add Tour modal
//     key: "addTour",
//   });
// };

const openEditTourModal = () => {
  setModalProps({
    visible: true,
    title: "Edit Tour",
    key: "editTour",
  });
};

  
  
  const closeModal = () => {
    setModalProps({
      visible: false,
      title: "",
      key: "",
    });
  };

  const addOrUpdateNewTour = async ({ ...payload }) => {
    setIsLoading(true);
    if (payload.start_date) {
      payload.start_date = convertToDbFormat(payload.start_date);
    }
    // payload.start_date = convertToDbFormat(payload.start_date);
    if (payload.end_date) {
      payload.end_date = convertToDbFormat(payload.end_date);
    } else if (payload.start_date){
      payload.end_date = convertToDbFormat(payload.start_date);
    }
    payload.vend_fee = payload.vend_fee ? "Enable" : "Disable";
    payload.settlement_email = payload.settlement_email ? "Enable" : "Disable";
    const resp = await apiCall(
      tourDetails?.id ? ApiUrl.tourEdit(tourDetails?.id) : ApiUrl.addTour,
      payload
    );
    if (resp.status) {
      // Update the tour data in the component's state
      const response = await getApiCall(ApiUrl.toursList(selectedTourStatus));
      if(response.status){
        setToursData(response.data)
      }
      closeModal();
      // getTours(selectedTourStatus);
      setSelectedTourStatus("upcoming");
      setIsLoading(false);
      toast.success(
        `Tour ${tourDetails?.id ? "updated" : "added"} successfully`
      );
    } else {
      toast.error(
        `Tour not ${tourDetails?.id ? "updated" : "added"} . Please try later`
      );
    }
  };

  const handleSetTourStatus = (item) => {
    setIsLoading(true);
    setSelectedTourStatus(item.key);
  };

  
  const onTourClick = (item) => {
    if (item.start_date && item.end_date ) {
      localStorage.setItem("initalTourId", item.id);
      localStorage.setItem("tourItemObj", JSON.stringify(item));
      navigate("/shows", { state: { tourId: item.id, item } });
    } else {
      toast.warning("Tour date is not fixed yet. Please set the date before navigating to the shows page.");
    }
  };
  
  

const editTour = ({ ...tourItem }) => {
  setTourDetails(tourItem);
  openEditTourModal();
};

  
  return (

    <LoadingOverlay
      active={isLoading}
      classNamePrefix="customLoader"
      spinner={<BeatLoader color="white" size={20} />}
    >
      <CustomModal
        handleModalClose={closeModal}
        modalProps={modalProps}
        className={`overflow-y-scroll w-full ${expandAddTourModal && "h-[600px]"
          } rounded-2xl shadow-xl`}
      >
        <ToursForm
          handleModalClose={closeModal}
          handleAddOrUpdateTour={addOrUpdateNewTour}
          addToursData={addToursData}
          handleSetAddExpandModal={setAddExpandModal}
          singleTourDetails={tourDetails}
          modalTitle={modalProps.title}
        />
      </CustomModal>
      <CustomModal
        handleModalClose={() => setCancelConfirmationData(null)}
        modalProps={{
          visible: !!cancelConfirmationData,
          title: "Cancel Tour Confirmation",
          key: "cancelTourConfirmation",
        }}
      >
        {cancelConfirmationData && (
          <CancelForm
            handleModalClose={() => setCancelConfirmationData(null)}
            handleCancel={handleCancelConfirmation}
          />
        )}
      </CustomModal>
      <CustomModal
        handleModalClose={() => setCancelConfirmationData(null)}
        modalProps={{
          visible: !!cancelConfirmationData,
          title: "Cancel Tour Confirmation",
          key: "cancelTourConfirmation",
        }}
      >
        {cancelConfirmationData && (
          <CancelForm
            handleModalClose={() => setCancelConfirmationData(null)}
            handleCancel={handleCancelConfirmation}
          />
        )}
      </CustomModal>

      <div className="px-7 py-2 third-header">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-8">
            {toursData &&
              toursData.tour_status.map((item) => (
                <div
                  className="flex flex-row justify-center items-center gap-2 cursor-pointer "
                  key={item.key}
                  onClick={() => handleSetTourStatus(item)}
                >
                  <div className="flex justify-center items-center border border-secondary h-7 w-7 rounded-lg hoverable-button">
                    {item.key === "upcoming" ? (
                      <CurrentTours
                        stroke={selectedTourStatus === item.key && "#1C1C1C"}
                      />
                    ) : item.key === "completed" ? (
                      <CompletedTours
                        stroke={selectedTourStatus === item.key && "#1C1C1C"}
                      />
                    ) : (
                      <ClosedTours
                        stroke={selectedTourStatus === item.key && "#1C1C1C"}
                      />
                    )}
                  </div>
                  <p
                    className={`font-pop text-xs text-primary ${selectedTourStatus === item.key && "font-semibold"
                      }`}
                  >
                    {item.label}
                  </p>
                </div>
              ))}
          </div>
          <div className="flex flex-row items-center justify-center gap-12">
            <div
              className="flex flex-row justify-center items-center bg-primary w-40 h-9 rounded-lg gap-2 cursor-pointer"
              onClick={openAddTourModal}
            >
              <img src={"/plus.png"} className="w-3 h-3 object-contain" />
              <p className="font-pop text-xs text-white">Add Tour</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white px-3 py-5 main-body" style={{ }}>
        <TableData
          tourStatus={selectedTourStatus}
          data={toursData}
          hanldeTourClick={onTourClick}
          handleEditTour={editTour}
          handlecancelTour={cancelTour}
        />
      </div>

    </LoadingOverlay>
  );
};
const CancelForm = ({ handleModalClose, handleCancel, handleCancelConfirmation }) => {
  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        handleCancel();
      }}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <div className="p-4">
            <div className="flex flex-row justify-between items-center gap-20 w-200">
              <div className="flex flex-col">
                <p className="font-pop text-xl font-medium text-primary">
                  Are you sure you want to cancel ?
                </p>
              </div>
            </div>
          </div>
          <div className="border mx-4" />
          <div className="mx-4">
            <div className="flex flex-row gap-4 mt-6 justify-center items-center">
              <button
                type="submit"
                className="flex justify-center items-center bg-secondary text-white h-8 w-20 rounded-lg shadow-lg"
                onClick={handleCancelConfirmation}
              >
                Yes
              </button>


              <button
                className="flex justify-center items-center bg-grey h-8 w-20 rounded-lg shadow-lg"
                onClick={handleModalClose}
              >
                No
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
const secondaryMenu = AppData.toursSecondaryMenu;
export default withHoc(Tours, { secondaryMenu, page: "tours" }); 
